
	import {Vue, Options, Inject, Watch, Prop} from 'vue-property-decorator';
	import designerPoster from '@/components/custom/designer.poster.vue';
	import designerControls from '@/components/custom/designer.controls.vue';
	import designerUtilities from '@/components/custom/designer.utilities.vue';
	import {EventName, EventBus} from "@/helpers/event-bus";

	@Options({
		name: "DesignerFoundation",
		components: {
			designerPoster,
			designerControls,
			designerUtilities
		},
	})
	export default class DesignerFoundation extends Vue {
		@Inject() readonly firebase!: any;
		@Inject() readonly store!: any;

		@Prop() name;
		@Prop() static;
		@Prop() imported;
		@Prop() default;
		@Prop() controls;
		@Prop({ type: Function, required: true }) drawer!: any;
		readonly eventBus: EventBus = EventBus.getInstance();

		$refs: any;

		activeControl = 0;
		computedVariables: any = null;

		additionalControls: any = [
			{ name: 'Presets' },
			{ name: 'Save/Download' }
		];


		@Watch('computedVariables', {deep: true})
		posterDraw() {
			this.callPosterDraw();
		}

		beforeMount() {
			this.eventBus.on(EventName.UpdateVariable, this.updateVariableValue);
			this.computedVariables = Object.keys(this.imported).length > 0 ? this.imported : this.default;
		}
		updateVariableValue(e) {
			this.computedVariables[e.variable] = e.value;
		}
		callPosterDraw () {
			if (this.$refs.poster) {
				this.$refs.poster.drawDisplayCanvas();
			}
		}

		get windowWidth() {
			return this.store.state.windowWidth;
		}

		get computedControls() {
			let arr =  this.controls.concat(this.additionalControls);
			arr.forEach((el, index) => {
				el['index'] = index;
			});
			return arr;
		}
	}

