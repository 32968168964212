
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'PremiumFeature',
	components: {
	},
})
export default class PremiumFeature extends mixins(Services) {
	@Inject() store;
	@Inject() firebase;
	@Prop() visible;

	close() {
		this.$emit('close');
	}
	submit() {
		this.$router.push({ path: '/premium', replace: true });
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}
}
