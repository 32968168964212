<template>
	<div class="flex overflow-hidden h-screen relative">
		<TransitionRoot as="template" :show="sidebarOpen">
			<Dialog as="div" static class="flex fixed inset-0 z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
				<TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75" />
				</TransitionChild>
				<TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
					<div class="flex relative flex-col flex-1 w-full max-w-xs bg-gray-0 dark:bg-gray-900">
						<TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
							<div class="absolute top-0 right-0 pt-2 -mr-12">
								<button type="button" class="flex justify-center items-center ml-1 w-10 h-10 focus:outline-none" @click="sidebarOpen = false">
									<tw-icon weight="far" icon="times" class="text-gray-50 text-2xl"/>
								</button>
							</div>
						</TransitionChild>
						<sidebar></sidebar>
					</div>
				</TransitionChild>
				<div class="flex-shrink-0 w-14" aria-hidden="true">
					<!-- Dummy element to force sidebar to shrink to fit close icon -->
				</div>
			</Dialog>
		</TransitionRoot>

		<!-- Static sidebar for desktop -->
		<div class="hidden bg-gray-0 dark:bg-gray-900 md:flex md:flex-shrink-0">
			<div class="flex flex-col w-60">
				<sidebar/>
			</div>
		</div>

		<button v-show="!sidebarOpen" type="button" class="px-2 mx-2 text-gray-500 absolute h-12 left-0 top-0 focus:outline-none md:hidden z-50" @click="sidebarOpen = true">
			<tw-icon weight="far" icon="bars" class="dark:text-dark-300 text-2xl"/>
		</button>

		<main class="relative flex-1 focus:outline-none bg-gray-50 dark:bg-gray-950">
			<div class="p-4 pt-2 pb-0 absolute inset-0">
				<div class="h-full overflow-hidden-cancel">
					<!-- Replace with your content -->
					<slot name="content"></slot>
				</div>
			</div>
		</main>
	</div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-property-decorator';
import sidebar from './sidebar.vue';
import {
	MenuAlt2Icon,
	XIcon,
} from '@heroicons/vue/outline';

@Options({
	name: 'Navigation',
	components: {
		sidebar,
		MenuAlt2Icon,
		XIcon,
	},
})
export default class Navigation extends Vue {
	sidebarOpen = false;
}
</script>

<style lang="scss">
</style>
