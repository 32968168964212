import { createStore } from 'vuex';

export default createStore({
  state: {
    theme: 'dark',
    isSignedIn: false,
    isPremium: false,
    firebaseCustomer: {
      uid: '',
    },
    firebaseResources: null,
    firebaseProducts: [],
    windowWidth: 0,
    windowHeight: 0,
    breakpoint: 'xs',
  },
  mutations: {
    setTheme(state, val) {
      state.theme = val;
    },
    setIsSignedIn(state, val) {
      state.isSignedIn = val;
    },
    setIsPremium(state, val) {
      state.isPremium = val;
    },
    setFirebaseCustomer(state, val) {
      state.firebaseCustomer = val;
    },
    setFirebaseResources(state, val) {
      state.firebaseResources = val;
    },
    setFirebaseProducts(state, val) {
      state.firebaseProducts = val;
    },
    setWindowWidth (state, width) {
      state.windowWidth = width;
    },
    setWindowHeight (state, height) {
      state.windowHeight = height;
    },
    setBreakpoint (state, val) {
      state.breakpoint = val;
    }
  },
  actions: {
  },
  modules: {
  }
});
