
import {Options, Vue, Inject} from 'vue-property-decorator';
import discord from '@/components/custom/button.discord.vue';

@Options({
	name: 'Sidebar',
	components: {
		discord
	},
})
export default class Sidebar extends Vue {
	@Inject() readonly firebase!: any;
	@Inject() readonly store!: any;

	sidebarOpen = false;
	darkMode = true;

	navigation = [
		{ name: 'Dashboard', icon: 'chart-bar', path: '/', isPremium: false },
		{ name: 'Design', icon: 'drafting-compass', path: '/design', isPremium: false },
		{ name: 'Library', icon: 'books', path: '/library', isPremium: true },
		{ name: 'Browse', icon: 'search', path: '/browse', isPremium: true },
		{ name: 'Account', icon: 'user', path: '/account-settings', isPremium: false },
	];

	isActiveRoute(route) {
		return (route.name === 'Dashboard' && this.$route.name === 'Dashboard') || (route.name !== 'Dashboard' && this.$route.path.includes(route.path));
	}
	async purgeData() {
		const ref1 = this.firebase.firestore().collection("library/");
		ref1.onSnapshot((snapshot) => {
			snapshot.docs.forEach((doc) => {
				ref1.doc(doc.id).delete();
			});
		});
		// const ref2 = this.firebase.firestore().collection("customers/");
		// ref2.onSnapshot((snapshot) => {
		// 	snapshot.docs.forEach((doc) => {
		// 		ref2.doc(doc.id).delete();
		// 	});
		// });
		// this.signOut();
	}
	signOut () {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
			location.reload();
		});
	}
	openDiscord() {
		window.open('https://discord.gg/SgUWJ5bkzC','_blank');
	}

	get logo() {
		return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
	}
	get isPremium() {
		return this.store.state.isPremium;
	}
	get availableNavigation() {
		return this.navigation.filter((route: any) => {
			if (!(route.isPremium && !this.isPremium)) {
				return route;
			}
		});
	}
	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}
	get windowWidth() {
		return this.store.state.windowWidth;
	}
	get windowHeight() {
		return this.store.state.windowHeight;
	}
}
