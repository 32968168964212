<template>
	<TransitionRoot as="template" :show="visible">
		<Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="close()" :open="visible">
			<div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
						<div class=" bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
							<slot name="content"></slot>
						</div>
						<div class="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex justify-end">
							<tw-button v-if="showCancelButton" class="mr-2" text="Cancel" defaultStyle="cancel" @click="close()"/>
							<tw-button :text="submitText" :defaultStyle="submitStyle" @click="submit()"/>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
	import {Vue, Options, Prop, Inject} from 'vue-property-decorator';

	@Options({
		name: 'TwModal',
		components: {
		},
	})
	export default class TwModal extends Vue {
		@Inject() store;
		@Prop() visible;
		@Prop({default: 'Submit'}) submitText;
		@Prop({default: null}) submitStyle;
		@Prop({default: true}) showCancelButton;

		close() {
			this.$emit('close');
		}
		submit() {
			this.$emit('submit');
		}
		get theme() {
			return this.store.state.theme;
		}
	}
</script>
