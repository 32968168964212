
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Yosemite",
		components: {
			designer
		},
	})
	export default class Yosemite extends mixins(Functions) {
		name = 'Yosemite';
		imported: any = {};
		static = {};
		default: any = {
			text_primary: 'COLLECTION',
			text_fontFamily: 'Open Sans',
			text_primarySize: 55,
			colors_background: '#2A2A2C',
			colors_text: '#FFFFFF',
			colors_border: '#E59F0D',
			extras_show_collection: true,
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
				secondary: new PosterDto(),
				tertiary: new PosterDto(),
			}
		};

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_focus',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'Image',
							overrides: ['Upper', 'Lower', 'Logo'],
							label: 'Image Focus',
							count: 3,
						}
					},
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'COLLECTION',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 30,
							max: 70,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_text',
						comp: 'color-swatches',
						options: {
							label: 'Collection Font',
						}
					},
					{
						variable: 'colors_border',
						comp: 'color-swatches',
						options: {
							label: 'Border',
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_collection',
						comp: 'toggle',
						options: {
							name: 'collection',
							label: 'Show Collection Text',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		];

		created() {
			this.default.images.primary.boundX = 0;
			this.default.images.primary.boundY = 0;
			this.default.images.primary.boundW = 1000;
			this.default.images.primary.boundH = 551;
			this.default.images.secondary.boundX = 0;
			this.default.images.secondary.boundY = 949;
			this.default.images.secondary.boundW = 1000;
			this.default.images.secondary.boundH = 551;
			this.default.images.tertiary.initialY = 700;
			this.default.images.tertiary.initialW = 600;
			this.default.images.tertiary.initialH = 200;
			this.default.images.tertiary.maxW = 800;
			this.default.images.tertiary.maxH = 200;
		}

		drawer (ctx: any, variables: any, poster: any) {
			this.draw_rectangle(ctx, 0, 0, 1000, 1500, 'transparent', null, null);
			this.draw_rectangle(ctx, 0, 551, 1500, 8, variables.colors_border, null, null);
			this.draw_rectangle(ctx, 0, 558, 1500, 382, variables.colors_background, null, null);
			this.draw_rectangle(ctx, 0, 941, 1500, 8, variables.colors_border, null, null);

			poster.primaryFileSource ? this.draw_imageWithBounds(ctx, poster, 'primary', variables, null) : this.write_imageHelper(ctx, 1000 / 2, 300, 0, 'dual.1');
			poster.secondaryFileSource ? this.draw_imageWithBounds(ctx, poster, 'secondary', variables, null) : this.write_imageHelper(ctx, 1000 / 2, 1250, 0, 'dual.2');
			poster.tertiaryFileSource ? this.draw_image(ctx, poster, 'tertiary', variables) : this.write_imageHelper(ctx, 1000 / 2, 750, 0, 'dual.3');

			if (variables.extras_show_collection) {
				this.write_textLine(ctx, variables.text_primary, 500, 910, variables.text_primarySize, variables.text_fontFamily, variables.colors_text, 'bold', 'normal', 'center', 'alphabetic');
			}
			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
