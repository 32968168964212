
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Pomona",
		components: {
			designer
		},
	})
	export default class Pomona extends mixins(Functions) {
		name = 'Pomona';
		imported: any = {};
		static: any = {
			text_secondary: 'Collection',
			text_secondarySize: 75,
			width_border: 12,
		};
		default: any = {
			text_primary: 'COLLECTION NAME',
			text_fontFamily: 'Open Sans',
			text_primarySize: 75,
			colors_text_primary: '#FFFFFF',
			colors_border: '#CC7B19',
			colors_collection: '#CC7B19',
			colors_ribbon: '#232930',
			colors_ribbon_opacity: 100,
			extras_show_gridMap: false,
			extras_show_collection: true,
			overlay_color: '#000000',
			overlay_opacity: 0,
			overlay_direction: 'up',
			image_logo: 'plex',
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
			}
		};
		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
					{
						variable: 'image_logo',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'Logo',
							label: 'Player Logo',
							count: 2,
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Collection Name',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 50,
							max: 100,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Primary Font',
						}
					},
					{
						variable: 'colors_border',
						comp: 'color-swatches',
						options: {
							label: 'Border',
						}
					},
					{
						variable: 'colors_collection',
						comp: 'color-swatches',
						options: {
							label: '"Collection" Font',
						}
					},
					{
						variable: 'colors_ribbon',
						comp: 'color-swatches',
						options: {
							label: 'Ribbon',
						}
					},
					{
						variable: 'colors_ribbon_opacity',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Ribbon Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_direction',
						comp: 'button-group',
						options: {
							mod: 'Opacity',
							label: 'Direction',
							count: 2,
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_collection',
						comp: 'toggle',
						options: {
							name: 'collection',
							label: 'Show "COLLECTION" Text',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		];

		@Watch('default.image_logo')
		watchColorBanner() {
			if (this.default.image_logo === 'plex') {
				this.default.colors_collection = '#CC7B19';
				this.default.colors_border = '#CC7B19';
			} else if (this.default.image_logo === 'emby') {
				this.default.colors_collection = '#52B54B';
				this.default.colors_border = '#52B54B';
			}
		}

		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, (908 / 2) + 92, (1350 / 2), 65, null);

			const fillStyle: any = this.color_hexToRgba(variables.colors_ribbon, variables.colors_ribbon_opacity);

			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

			ctx.beginPath();
			ctx.moveTo(-9, -9);
			if (variables.extras_show_collection) {
				ctx.lineTo(118, -9);
				ctx.lineTo(118, 416);
				ctx.lineTo(92, 456);
			} else {
				ctx.lineTo(92, -9);
			}
			ctx.lineTo(92, 936);
			ctx.lineTo(358, 1350);
			ctx.lineTo(1009, 1350);
			ctx.lineTo(1009, 1509);
			ctx.lineTo(-9, 1509);
			ctx.lineTo(-9, -9);
			ctx.closePath();
			ctx.strokeStyle = variables.colors_border;
			ctx.lineWidth = this.static.width_border;
			ctx.fillStyle = `rgba(${fillStyle.r}, ${fillStyle.g}, ${fillStyle.b}, ${fillStyle.a}`;
			ctx.save();
			ctx.clip();
			ctx.fill();
			ctx.stroke();
			ctx.restore();

			if (variables.extras_show_collection) {
				ctx.save();
				ctx.font = `bold ${this.static.text_secondarySize}px Open Sans`;
				ctx.fillStyle = variables.colors_collection;
				ctx.translate(80, 35);
				ctx.rotate(-Math.PI / 2);
				ctx.textAlign = 'right';
				ctx.textBaseline = 'center';
				ctx.fillText(this.static.text_secondary, 0, 15 / 2);
				ctx.restore();
				ctx.fillRect(80, 35, 2, 2);
			}

			if (variables.image_logo === 'emby') {
				ctx.beginPath();
				ctx.moveTo(142, 1162);
				ctx.lineTo(204, 1224);
				ctx.lineTo(214, 1213);
				ctx.lineTo(285, 1283);
				ctx.lineTo(224, 1345);
				ctx.lineTo(234, 1355);
				ctx.lineTo(164, 1426);
				ctx.lineTo(103, 1365);
				ctx.lineTo(91, 1376);
				ctx.lineTo(21, 1305);
				ctx.lineTo(82, 1243);
				ctx.lineTo(71, 1233);
				ctx.lineTo(142, 1162);
				ctx.closePath();
				ctx.fillStyle = '#52B54B';
				ctx.fill();

				ctx.beginPath();
				ctx.moveTo(122, 1244);
				ctx.lineTo(209, 1294);
				ctx.lineTo(122, 1344);
				ctx.lineTo(122, 1244);
				ctx.closePath();
				ctx.fillStyle = '#FFFFFF';
				ctx.fill();
			} else {
				const startX = 44;
				const endY = 1106;
				const gradient = ctx.createRadialGradient(startX + 215, endY + 175, 0.000, startX + 215, endY + 175, 225);
				gradient.addColorStop(0.000, 'rgba(255, 195, 2, 1.000)');
				gradient.addColorStop(1.000, 'rgba(209, 127, 25, 1.000)');
				ctx.beginPath();
				ctx.moveTo(startX, endY);
				ctx.lineTo(startX + 100, endY);
				ctx.lineTo(startX + 215, endY + 175);
				ctx.lineTo(startX + 100, endY + 350);
				ctx.lineTo(startX, endY + 350);
				ctx.lineTo(startX + 113, endY + 175);
				ctx.lineTo(startX, endY);
				ctx.closePath();
				ctx.fillStyle = gradient;
				ctx.strokeStyle = gradient;
				ctx.fill();
			}

			this.write_textLine(ctx, variables.text_primary, poster.baseWidth - 30, poster.baseHeight - 64, variables.text_primarySize, variables.text_fontFamily, variables.colors_text_primary, 'normal', 'normal', 'right', 'middle');

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
