import firebase from './config';

export default class AuthenticationScripts {
	static async deleteCustomer(uid: any, email: any, password: any) {
		let result: any = '';
		const credentials = await firebase.auth.EmailAuthProvider.credential(email, password);

		await firebase.auth().currentUser?.reauthenticateWithCredential(credentials).then(async () => {
			firebase.firestore().collection("customers/").doc(uid).delete().then(async () => {
				await firebase.auth().currentUser?.delete().then(async () => {
					return;
				});
			});
		}).catch(async (error: any) => {
			console.log(error);
			if (error.code === 'auth/too-many-requests') {
				result = 'Too many request, try again later';
			} else if (error.code === 'auth/wrong-password') {
				result = 'Password is incorrect';
			} else {
				result = error.code;
			}
			return;
		});
		return result;

	}
	static async setPassword(email: any, currentPassword: any, newPassword: any) {
		let result: any = '';

		const credentials = await firebase.auth.EmailAuthProvider.credential(email, currentPassword);
		await firebase.auth().currentUser?.reauthenticateWithCredential(credentials).then(async () => {
			await firebase.auth().currentUser?.updatePassword(newPassword)
				.then(async () => {
					result = 'success';
					return;
				})
				.catch(async (error) => {
					result = error.code;
					return;
				});
		}).catch(async (error) => {
			if (error.code === 'auth/too-many-requests') {
				result = 'Too many request, try again later';
			} else if (error.code === 'auth/wrong-password') {
				result = 'Wrong password';
			} else {
				result = error.code;
			}
			return;
		});
		return result;
	}
	static async changeEmail(oldEmail: any, newEmail: any, password: any) {
		let result: any = '';

		const credentials = await firebase.auth.EmailAuthProvider.credential(oldEmail, password);
		await firebase.auth().currentUser?.reauthenticateWithCredential(credentials).then(async () => {
			await firebase.auth().currentUser?.updateEmail(newEmail)
				.then(async () => {
					result = 'success';
					return;
				})
				.catch(async (error) => {
					result = error.code;
					return;
				});
		}).catch(async (error) => {
			if (error.code === 'auth/too-many-requests') {
				result = 'Too many request, try again later';
			} else if (error.code === 'auth/wrong-password') {
				result = 'Wrong password';
			} else {
				result = error.code;
			}
			return;
		});
		return result;
	}
}
