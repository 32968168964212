
	import { Vue, Options, Prop } from 'vue-property-decorator';

	@Options({
		name: 'TwIcon',
		components: {
		},
	})
	export default class TwIcon extends Vue {
		@Prop() icon;
		@Prop({ default: 'far' }) weight;
		@Prop({ default: 'base' }) size;
	}
