import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'jquery';
import "./assets/css/fonts.scss";
import "./assets/css/global.scss";
import './assets/css/tailwind.css';

import {
	Dialog,
	DialogOverlay,
	DialogTitle,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	TransitionChild,
	TransitionRoot,
	Switch,
	Listbox,
	ListboxButton,
	ListboxLabel,
	ListboxOption,
	ListboxOptions
} from '@headlessui/vue';

import TwIcon from "./shared/tw-icon.vue";
import TwButton from "./shared/tw-button.vue";
import TwNotify from "./shared/tw-notify.vue";
import TwToggle from "./shared/tw-toggle.vue";
import TwModal from "./shared/tw-modal.vue";
import TwInputGroup from "./shared/tw-input-group.vue";
import TwSelectMenu from "./shared/tw-select-menu.vue";

const app = createApp(App);

app.component('Dialog', Dialog);
app.component('DialogOverlay', DialogOverlay);
app.component('DialogTitle', DialogTitle);
app.component('Menu', Menu);
app.component('MenuButton', MenuButton);
app.component('MenuItem', MenuItem);
app.component('MenuItems', MenuItems);
app.component('TransitionChild', TransitionChild);
app.component('TransitionRoot', TransitionRoot);
app.component('Listbox', Listbox);
app.component('ListboxButton', ListboxButton);
app.component('ListboxLabel', ListboxLabel);
app.component('ListboxOption', ListboxOption);
app.component('ListboxOptions', ListboxOptions);
app.component('Switch', Switch);

app.component('TwIcon', TwIcon);
app.component('TwButton', TwButton);
app.component('TwNotify', TwNotify);
app.component('TwToggle', TwToggle);
app.component('TwModal', TwModal);
app.component('TwInputGroup', TwInputGroup);
app.component('TwSelectMenu', TwSelectMenu);

app.use(store);
app.use(router);
app.mount('#app');
