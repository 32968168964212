<template>
	<tw-modal :visible="visible"
	          submitText="Change"
	          @close="close"
	          @submit="submit">
		<template v-slot:content>
			<div class="sm:flex sm:items-start w-full">
				<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
					<tw-icon class="text-red-600" size="lg" icon="exclamation-triangle"/>
				</div>
				<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
					<DialogTitle as="h3" class="text-lg leading-6 font-medium text-primary">Change Email</DialogTitle>
					<div class="mt-2">
						<p class="text-sm text-gray-500 dark:text-gray-200 mb-2">This will sign you out and require new email verification.</p>
						<tw-input-group label="New Email"
						                class="w-full"
						                :required="true"
						                border-color="border-gray-300 dark:border-gray-500"
						                background-color="bg-white dark:bg-gray-600"
						                :value="email"
						                @updateValue="email = $event"/>
						<tw-input-group label="Confirm Password"
						                :required="true"
						                type="password"
						                border-color="border-gray-300 dark:border-gray-500"
						                background-color="bg-white dark:bg-gray-600"
						                :value="password"
						                @updateValue="password = $event"/>
					</div>
				</div>
			</div>
		</template>
	</tw-modal>
</template>

<script lang="ts">
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'ChangeEmail',
	components: {
	},
})
export default class ChangeEmail extends mixins(Services) {
	@Inject() store;
	@Inject() firebase;
	@Inject() firebaseAuthentication;
	@Prop() visible;

	email: any = '';
	password: any = '';

	close() {
		this.$emit('close');
		this.email = '';
		this.password = '';
	}
	async submit() {
		await this.firebaseAuthentication.changeEmail(this.firebaseCustomer.email, this.email, this.password).then(async (res: any) => {
			if (res !== 'success') {
				this.notify('danger', 'Authentication', res);
			} else {
				await this.firebase.auth().currentUser.sendEmailVerification().then();
				this.firebase.auth().signOut().then(() => {
					this.store.commit('setIsSignedIn', false);
					localStorage.removeItem('timestamp');
					localStorage.removeItem('customer');
					localStorage.removeItem('resources');
					location.reload();
				});
			}
		});
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}

}
</script>
