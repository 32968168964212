<template>
	<div class="flex flex-col h-full w-full relative">
		<div class="flex-1 overflow-hidden">
			<div class="h-full overflow-auto pt-10 pb-4">
				<ul v-show="ready" role="list" class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 2xl:grid-cols-9">
					<li v-for="file in templates" :key="file.id" class="relative ">
						<div class="group cursor-pointer block w-full aspect-w-10 aspect-h-7 bg-black rounded overflow-hidden border border-secondary relative" @click="templateClick(file)">
							<img :src="file.url" alt="" class="object-cover pointer-events-none group-hover:opacity-40" @load="increaseLoadedCount()"/>
							<div class="information absolute inset-2 top-1">
								<div class="h-full flex flex-col relative justify-between overflow-hidden">
									<p class=" text-xs text-primary">{{file.id}}</p>
									<p v-if="file.creator" class="text-xs text-primary overflow-hidden overflow-ellipsis">{{file.creator}}</p>
									<img v-else class="h-3 mr-auto" :src="require(`./../assets/img/${logo}.svg`)" alt="">
								</div>
							</div>
							<tw-icon class="info-icon absolute right-1 top-1 text-white" weight="fas" icon="info-circle"/>
						</div>
					</li>
				</ul>

				<TransitionRoot as="template" :show="templateVisible">
					<Dialog as="section" static class="fixed inset-0 overflow-hidden md:left-60" @close="closeDesigner" :open="templateVisible">
						<div class="absolute inset-0 overflow-hidden">
							<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
								<DialogOverlay class="absolute inset-0 cursor-pointer bg-gray-500 bg-opacity-75 transition-opacity" />
							</TransitionChild>

							<div class="fixed top-12 bottom-0 flex right-2 md:right-4 lg:right-4 xl:right-20 2xl:right-36 left-2 md:left-64 xl:left-80 2xl:left-96" @close="closeDesigner" :open="templateVisible">
								<TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-y-full" enter-to="translate-y-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-y-0" leave-to="translate-y-full">
									<div class="w-full h-full flex flex-col pt-6 bg-gray-0 dark:bg-gray-900 shadow-xl rounded-t-md px-4 sm:px-6">
										<div class="flex items-start justify-between">
											<DialogTitle class="text-lg font-medium bg-gradient-to-r from-brand-light-from to-brand-light-to dark:from-brand-dark-from dark:to-brand-dark-to text-transparent! bg-clip-text capitalize">
												{{ $route.name }}</DialogTitle>
											<div class="ml-3 h-7 flex items-center">
												<button class="text-gray-400 hover:text-gray-500 focus:outline-none" @click="closeDesigner">
													<tw-icon size="lg" icon="times"/>
												</button>
											</div>
										</div>
										<div class="mt-4 relative flex-1">
											<div class="absolute inset-0">
												<div class="h-full" aria-hidden="true">
													<router-view/>
												</div>
											</div>
										</div>
									</div>
								</TransitionChild>
							</div>
						</div>
					</Dialog>
				</TransitionRoot>
			</div>
		</div>

		<div class="pb-4 w-full absolute bg-gradient-to-b from-gray-50 via-gray-50 dark:from-gray-950 dark:via-gray-950 to-transparent h-12">
			<span class="text-xl my-auto text-gray-500 dark:text-gray-300 pl-10 md:pl-0">Design</span>
		</div>
	</div>
</template>

<script lang="ts">
	import { Vue, Options, Watch, Inject } from 'vue-property-decorator';
	import $ from 'jquery';

	@Options({
		name: 'Design',
		components: {
		},
	})
	export default class Design extends Vue {
		@Inject() store;

		ready = false;
		imagesLoaded = 0;
		templateVisible = false;

		@Watch('$route.name')
		designState() {
			if (this.$route.name === 'Design') {
				this.templateVisible = false;
			}
		}

		@Watch('imagesLoaded')
		setReady () {
			if (this.imagesLoaded === this.templateCount) {
				this.ready = true;
			}
		}

		mounted () {
			if (this.$route.name !== 'Design') {
				this.templateVisible = true;
			}


			$(".group").hover(function () {
				$(this).toggleClass("show");
			});
			$(".info-icon").hover(function () {
				$(this).siblings('.information').toggleClass("show");
			});
		}
		templateClick (item: any) {
			if (item.status !== 'In Progress') {
				this.$router.push({ name: item.id});
				this.templateVisible = true;
			}
		}

		increaseLoadedCount () {
			this.imagesLoaded++;
		}
		closeDesigner() {
			this.templateVisible = false;
			setTimeout(() => {
				this.$router.push({ name: 'Design'});
			}, 500);

		}
		get templateCount () {
			return Object.keys(this.templates).length;
		}
		get templates() {
			let arr: any = [];
			const entries = Object.entries(this.store.state.firebaseResources ? this.store.state.firebaseResources['Templates'] : {});
			entries.forEach((entry: any) => {
				entry[1]['id'] = entry[0];
				entry[1].order = entry[1].order ? entry[1].order : 999;
				arr.push(entry[1]);
			});

			arr = arr.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
			arr = arr.sort((a: any, b: any) => { return a.status === 'New' ? -1 : b.status == 'New' ? 1 : 0; });

			return arr;
		}
		get breakpoint() {
			return this.store.state.breakpoint;
		}
		get logo() {
			return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
		}
	}
</script>

<style scoped lang="scss">
	.group {
		.info-icon {
			opacity: 0;
		}
		.information {
			opacity: 0;
		}
		&.show {
			.info-icon {
				opacity: 1;
			}
			.information.show {
				opacity: 1;
				height: auto;
			}
		}

	}
</style>
