<template>
	<div class="flex flex-col flex-grow border-r border-gray-200 dark:border-gray-800 overflow-y-auto">
		<router-link class="mx-auto flex justify-center py-11" :to="{ path: '/' }">
			<img class="h-5 overflow-visible" :src="require(`../../assets/img/${logo}.svg`)" alt="Coverlabs Logo">
		</router-link>
		<div class="flex flex-col flex-1">
			<!-- Profile Image -->
			<div v-if="windowHeight <= 650" class="flex items-center px-5 mb-5">
				<span class="inline-flex items-center justify-center h-9 w-9 rounded-full border-2 border-primary">
					<img v-if="firebaseCustomer.photoUrl" :src="firebaseCustomer.photoUrl" class="rounded-full" alt="profile"/>
					<tw-icon v-else weight="fas" icon="user" class="inline-flex items-center justify-center text-white w-8 h-8 text-sm bg-gray-500 rounded-full"/>
				</span>
				<div class="ml-3 overflow-hidden">
					<p class="text-primary text-sm truncate">{{firebaseCustomer.username}}</p>
					<p class="text-gray-500 text-xs truncate">{{firebaseCustomer.email}}</p>
				</div>
			</div>
			<div v-else class="flex flex-col overflow-hidden">
				<span class="inline-flex mx-auto mb-5 items-center justify-center h-20 w-20 rounded-full bg-gray-500 border-2 border-primary">
					<img v-if="firebaseCustomer.photoUrl" :src="firebaseCustomer.photoUrl" class="rounded-full" alt="profile"/>
					<tw-icon v-else weight="fas" icon="user" class="text-white text-3xl"/>
				</span>
				<p class="text-primary text-center text-sm truncate">{{firebaseCustomer.username}}</p>
				<p class="text-gray-500 text-center text-xs mb-6 truncate">{{firebaseCustomer.email}}</p>
			</div>

			<!-- Routes -->
			<nav class="border-t border-b border-gray-200 dark:border-gray-800 divide-y divide-gray-200 dark:divide-gray-800">
				<router-link v-for="(route, index) in availableNavigation"
				             :key="index"
				             :to="{ path: route.path }"
				             class="h-10 flex justify-between px-5"
				             :class="isActiveRoute(route) ? (!this.darkMode ? 'light-partial-gradient' : 'dark-partial-gradient') : ''">
					<div class="flex">
						<tw-icon weight="fas"
						         :icon="route.icon"
						         class="text-gray-300 dark:text-gray-500"
						         :class="{'background-gradient-b text-transparent! bg-clip-text': isActiveRoute(route)}"></tw-icon>
						<span class="text-sm text-gray-300 dark:text-gray-500 inline-block my-auto pl-3" :class="{'text-gray-900! dark:text-gray-50!': isActiveRoute(route)}">{{route.name}}</span>
					</div>
					<tw-icon v-if="isActiveRoute(route)"
					         weight="fas"
					         icon="chevron-right"
					         class="text-primary"/>
				</router-link>
			</nav>
			<div class="flex-1">
			</div>
<!--			<router-link v-if="!isPremium" :to="{ path: '/premium' }">-->
<!--				<div class="mx-auto mt-4 w-48 border border-primary background-secondary rounded-lg p-4 cursor-pointer">-->
<!--					<img class="w-32 mx-auto" src="../../assets/img/premium.svg" alt="premium">-->
<!--					<p class="text-center text-primary text-md pt-4">Use our Premium Features now!</p>-->
<!--					<div class="mx-auto w-9 mt-6">-->
<!--						<tw-icon class="h-9 w-9 rounded-full bg-brand-light-to dark:bg-brand-dark-to text-center text-white" weight="fas" icon="arrow-right"/>-->
<!--					</div>-->
<!--				</div>-->
<!--			</router-link>-->

			<!--			<div class="flex pt-3">-->
			<!--				<span class="mx-5 my-3 flex cursor-pointer text-red-600 text-sm inline-block my-auto pl-3" @click="purgeData()">Purge Data</span>-->
			<!--			</div>-->

			<div>

			</div>
			<discord @click="openDiscord()"/>
			<div class="flex pt-3">
				<div class="mx-5 my-3 flex cursor-pointer text-gray-300 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-50" @click="signOut">
					<tw-icon weight="fas"
					         icon="sign-out"
					         class="transform rotate-180"></tw-icon>
					<span class="text-sm inline-block my-auto pl-3">Sign Out</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {Options, Vue, Inject} from 'vue-property-decorator';
import discord from '@/components/custom/button.discord.vue';

@Options({
	name: 'Sidebar',
	components: {
		discord
	},
})
export default class Sidebar extends Vue {
	@Inject() readonly firebase!: any;
	@Inject() readonly store!: any;

	sidebarOpen = false;
	darkMode = true;

	navigation = [
		{ name: 'Dashboard', icon: 'chart-bar', path: '/', isPremium: false },
		{ name: 'Design', icon: 'drafting-compass', path: '/design', isPremium: false },
		{ name: 'Library', icon: 'books', path: '/library', isPremium: true },
		{ name: 'Browse', icon: 'search', path: '/browse', isPremium: true },
		{ name: 'Account', icon: 'user', path: '/account-settings', isPremium: false },
	];

	isActiveRoute(route) {
		return (route.name === 'Dashboard' && this.$route.name === 'Dashboard') || (route.name !== 'Dashboard' && this.$route.path.includes(route.path));
	}
	async purgeData() {
		const ref1 = this.firebase.firestore().collection("library/");
		ref1.onSnapshot((snapshot) => {
			snapshot.docs.forEach((doc) => {
				ref1.doc(doc.id).delete();
			});
		});
		// const ref2 = this.firebase.firestore().collection("customers/");
		// ref2.onSnapshot((snapshot) => {
		// 	snapshot.docs.forEach((doc) => {
		// 		ref2.doc(doc.id).delete();
		// 	});
		// });
		// this.signOut();
	}
	signOut () {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
			location.reload();
		});
	}
	openDiscord() {
		window.open('https://discord.gg/SgUWJ5bkzC','_blank');
	}

	get logo() {
		return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
	}
	get isPremium() {
		return this.store.state.isPremium;
	}
	get availableNavigation() {
		return this.navigation.filter((route: any) => {
			if (!(route.isPremium && !this.isPremium)) {
				return route;
			}
		});
	}
	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}
	get windowWidth() {
		return this.store.state.windowWidth;
	}
	get windowHeight() {
		return this.store.state.windowHeight;
	}
}
</script>

<style lang="scss">
</style>
