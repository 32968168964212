<template>
	<designer ref="designer"
	          :name="name"
	          :imported="imported"
	          :static="static"
	          :default="this.default"
	          :controls="controls"
	          :drawer="drawer"
	          @imageLoaded="imageLoadedListener"/>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-property-decorator';
import { PosterDto } from '@/helpers/dtos.poster';
import Functions from '@/mixins/designer.functions';
import designer from '@/components/custom/designer.foundation.vue';

@Options({
	name: "Niagara",
	components: {
		designer
	},
})
export default class Niagara extends mixins(Functions) {
	name = 'Niagara';
	imported: any = {};
	static: any = {
		padding_horizontal: 50,
		maxWidth: 900,
		depth_frame: 32,
	};
	default: any = {
		text_primary: 'COLLECTION',
		text_fontFamily: 'Open Sans',
		text_secondarySize: 70,
		text_primaryOffset: 1350,
		colors_text_secondary: '#FFFFFF',
		colors_border_outer: '#000000',
		colors_border_inner: '#E7E7E7',
		overlay_color: '#000000',
		overlay_opacity: 0,
		overlay_direction: 'up',
		extras_show_logo: true,
		extras_show_collection: true,
		extras_show_gridMap: false,
		image_focus: 'primary',
		images: {
			primary: new PosterDto(),
			secondary: new PosterDto(),
		}
	}

	controls: any = [
		{
			name: "Image",
			utilities: [
				{
					variable: 'image_focus',
					handler: 'imageToggle',
					comp: 'button-group',
					options: {
						mod: 'Image',
						label: 'Image Focus',
						count: 2,
					}
				},
				{
					value: 'scale',
					handler: 'scaleHandler',
					comp: 'slider',
					options: {
						label: 'Adjust Size',
						min: 5,
						max: 200,
						type: "%",
					}
				},
				{
					value: 'offsets',
					handler: 'offsetHandler',
					comp: 'offsets',
					options: {
						label: 'Offsets',
					}
				},
			]
		},
		{
			name: "Text",
			utilities: [
				{
					variable: 'text_primary',
					comp: 'input-group',
					options: {
						mod: 'text',
						label: 'COLLECTION',
					}
				},
				{
					variable: 'text_fontFamily',
					comp: 'select-menu',
					options: {
						mod: 'FontFamily',
						label: 'Font Family',
					}
				},
				{
					variable: 'text_secondarySize',
					comp: 'slider',
					options: {
						label: 'Collection Font Size',
						min: 50,
						max: 100,
						type: "px",
					}
				},
				{
					variable: 'text_primaryOffset',
					comp: 'slider',
					options: {
						label: 'Collection Font Location',
						min: 100,
						max: 1450,
						type: "px",
					}
				},
			]
		},
		{
			name: "Colors",
			utilities: [
				{
					variable: 'colors_text_secondary',
					comp: 'color-swatches',
					options: {
						label: 'Collection Font',
					}
				},
				{
					variable: 'colors_border_outer',
					comp: 'color-swatches',
					options: {
						label: 'Outer Border',
					}
				},
				{
					variable: 'colors_border_inner',
					comp: 'color-swatches',
					options: {
						label: 'Inner Border',
					}
				},
			]
		},
		{
			name: "Overlay",
			utilities: [
				{
					variable: 'overlay_color',
					comp: 'color-swatches',
					options: {
						label: 'Color',
					}
				},
				{
					variable: 'overlay_opacity',
					comp: 'slider',
					options: {
						label: 'Opacity',
						min: 0,
						max: 100,
						type: "%",
					}
				},
				{
					variable: 'overlay_direction',
					comp: 'button-group',
					options: {
						mod: 'Opacity',
						label: 'Direction',
						count: 2,
					}
				},
			]
		},
		{
			name: "Extras",
			utilities: [
				{
					variable: 'extras_show_logo',
					comp: 'toggle',
					options: {
						name: 'logo',
						label: 'Show Logo Art',
					}
				},
				{
					variable: 'extras_show_collection',
					comp: 'toggle',
					options: {
						name: 'collection',
						label: 'Show Collection Text',
					}
				},
				{
					variable: 'extras_show_gridMap',
					comp: 'toggle',
					options: {
						label: 'Show Grid Map',
					}
				},
			]
		}
	];


	created() {
		this.default.images.secondary.initialY = 1100;
		this.default.images.secondary.initialW = 600;
		this.default.images.secondary.initialH = 400;
		this.default.images.secondary.maxW = 800;
		this.default.images.secondary.maxH = 300;
	}

	drawer (ctx: any, variables: any, poster: any) {
		poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 650, 35, 'collection.1');

		this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

		if (variables.extras_show_logo) {
			poster.secondaryFileSource ? this.draw_image(ctx, poster, 'secondary', variables) : this.write_imageHelper(ctx, 1000 / 2, 1200, 0, 'collection.2');
		}
		if (variables.extras_show_collection) {
			this.write_textLine(ctx, variables.text_primary, 500, variables.text_primaryOffset, variables.text_secondarySize, variables.text_fontFamily, variables.colors_text_secondary, 'normal', 'normal', 'center', 'alphabetic');
		}

		let outer = 31;
		const inner = outer * 2;

		ctx.beginPath();
		ctx.moveTo(0, 0);
		ctx.lineTo(1000, 0);
		ctx.lineTo(1000, 1500);
		ctx.lineTo(0, 1500);
		ctx.lineTo(0, 0);
		ctx.lineTo(outer, 0);
		ctx.lineTo(outer, 1500 - outer);
		ctx.lineTo(1000 - outer, 1500 - outer);
		ctx.lineTo(1000 - outer, outer);
		ctx.lineTo(0, outer);
		ctx.closePath();
		ctx.strokeStyle = variables.colors_border_outer;
		ctx.stroke();
		ctx.fillStyle = variables.colors_border_outer;
		ctx.fill();

		outer = outer - 3;
		ctx.beginPath();
		ctx.moveTo(outer, outer);
		ctx.lineTo(1000 - outer, outer);
		ctx.lineTo(1000 - outer, 1500 - outer);
		ctx.lineTo(outer, 1500 - outer);
		ctx.lineTo(outer, outer);
		ctx.lineTo(inner, outer);
		ctx.lineTo(inner, 1500 - inner);
		ctx.lineTo(1000 - inner, 1500 - inner);
		ctx.lineTo(1000 - inner, inner);
		ctx.lineTo(outer, inner);
		ctx.closePath();
		ctx.strokeStyle = variables.colors_border_inner;
		ctx.stroke();
		ctx.fillStyle = variables.colors_border_inner;
		ctx.fill();

		if (variables.extras_show_gridMap) {
			this.draw_gridMap(ctx);
		}
	}

	imageLoadedListener () {
		//	RUN ANY RESETS HERE AFTER IMAGE LOADED
	}
}
</script>
