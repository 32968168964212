
	import { Vue, Options, Watch, Inject } from 'vue-property-decorator';
	import $ from 'jquery';

	@Options({
		name: 'Design',
		components: {
		},
	})
	export default class Design extends Vue {
		@Inject() store;

		ready = false;
		imagesLoaded = 0;
		templateVisible = false;

		@Watch('$route.name')
		designState() {
			if (this.$route.name === 'Design') {
				this.templateVisible = false;
			}
		}

		@Watch('imagesLoaded')
		setReady () {
			if (this.imagesLoaded === this.templateCount) {
				this.ready = true;
			}
		}

		mounted () {
			if (this.$route.name !== 'Design') {
				this.templateVisible = true;
			}


			$(".group").hover(function () {
				$(this).toggleClass("show");
			});
			$(".info-icon").hover(function () {
				$(this).siblings('.information').toggleClass("show");
			});
		}
		templateClick (item: any) {
			if (item.status !== 'In Progress') {
				this.$router.push({ name: item.id});
				this.templateVisible = true;
			}
		}

		increaseLoadedCount () {
			this.imagesLoaded++;
		}
		closeDesigner() {
			this.templateVisible = false;
			setTimeout(() => {
				this.$router.push({ name: 'Design'});
			}, 500);

		}
		get templateCount () {
			return Object.keys(this.templates).length;
		}
		get templates() {
			let arr: any = [];
			const entries = Object.entries(this.store.state.firebaseResources ? this.store.state.firebaseResources['Templates'] : {});
			entries.forEach((entry: any) => {
				entry[1]['id'] = entry[0];
				entry[1].order = entry[1].order ? entry[1].order : 999;
				arr.push(entry[1]);
			});

			arr = arr.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
			arr = arr.sort((a: any, b: any) => { return a.status === 'New' ? -1 : b.status == 'New' ? 1 : 0; });

			return arr;
		}
		get breakpoint() {
			return this.store.state.breakpoint;
		}
		get logo() {
			return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
		}
	}
