
	import { Vue, Options, Prop, Watch } from 'vue-property-decorator';

	@Options({
		name: 'TwInputGroup',
		components: {
		},
	})
	export default class TwInputGroup extends Vue {
		@Prop({default: ''}) label;
		@Prop({default: ''}) placeholder;
		@Prop({default: ''}) value;
		@Prop({default: ''}) message;
		@Prop({default: ''}) trailingIcon;
		@Prop( {default: null}) max;
		@Prop( {default: null}) min;
		@Prop({default: 'border-primary'}) borderColor;
		@Prop({default: 'background-secondary'}) backgroundColor;
		@Prop({default: 'right'}) trailingIconAlignment;
		@Prop({default: 'text'}) type;
		@Prop({default: false}) invalid;
		@Prop({default: false}) disabled;

		activeValue: any = '';

		created() {
			this.activeValue = this.value;
		}
		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.activeValue = this.value;
		}
		@Watch('activeValue')
		async watchActiveValue() {
			if (this.type === 'number') {
				this.activeValue = parseInt(this.activeValue, 10);
				if (this.max && this.activeValue > this.max) {
					this.activeValue = this.max;
				}
				if (this.min && this.activeValue < this.min) {
					this.activeValue = this.min;
				}
			}

			this.$emit('updateValue', this.activeValue);
		}
	}
