
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Cillon",
		components: {
			designer
		},
	})
	export default class Cillon extends mixins(Functions) {
		$refs: any;

		name = 'Cillon';
		imported: any = {};
		static: any = {
			activeBanner: null,
			usingPreferredColor: true,
		};
		default: any = {
			colors_banner: '#232930',
			overlay_color: '#000000',
			overlay_opacity: 0,
			overlay_direction: 'up',
			extras_show_minified: false,
			extras_show_gridMap: false,
			image_focus: 'primary',
			image_banner: {
				hex: '#000000',
				urlFull: 'https://firebasestorage.googleapis.com/v0/b/coverlabs-io.appspot.com/o/resources%2Fbanners%2F4K-full.png?alt=media&token=b7ec8e22-bec9-4ee0-806e-49aa58d8e849',
				urlMin: 'https://firebasestorage.googleapis.com/v0/b/coverlabs-io.appspot.com/o/resources%2Fbanners%2F4K-min.png?alt=media&token=554bc5f3-2103-4336-bfa4-33232e03d2c4'
			},
			images: {
				primary: new PosterDto(),
			},
		}
		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
					{
						variable: 'image_banner',
						comp: 'select-menu',
						options: {
							mod: 'Banner',
							label: 'Banner Logo',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_banner',
						comp: 'color-swatches',
						options: {
							label: 'Banner',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_direction',
						comp: 'button-group',
						options: {
							mod: 'Opacity',
							label: 'Direction',
							count: 2,
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_minified',
						comp: 'toggle',
						options: {
							name: 'minified',
							label: 'Show Minified Logo',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		]

		@Watch('default.extras_show_minified', { immediate: true })
		@Watch('default.image_banner', { immediate: true })
		watchObjectImgBanner() {
			this.loadImage();
		}
		@Watch('default.colors_banner')
		watchColorBanner() {
			this.static.usingPreferredColor = false;
		}

		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 870, 65, null);

			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

			if (this.static.activeBanner) {
				if (!variables.extras_show_minified) {
					this.draw_rectangle(ctx, 0, 0, poster.baseWidth, 120, variables.colors_banner, null,  null);
					let width = this.static.activeBanner.width;
					let height = this.static.activeBanner.height;
					const ratio = width / height;
					height = 66;
					width = height * ratio;
					ctx.drawImage(this.static.activeBanner, 500 - (width / 2), 60 - (height / 2), width, height);
				} else {
					ctx.beginPath();
					ctx.moveTo(0, 0);
					ctx.lineTo(0, 220);
					ctx.lineTo(220, 0);
					ctx.lineTo(0, 0);
					ctx.closePath();
					ctx.fillStyle = variables.colors_banner;
					ctx.fill();

					let width = this.static.activeBanner.width;
					let height = this.static.activeBanner.height;
					let ratio = 0;
					if (width > height) {
						ratio = height / width;
						width = 90;
						height = width * ratio;
					} else {
						ratio = width / height;
						height = 65;
						width = height * ratio;
					}
					ctx.drawImage(this.static.activeBanner, 65 - (width / 2), 65 - (height / 2), width, height);
				}
			}

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		loadImage () {
			const image = new Image();
			image.crossOrigin = "anonymous";

			if (this.default.extras_show_minified) {
				image.src = this.default.image_banner.urlMin;
			} else {
				image.src = this.default.image_banner.urlFull;
			}

			this.default.colors_banner = this.default.image_banner.hex;
			image.onload = () => {
				this.static.usingPreferredColor = true;
				this.static.activeBanner = image;
				this.$refs.designer.callPosterDraw();
			};
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
