<template>
	<div class="flex flex-col h-full w-full">
		<div class="pb-4 w-full h-12">
			<span class="text-xl my-auto text-gray-500 dark:text-gray-300 pl-10 md:pl-0">Browse</span>
		</div>
		<div class="flex-1 pt-8 flex flex-col overflow-hidden px-1">
			<div class="ease-in-out duration-500 transform flex flex-col justify-end mx-auto w-full xl:w-3/4 h-28 pb-4" :class="activeSearch ? 'h-12! w-full!' : ''">
				<div class="flex relative">
					<input name="input"
					       v-on:keyup.enter="search()"
					       v-model="searchString"
					       placeholder="Search for a collection, movie, or tv show"
					       class="w-full pl-2 h-9 leading-9 border rounded-full placeholder-gray-300 text-sm text-primary border-primary background-secondary ring-offset-primary focus-ring-offset-primary"
					       :class="activeSearch ? 'pr-14' : 'pr-10'"/>
					<tw-icon v-if="activeSearch" class="absolute right-10 h-9 text-sm text-primary cursor-pointer" weight="fas" icon="times" @click="clearSearch()"/>
					<tw-icon class="absolute right-3 h-9 text-lg background-gradient-b text-transparent! bg-clip-text cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="search" @click="search()"/>
				</div>
				<div class="flex justify-end">
					<div class="flex pt-2">
						<p class="h-3 leading-3 pr-2 text-xs text-dimmed">Powered by</p>
						<img class="h-3" src="./../assets/img/tmdb.svg" alt="tmdb"/>
					</div>
				</div>
			</div>
			<div class="flex-1 overflow-auto">
				<TransitionRoot
					:show="activeSearch"
					enter="transition-opacity duration-500"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity duration-100"
					leave-from="opacity-100"
					leave-to="opacity-0"
					class="h-full">
					<div class="pt-10 flex pb-3 h-full">
						<div>
							<div class="w-72">
								<p class="h-14s leading-10 py-2 px-3 bg-brand-light-to dark:bg-brand-dark-to text-white rounded-t-xl text-md">Search Results</p>
								<div class="py-2 border-2 border-gray-100 dark:border-gray-800 rounded-b-xl space-y-1 bg-gray-0 dark:bg-gray-900">
									<div v-for="tab in tabs"
									     :key="tab.name"
									     :class="tab.current ? 'bg-gray-400 dark:bg-gray-600 text-primary' : 'text-secondary'"
									     class="px-3 flex justify-between  text-sm h-10 leading-6 py-2 cursor-pointer hover:bg-gray-400 dark:hover:bg-gray-600"
									     @click="changeTab(tab)">
										<p class="">{{ tab.name }}</p>
										<p class="rounded-lg px-3 background-tertiary">{{ tab.total_results }}</p>
									</div>
								</div>

								<nav class="text-primary mt-3 bg-gray-0 dark:bg-gray-900 px-2 py-1 flex items-center justify-between h-10 rounded-xl border-2 border-gray-100 dark:border-gray-800">
									<div class="">
										<span v-if="activePageTotal !== null" class="font-medium text-xs leading-8">Showing {{ activePage }} of {{ activePageTotal }} Pages</span>
									</div>
									<div class="flex-1 flex justify-end">
										<tw-icon class="mr-2 p-1 text-xs cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="chevron-left" @click="paginate('back')"/>
										<tw-icon class="p-1 text-xs cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="chevron-right" @click="paginate('next')"/>
									</div>
								</nav>
							</div>
						</div>
						<div class="flex-1 flex flex-col pl-3">
							<div class="flex-1 relative">
								<div class="absolute inset-0 overflow-hidden">
									<div class="h-full w-full overflow-auto space-y-2 ">
										<div v-for="item in tabs.find(el => el.current).results"
										     :key="item.id"
										     class="h-28 cursor-pointer flex border rounded-lg border-gray-100 dark:border-gray-800 overflow-hidden bg-gray-0 dark:bg-gray-900"
										     @click="setData(item)">
											<div class="flex flex-col justify-center h-28">
												<img v-if="item.poster_path" class="item-poster h-full object-contain" :src="`https://image.tmdb.org/t/p/w200/${item.poster_path}`" alt="" onerror="this.style.display='none'">
												<img v-else class="item-poster h-full object-contain" src="./../assets/img/blank_poster.svg" alt="">
											</div>
											<div class="flex-1 flex flex-col px-3 py-2 overflow-hidden">
												<p class="text-primary text-sm m-0 whitespace-nowrap overflow-hidden overflow-ellipsis h-5 leading-5">{{ item.name ? item.name : item.title}}</p>
												<p class="text-dimmed text-xs m-0 overflow-ellipsis h-5 leading-5">({{cleanReleaseDate(item)}})</p>
												<p class="mt-2 text-primary overflow-hidden line-clamp-2 text-xs">{{item.overview}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</TransitionRoot>

				<TransitionRoot
					:show="!activeSearch"
					enter="transition-opacity duration-500"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity duration-500"
					leave-from="opacity-100"
					leave-to="opacity-0"
					class="h-full w-full xl:w-3/4 mx-auto">
					<div class="pt-4 flex pb-3 h-full flex flex-col">
						<div class="flex border-b border-primary pb-4 mb-4">
							<counter/>
<!--							<p class="background-gradient-r text-transparent! bg-clip-text text-5xl">1000</p>-->
							<p class="text-primary pb-2 pl-3 mt-5">Covers and growing</p>
						</div>

						<div class="flex-1 flex flex-col justify-center">

							<p class="text-primary text-lg pb-2">Recently Added:</p>
							<div class="flex space-x-4 mb-4 h-20 md:h-24 lg:h-40 xl:h-44">
								<div v-for="i in 6" :key="i" class="flex flex-col h-full justify-center rounded-md overflow-hidden">
									<img class="item-poster h-full object-contain" src="./../assets/img/blank_poster.svg" alt="">
								</div>
							</div>

							<p class="text-primary text-lg pb-2">Top Downloads:</p>
							<div class="flex space-x-4 mb-4 h-20 md:h-24 lg:h-40 xl:h-44">
								<div v-for="i in 6" :key="i" class="flex flex-col h-full justify-center rounded-md overflow-hidden">
									<img class="item-poster h-full object-contain" src="./../assets/img/blank_poster.svg" alt="">
								</div>
							</div>
						</div>
					</div>
				</TransitionRoot>
			</div>
		</div>
<!--		<div class="flex-1 pt-12 grid grid-cols-12 ">-->
<!--			<div class="flex flex-col col-start-2 col-span-10">-->

<!--			</div>-->

<!--		</div>-->
	</div>
</template>

<script lang="ts">
	import { Vue, Options } from 'vue-class-component';
	import counter from "@/components/custom/poster.counter.vue";
	import axios from "axios";
	import moment from "moment";

	@Options({
		name: 'Browse',
		components: {
			counter
		},
	})
	export default class Browse extends Vue {
		searchString: any = 'avenger';
		activeSearch = false;

		totalCount = 1000;

		tabs: any = [
			{
				name: 'Collection',
				current: true,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
			{
				name: 'Movie',
				current: false,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
			{
				name: 'TV',
				current: false,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
		]

		async search () {
			const _searchString = this.searchString.trim().replace(/ /g,"+");
			if (_searchString.length === 0) return;

			this.tabs.forEach((el) => {
				el.page = 1;
				el.results = [];
				el.total_pages = null;
			});

			await this.getDatabaseList('initial', 'Collection');
			await this.getDatabaseList('initial', 'Movie');
			await this.getDatabaseList('initial', 'TV');
			this.activeSearch = true;
		}

		async getDatabaseList(type, format) {
			let $el = this.tabs.find(el => el.name === format);
			await axios.get(`https://api.themoviedb.org/3/search/${format.toLowerCase()}?api_key=121f04161acaada7f621744b0ba5cea9&page=${$el.page}&query=${this.searchString}`).then((res) => {
				if (type === 'initial') {
					$el.total_pages = res.data.total_pages;
					$el.total_results = res.data.total_results;
				}
				$el.results = res.data.results;
			});
		}

		changeTab(tab) {
			this.tabs.forEach((el) => {
				el.current = false;
			});
			tab.current = true;
		}

		cleanReleaseDate(item) {
			return moment(item.release_date).format('MM/DD/YYYY');
		}

		setData(item) {
			console.log(item);
		}
		clearSearch() {
			this.activeSearch = false;
			this.searchString = '';
			this.tabs.forEach((el) => {
				el.page = 1;
				el.results = [];
				el.total_pages = null;
				el.total_results =0;
			});
		}

		async paginate(direction) {
			let $el = this.tabs.find(el => el.current);
			if (direction === 'next' && $el.page < $el.total_pages) {
				$el.page++;
			} else if (direction === 'back' && $el.page > 1){
				$el.page--;
			}
			await this.getDatabaseList('paging', $el.name);
		}

		get activePage() {
			let $el = this.tabs.find(el => el.current);
			return $el.page;
		}
		get activePageTotal() {
			let $el = this.tabs.find(el => el.current);
			return $el.total_pages;
		}
	}
</script>
