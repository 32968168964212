<template>
	<div class="flex justify-between">
		<p class="text-gray-950 dark:text-gray-50 text-sm leading-6">{{ utility.options.label }}</p>
		<Switch v-model="activeValue" :class="[activeValue ? 'bg-brand-light-to dark:bg-brand-dark-to' : 'background-tertiary', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ring-offset-primary focus-ring-offset-primary']">
			<span :class="[activeValue ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
				<span :class="[activeValue ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
					<svg class="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
						<path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</span>
				<span :class="[activeValue ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
					<svg class="bg-white h-3 w-3 text-brand-light-to dark:text-brand-dark-to" fill="currentColor" viewBox="0 0 12 12">
						<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
					</svg>
				</span>
			</span>
		</Switch>
	</div>
</template>

<script lang="ts">
	import { Options, Prop, Watch, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";

	@Options({
		name: 'TwToggle',
		components: {
		},
	})
	export default class TwToggle  extends mixins(Services) {
		@Prop({ default: false }) value: any;
		@Prop({ default: false }) useEmit: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() disabled: any;
		@Prop() isLast: any;

		activeValue: any = false;

		@Watch('value', { immediate: true })
		watchValue() {
			this.activeValue = this.value;
		}

		@Watch('activeValue')
		watchActiveValue() {
			if (this.disabled) {
				this.activeValue = false;
			}

			if (this.useEmit) {
				this.$emit('updateValue', this.activeValue);
			} else {
				this.valueHandler(this.utility, this.activeValue, this.variables);
			}
		}
	}
</script>
