<template>
	<div class="h-full w-full flex justify-center">
		<div class="flex flex-col justify-center h-full">
			<canvas class="viewer rounded border-gray-200 dark:border-gray-800 light-poster-gradient dark:dark-poster-gradient"
					:width="baseWidth"
					:height="baseHeight"
					@dragover="dragEnter"
					@dragleave="dragExit"
					@drop="dropped"
					@mousedown="mouseDownEvent">
			</canvas>
			<canvas class="export"
					style="display: none"
					:width="baseWidth"
					:height="baseHeight">
			</canvas>
			<canvas class="minified"
					style="display: none"
					:width="baseWidth / 2"
					:height="baseHeight / 2">
			</canvas>
		</div>
	</div>
</template>

<script lang="ts">
	import { Options, Inject, Watch, Prop, mixins } from 'vue-property-decorator';
	import { PosterDetailDto } from '@/helpers/dtos.poster';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import { Services } from "@/mixins/services";

	@Options({
		name: "DesignerPoster",
		components: {
		},
	})
	export default class DesignerPoster extends mixins(Services) {
		@Inject() readonly store!: any;
		@Inject() readonly firebase!: any;

		@Prop() variables: any;
		@Prop() template: any;
		@Prop({ type: Function, required: true }) drawer!: any;

		readonly eventBus: EventBus = EventBus.getInstance();

		displayCanvas: any = null;
		exportCanvas: any = null;
		optimizedCanvas: any = null;
		baseWidth: any = 1000;
		baseHeight: any = 1500;
		dragOver: any = false;

		primaryFileSource: any = null;
		secondaryFileSource: any = null;
		tertiaryFileSource: any = null;
		quaternaryFileSource: any = null;
		quinaryFileSource: any = null;

		primaryFileDetails = new PosterDetailDto();
		secondaryFileDetails = new PosterDetailDto();
		tertiaryFileDetails = new PosterDetailDto();
		quaternaryFileDetails = new PosterDetailDto();
		quinaryFileDetails = new PosterDetailDto();

		@Watch('posterStyle')
		@Watch('primaryFileDetails', { deep: true })
		@Watch('secondaryFileDetails', { deep: true })
		@Watch('tertiaryFileDetails', { deep: true })
		@Watch('quaternaryFileDetails', { deep: true })
		@Watch('quinaryFileDetails', { deep: true })
		watchForRedraw() {
			this.drawDisplayCanvas();
		}
		mounted () {
			this.eventBus.on(EventName.DownloadPoster, this.downloader);
			this.eventBus.on(EventName.ClearPoster, this.clearPoster);
			this.eventBus.on(EventName.UpdatePoster, this.updatePoster);

			window.addEventListener('dragover', this.windowDragListener);
			window.addEventListener('drop', this.windowDropListener);
			this.displayCanvas = this.$el.children[0].children[0];
			this.exportCanvas = this.$el.children[0].children[1];
			this.optimizedCanvas = this.$el.children[0].children[2];
			this.drawDisplayCanvas();
		}

		windowDropListener (e: any) {
			e.preventDefault();
			if (e.target !== this.displayCanvas) return;

			const imageType = this.variables.image_focus;
			const f = e.dataTransfer.files[0];

			if (f.type.match(/image\/(jpg|jpeg|png)/i)) {
				const image = new Image();
				image.onload = this.imageLoad();
				image.src = URL.createObjectURL(f);

				this[`${imageType}FileDetails`].name = f.name.split('.').slice(0, -1).join('.');
				this[`${imageType}FileSource`] = image;
			}
		}

		updatePoster(e) {
			this[`${e.type}FileDetails`][e.variable] = e.value;
		}
		clearPoster(e) {
			this[`${e.type}FileSource`] = null;
			this.drawDisplayCanvas();
		}

		drawDisplayCanvas () {
			const canvas = this.displayCanvas;
			const ctx = canvas.getContext('2d');
			canvas.width = this.posterStyle.width;
			canvas.height = this.posterStyle.height;
			ctx.scale(this.posterStyle.height / this.baseHeight, this.posterStyle.width / this.baseWidth);
			this.drawer(ctx, this.variables, this);
		}

		async drawExportCanvas () {
			const canvas = this.exportCanvas;
			const ctx = canvas.getContext('2d');
			canvas.width = this.baseWidth;
			canvas.height = this.baseHeight;
			this.drawer(ctx, this.variables, this);
		}

		async drawOptimizedCanvas () {
			const minCanvas = this.optimizedCanvas;
			const ctx = minCanvas.getContext('2d');
			const miniWidth = (1000 * 0.2);
			const miniHeight = (1500 * 0.2);
			minCanvas.width = miniWidth;
			minCanvas.height = miniHeight;
			ctx.scale(miniHeight / this.baseHeight, miniWidth / this.baseWidth);
			this.drawer(ctx, this.variables, this);
		}

		dragEnter () {
			this.dragOver = true;
		}

		dragExit () {
			this.dragOver = false;
		}

		dropped (e: any) {
			e.preventDefault();
		}

		windowDragListener (e: any) {
			e.preventDefault();
		}

		imageLoad () {
			return (e: any) => {
				const imageType = this.variables.image_focus;

				let imgRatio = 0;
				let calcPosterWidth = 0;
				let calcPosterHeight = 0;

				if (e.target.naturalWidth >= e.target.naturalHeight) {
					imgRatio = e.target.naturalWidth / e.target.naturalHeight;
					if (this.variables.images[imageType].maxW) {
						const results = this.maxCalculations(imageType, imgRatio, 'width', 1, this.variables.images[imageType].maxW, this.variables.images[imageType].maxH, calcPosterWidth, calcPosterHeight);
						calcPosterWidth = results?.calcPosterWidth;
						calcPosterHeight = results?.calcPosterHeight;

						// IF HEIGHT STILL REMAINS LARGER THAN MAX-H
						if (calcPosterHeight > this.variables.images[imageType].maxH) {
							const results = this.maxCalculations(imageType, imgRatio, 'height', 2, this.variables.images[imageType].maxW, this.variables.images[imageType].maxH, calcPosterWidth, calcPosterHeight);
							calcPosterWidth = results?.calcPosterWidth;
							calcPosterHeight = results?.calcPosterHeight;
						}
					} else {
						calcPosterHeight = this.variables.images[imageType].initialH;
						calcPosterWidth = this.variables.images[imageType].initialH * imgRatio;
					}
				} else {
					imgRatio = e.target.naturalHeight / e.target.naturalWidth;
					if (this.variables.images[imageType].maxH) {
						const results = this.maxCalculations(imageType, imgRatio, 'height', 1, this.variables.images[imageType].maxW, this.variables.images[imageType].maxH, calcPosterWidth, calcPosterHeight);
						calcPosterWidth = results?.calcPosterWidth;
						calcPosterHeight = results?.calcPosterHeight;

						// IF WIDTH STILL REMAINS LARGER THAN MAX-W
						if (calcPosterWidth > this.variables.images[imageType].maxW) {
							const results = this.maxCalculations(imageType, imgRatio, 'width', 2, this.variables.images[imageType].maxW, this.variables.images[imageType].maxH, calcPosterWidth, calcPosterHeight);
							calcPosterWidth = results?.calcPosterWidth;
							calcPosterHeight = results?.calcPosterHeight;
						}
					} else {
						calcPosterWidth = this.variables.images[imageType].initialW;
						calcPosterHeight = this.variables.images[imageType].initialW * imgRatio;
					}
				}

				let top = 0;
				let left = 0;
				if (this.variables.images[imageType].initialX !== null) {
					if (this.variables.images[imageType].positionX === 'left') {
						left = this.variables.images[imageType].initialX;
					} else {
						left = this.variables.images[imageType].initialX - (calcPosterWidth / 2);
					}
				} else {
					if (calcPosterWidth > this.baseWidth) {
						left = (this.baseWidth - calcPosterWidth) / 2;
					} else {
						left = ((this.baseWidth / 2) - (calcPosterWidth / 2));
					}
				}

				if (this.variables.images[imageType].initialY !== null) {
					top = this.variables.images[imageType].initialY - (calcPosterHeight / 2);
				} else {
					if (calcPosterHeight > this.baseHeight) {
						top = (this.baseHeight - calcPosterHeight) / 2;
					} else {
						top = (this.baseHeight - calcPosterHeight) / 2;
					}
				}


				let _details = (this as { [key: string]: any })[`${imageType}FileDetails`] as any;
				if (this.variables.images[imageType].boundW) {
					_details.mode = 'clipped';
				}

				_details.ratio = imgRatio;
				_details.width = calcPosterWidth;
				_details.height = calcPosterHeight;
				_details.scale = this.variables.images[imageType].scale;
				_details.blockMove = this.variables.images[imageType].blockMove;
				_details.offset = {
					top: top,
					left: left
				};

				this.drawDisplayCanvas();
				this.$emit('imageLoaded');
			};
		}

		maxCalculations (imageType: any, ratio: any, priority: any, sequence: any, maxW: any, maxH: any, width: any, height: any) {
			if (sequence === 1) {
				if (priority === 'width') {
					return {
						calcPosterWidth: maxW,
						calcPosterHeight: maxW / ratio
					};
				} else if (priority === 'height') {
					return {
						calcPosterHeight: maxH,
						calcPosterWidth: maxH / ratio
					};
				}
			} else if (sequence === 2) {
				if (priority === 'width') {
					return {
						calcPosterWidth: maxW,
						calcPosterHeight: maxW * (height / width)
					};
				} else if (priority === 'height') {
					return {
						calcPosterHeight: maxH,
						calcPosterWidth: maxH * (width / height)
					};
				}
			}
		}

		async downloader (e) {
			try {
				await this.drawExportCanvas();
				const canvas = this.exportCanvas;
				const link = document.createElement('a');
				canvas.toBlob((blob) => {
					let _tempName = this.primaryFileDetails.name.length > 0 ? this.primaryFileDetails.name : 'coverlabs_poster';
					const fileTitle = e.saveVariables.title.length > 0 ? e.saveVariables.title : _tempName;
					link.download = `${fileTitle}.jpg`;
					document.body.append(link);
					link.href = window.URL.createObjectURL(blob);
					link.click();
					document.body.removeChild(link);
				}, 'image/jpeg', e.downloadVariables.quality / 100);
				if (e.saveVariables.saveToLibrary) {
					await this.drawOptimizedCanvas();
					await this.saveReference(canvas, e.saveVariables);
				}
			} catch (err) {
				alert(err);
			}
		}

		async saveReference (canvas, saveDetails) {
			await this.firebase.functions().httpsCallable('addPoster')({
				tmdbId: saveDetails.id,
				tmdbType: saveDetails.type,
				tmdbTitle: saveDetails.title,
				template: this.template,
			}).then(async (result: any) => {
				if(!('error' in result.data)) {
					await this.firebase.firestore().collection('library/').doc(result.data.id).update({ id: result.data.id });
					await this.saveImages(result.data.id);
					this.notify('success', 'Saved', 'Poster saved to the Library');
				} else {
					this.notify('danger', 'Insert Failed', result.data.error);
				}
			}).catch((error: any) => {
				this.notify('danger', 'Insert Failed', error);
			});
		}

		saveImages (id: any) {
			const posterUrl = this.exportCanvas.toDataURL('image/jpeg', 100);
			const posterRef = this.firebase.storage().ref().child(`posters/original/${id}.jpg`);
			posterRef.putString(posterUrl, 'data_url').then((fileSnapshot: any) => {
				fileSnapshot.ref.getDownloadURL().then((url) => {
					this.firebase.firestore().collection('library/').doc(id).update({ originalPoster: url });
				});
			});

			const optimizedUrl = this.optimizedCanvas.toDataURL('image/jpeg', 90);
			const optimizedRef = this.firebase.storage().ref().child(`posters/optimized/${id}.jpg`);
			optimizedRef.putString(optimizedUrl, 'data_url').then((fileSnapshot: any) => {
				fileSnapshot.ref.getDownloadURL().then((url) => {
					this.firebase.firestore().collection('library/').doc(id).update({ optimizedPoster: url });
				});
			});
		}

		mouseMoveEvent (e: any) {
			const imageType = this.variables.image_focus;
			const scaleWidth = this.posterStyle.width / this.baseWidth;
			const scaleHeight = this.posterStyle.height / this.baseHeight;

			let _details = (this as { [key: string]: any })[`${imageType}FileDetails`] as any;
			if (_details.blockMove) return;

			if (_details.mode === 'clipped') {
				_details.offset.left += ((e.movementX / scaleWidth) * _details.ratio);
				_details.offset.top += ((e.movementY / scaleHeight) * _details.ratio);
			} else {
				_details.offset.left += (e.movementX / scaleWidth);
				_details.offset.top += (e.movementY / scaleHeight);
			}
		}

		mouseDownEvent () {
			if (!this.primaryFileSource && !this.secondaryFileSource && !this.tertiaryFileSource && !this.quaternaryFileSource && !this.quinaryFileSource) return;
			window.addEventListener("mousemove", this.mouseMoveEvent);
			window.addEventListener("mouseup", this.mouseUpEvent);
		}

		mouseUpEvent () {
			window.removeEventListener('mouseup', this.mouseUpEvent);
			window.removeEventListener('mousemove', this.mouseMoveEvent);
		}

		beforeUnmount() {
			this.eventBus.off(EventName.DownloadPoster, this.downloader);
			this.eventBus.off(EventName.ClearPoster, this.clearPoster);
			this.eventBus.off(EventName.UpdatePoster, this.updatePoster);
			window.removeEventListener('dragover', this.windowDragListener);
			window.removeEventListener('drop', this.windowDropListener);
		}

		get posterStyle() {
			let segment = 0;
			if (this.windowWidth >= 1536) {
				segment = ((this.windowHeight - 300) / 3);
			} else if (this.windowWidth >= 1280) {
				segment = (this.windowWidth - 780) / 3;
			} else if (this.windowWidth >= 1024) {
				segment = (this.windowWidth - 700) / 3;
			} else {
				segment = 100;
			}

			// if (((this.windowHeight - 450) / 3) < segment) {
			// 	segment = 50;
			// }
			return {
				height: segment * 3,
				width: segment * 2,
			};
		}
		get windowWidth() {
			return this.store.state.windowWidth;
		}
		get windowHeight() {
			return this.store.state.windowHeight;
		}
		get firebaseCustomer() {
			return this.store.state.firebaseCustomer;
		}
	}
</script>


<style scoped lang="scss">
	canvas {
		image-rendering: crisp-edges;
	}
</style>
