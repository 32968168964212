
	import { Options, Prop, Watch, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import VueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/default.css';

	@Options({
		name: 'TwRangeSlider',
		components: {
			VueSlider
		},
	})
	export default class TwRangeSlider extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;
		@Prop({default: false}) useEmit: any;

		activeValue: any = 0;

		@Watch('value', { immediate: true })
		watchValue() {
			this.activeValue = this.value;
		}

		@Watch('activeValue')
		watchActiveValue() {
			if (this.activeValue > this.utility.options.max) {
				this.activeValue = this.utility.options.max;
			} else if (this.activeValue < this.utility.options.min) {
				this.activeValue = this.utility.options.min;
			}
			if (this.useEmit) {
				this.$emit('updateValue', this.activeValue);
			} else {
				this.valueHandler(this.utility, this.activeValue, this.variables);
			}
		}
	}
