<template>
	<div id="designer-foundation" class="flex flex-col h-full w-full relative">
		<div class="absolute inset-0">
			<div class="w-full h-full flex flex-col-reverse lg:flex-row">
				<div class="h-full pb-4 lg:mr-6 lg:pt-6">
					<template v-if="windowWidth >= 1023">
						<div class="flex h-full w-full">
							<designer-controls :controls="computedControls"
							                   :activeControl="activeControl"
							                   :isDropdown="windowWidth < 1023"
							                   @setActive="activeControl = $event"/>
							<designer-utilities :controls="computedControls"
							                    :activeControl="activeControl"
							                    :variables="computedVariables"/>
						</div>
					</template>
					<template v-else>
						<div class="flex flex-col h-full pt-4">
							<designer-controls :controls="computedControls"
							                   :activeControl="activeControl"
							                   :isDropdown="windowWidth < 1023"
							                   @setActive="activeControl = $event"/>
							<designer-utilities :controls="computedControls"
							                    :activeControl="activeControl"
							                    :variables="computedVariables"/>
						</div>

					</template>
				</div>
				<div class="flex flex-1 lg:h-full">
					<designer-poster ref="poster"
					                 :template="name"
					                 :drawer="drawer"
					                 :variables="computedVariables"
					                 @imageLoaded="$emit('imageLoaded')"/>

				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Vue, Options, Inject, Watch, Prop} from 'vue-property-decorator';
	import designerPoster from '@/components/custom/designer.poster.vue';
	import designerControls from '@/components/custom/designer.controls.vue';
	import designerUtilities from '@/components/custom/designer.utilities.vue';
	import {EventName, EventBus} from "@/helpers/event-bus";

	@Options({
		name: "DesignerFoundation",
		components: {
			designerPoster,
			designerControls,
			designerUtilities
		},
	})
	export default class DesignerFoundation extends Vue {
		@Inject() readonly firebase!: any;
		@Inject() readonly store!: any;

		@Prop() name;
		@Prop() static;
		@Prop() imported;
		@Prop() default;
		@Prop() controls;
		@Prop({ type: Function, required: true }) drawer!: any;
		readonly eventBus: EventBus = EventBus.getInstance();

		$refs: any;

		activeControl = 0;
		computedVariables: any = null;

		additionalControls: any = [
			{ name: 'Presets' },
			{ name: 'Save/Download' }
		];


		@Watch('computedVariables', {deep: true})
		posterDraw() {
			this.callPosterDraw();
		}

		beforeMount() {
			this.eventBus.on(EventName.UpdateVariable, this.updateVariableValue);
			this.computedVariables = Object.keys(this.imported).length > 0 ? this.imported : this.default;
		}
		updateVariableValue(e) {
			this.computedVariables[e.variable] = e.value;
		}
		callPosterDraw () {
			if (this.$refs.poster) {
				this.$refs.poster.drawDisplayCanvas();
			}
		}

		get windowWidth() {
			return this.store.state.windowWidth;
		}

		get computedControls() {
			let arr =  this.controls.concat(this.additionalControls);
			arr.forEach((el, index) => {
				el['index'] = index;
			});
			return arr;
		}
	}

</script>

<style scoped lang="scss">
	html:not(.dark) {
		.option-selector:not(.active):hover {
			.icon, p {
				color: #191f28;
			}
		}
	}
	html.dark {
		.option-selector:not(.active):hover {
			.icon, p {
				color: #f9fafb;
			}
		}
	}
</style>
