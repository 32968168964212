
	import { Options, Prop, Watch, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";

	@Options({
		name: 'TwToggle',
		components: {
		},
	})
	export default class TwToggle  extends mixins(Services) {
		@Prop({ default: false }) value: any;
		@Prop({ default: false }) useEmit: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() disabled: any;
		@Prop() isLast: any;

		activeValue: any = false;

		@Watch('value', { immediate: true })
		watchValue() {
			this.activeValue = this.value;
		}

		@Watch('activeValue')
		watchActiveValue() {
			if (this.disabled) {
				this.activeValue = false;
			}

			if (this.useEmit) {
				this.$emit('updateValue', this.activeValue);
			} else {
				this.valueHandler(this.utility, this.activeValue, this.variables);
			}
		}
	}
