<template>
	<Listbox as="div" v-model="selected">
		<label v-if="label !== ''" class="block text-xs text-secondary">{{ label }}</label>
		<div class="mt-1 relative">
			<ListboxButton class="text-sm focus-ring-offset-primary ring-offset-primary relative cursor-pointer w-full border rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none"
			               :class="[style.selectMenu.text, style.selectMenu.background, style.selectMenu.border, style.selectMenu.focus]">
				<span v-if="Object.keys(selected).length === 0" class="block h-5 truncate text-dimmed">Select Option</span>

				<template v-else>
					<img v-if="modifier === 'TV'" class="block mx-auto truncate h-5" :alt="selected.name" :src="selected.value">
					<span v-else-if="modifier === 'FontAwesome'" class="flex h-5 truncate">
						<i class="fa pr-3 leading-5" :data-title="generateIcon(selected.value)"></i>
						<span>{{ selected.name }}</span>
					</span>
					<span v-else class="block h-5 truncate">{{ selected.name }}</span>
				</template>

				<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
					<tw-icon icon="arrows-v" class="h-5 w-5 text-dimmed"/>
                </span>
			</ListboxButton>

			<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
				<ListboxOptions class="absolute cursor-pointer z-10 mt-1 w-full border shadow-2xl max-h-60 rounded-md overflow-auto focus:outline-none text-sm"
				                :class="[style.options.background, style.options.border, style.options.focus, style.options.offset]">
					<ListboxOption as="template" v-for="option in options" :key="option.id" :value="option" v-slot="{ active, selected }">
						<li class="pl-8 cursor-default select-none relative py-2 px-4"
						    :style="[modifier === 'FontFamily' ? `font-family: ${option.name} !important;` : '']"
						    :class="[active ? style.options.activeRow : style.options.text]">

							<img v-if="modifier === 'TV'" class="block mx-auto cursor-pointer truncate h-5" :alt="option.name" :src="option.value">
							<span v-else-if="modifier === 'Network'" class="flex justify-between" >
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.name }}</span>
								<img class="cursor-pointer truncate h-4" :alt="option.name" :src="option.value.url">
							</span>
							<span v-else-if="modifier === 'FontAwesome'" class="flex h-5 truncate">
								<i class="fa pr-3 leading-5" :data-title="generateIcon(option.value)"></i>
								<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.name }}</span>
							</span>
							<span v-else :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ option.name }}</span>

							<span v-if="selected" :class="[active ? 'text-white' : style.options.activeText, 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
								<tw-icon class="h-5 w-5"  weight="fas" icon="check"/>
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
		</div>
	</Listbox>
</template>
<script lang="ts">
	import { Vue, Options, Prop, Watch } from 'vue-property-decorator';

	@Options({
		name: 'TwSelectMenu',
		components: {
		},
	})
	export default class TwSelectMenu extends Vue {
		@Prop({default: ''}) label;
		@Prop({default: null}) value;
		@Prop({default: []}) options;
		@Prop({default: 'primary'}) variant;
		@Prop({default: null}) modifier;

		selected: any = null;

		@Watch('value', {immediate: true})
		watchValue() {
			this.selected = this.value !== null ? this.value : this.options[0];
		}

		@Watch('selected')
		watchActiveValue() {
			this.$emit('updateValue', this.selected);
		}

		get style () {
			if (this.variant === 'secondary') {
				return {
					selectMenu: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'bg-white dark:bg-gray-900',
						focus: 'focus-ring-offset-primary',
					},
					options: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'bg-white dark:bg-gray-900',
						activeRow: 'text-white bg-brand-light-to dark:bg-brand-dark-to',
						activeText: 'text-brand-light-to dark:text-brand-dark-to',
						focus: 'focus-ring-offset-primary',
						offset: 'ring-offset-primary'
					}
				};
			} else {
				return {
					selectMenu: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'background-secondary',
						focus: 'focus-ring-offset-primary',
					},
					options: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'background-secondary',
						activeRow: 'text-white bg-brand-light-to dark:bg-brand-dark-to',
						activeText: 'text-brand-light-to dark:text-brand-dark-to',
						focus: 'focus-ring-offset-primary',
						offset: 'ring-offset-primary'
					}
				};
			}
		}


		generateIcon (font) {
			return JSON.parse(`["\\u${font}"]`)[0];
		}
	}
</script>

<style scoped lang="scss">
	.fa:before {
		font-weight: 900;
		content: attr(data-title) !important;
	}
</style>
