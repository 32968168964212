
import { Vue, Options, Prop } from 'vue-property-decorator';

@Options({
	name: 'DesignerPresets',
	components: {
	},
})
export default class DesignerPresets extends Vue {

}
