<template>
	<div class="flex flex-col h-full overflow-auto relative">
		<div class="flex flex-col pt-9 pb-4">
			<p class="text-3xl text-brand-light-to dark:text-brand-dark-to text-center pb-5">Create beautiful templates in minutes</p>
			<p class="text-md text-primary text-center">Choose your plan. We'll love you no matter what you go with</p>
		</div>
		<div class="flex flex-1 flex-col justify-center">
			<div class="flex flex-wrap-reverse justify-center">
				<div class="mx-8 mt-5 h-130 px-8 py-10 background-primary free flex flex-col w-80 border-2 border-primary">
					<div class="flex-1">
						<div class="flex flex-col">
							<p class="name font-bold text-2xl leading-9 text-brand-alternate-to">Free</p>
							<p class="text-xs mt-2 text-primary">For Personal use to try Coverlabs for an unlimited period of time.</p>
						</div>
						<div class="cost flex border-b border-secondary h-20 my-6 pb-6">
							<p class="text-6xl text-brand-alternate-to">$0</p>
							<p class="label text-sm text-primary leading-5 pt-8 pl-2">Per Year</p>
						</div>
						<div class="details">
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Unlimited Downloads</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Access to Designer</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Lifetime Updates</p>
							</div>
						</div>
					</div>
					<router-link :to="{ path: '/' }">
						<p class="cursor-pointer background-gradient-alt-r h-10 leading-10 text-center w-full text-white border rounded-lg border-primary">Use Coverlabs for Free</p>
					</router-link>
				</div>
				<div class="mx-8 mt-5 h-130 px-8 py-10 premium flex flex-col w-80 relative border-2 border-primary">
					<p class="m-0 caption rounded-full text-white absolute -top-4 text-center w-64 h-8 leading-8 background-gradient-r">MOST POPULAR CHOICE</p>

					<div class="flex-1">
						<div class="flex flex-col">
							<p class="name m-0 font-bold text-2xl leading-9 text-brand-light-to dark:text-brand-dark-to">Premium</p>
							<p class="text-xs mt-2 text-primary">For full access to designer and library, along with unlimited downloads.</p>
						</div>
						<div class="cost flex border-b border-secondary h-20 my-6 pb-6">
							<p class="text-6xl text-brand-light-to dark:text-brand-dark-to">$10</p>
							<p class="label text-sm text-primary leading-5 pt-8 pl-2">Per Year</p>
						</div>

						<div class="details">
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Unlimited Downloads</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Access to All Pages</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Create Template Presets</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Upload to Library</p>
							</div>
							<div class="flex h-4 mb-4">
								<tw-icon weight="fas" icon="check-circle" class="leading-4 text-green-600"/>
								<p class="pl-3 text-sm text-primary leading-4">Lifetime Updates</p>
							</div>
						</div>
					</div>
					<p class="cursor-pointer background-gradient-r h-10 leading-10 text-center w-full text-white border rounded-lg border-primary" @click="goPremium()">Buy Premium</p>
				</div>
			</div>
		</div>
		<div class="stripe-management flex p-4 justify-end">
			<p class="text-xs leading-5 text-primary pr-2">Powered By</p>
			<img class="h-5" src="./../assets/img/stripe.svg" alt="">
		</div>

		<div class="absolute left-0 bottom-4 w-40">
			<tw-button text="Show Beta Card" @click="betaAlertVisible = true"></tw-button>
		</div>

		<coming-soon :visible="showComingSoon"
		            @close="showComingSoon = false"/>
		<beta-alert :visible="betaAlertVisible"
		            @close="betaAlertVisible = false"/>
	</div>
</template>

<script lang="ts">
	import { Vue, Options, Inject } from "vue-property-decorator";
	// import { loadStripe } from "@stripe/stripe-js";
	import comingSoon from "@/components/modals/coming-soon.vue";
	import betaAlert from "@/components/modals/beta-premium.vue";

	@Options({
		name: 'Premium',
		components: {
			betaAlert,
			comingSoon
		},
	})
	export default class Premium extends Vue {
		@Inject() readonly firebase!: any;
		@Inject() readonly $store!: any;
		betaAlertVisible = false;
		showComingSoon = false;


		async goPremium() {
			this.showComingSoon = true;
			// const testPublishedKey = 'pk_test_51IWAKLKRAT62QOAPYrh91B4ILHVlkR0HdN7IWrl98MeS14UqjpeCUsVaSSDojL28lvAAEbRS9miEJFNx7ghGO23L00ikXV148B';
			// // const testPremiumPrice = 'price_1IWALiKRAT62QOAPWZXFGhGP';
			// const testDailyPrice = 'price_1IWkmFKRAT62QOAPaCurcvM5';
			//
			// const docRef = await this.firebase.firestore()
			// 	.collection('customers')
			// 	.doc(this.firebaseCustomer.uid)
			// 	.collection('checkout_sessions')
			// 	.add({
			// 		'price': testDailyPrice,
			// 		'success_url': window.location.origin,
			// 		'cancel_url': window.location.origin,
			// 	});
			//
			// // Wait for the CheckoutSession to get attached by the extension
			// docRef.onSnapshot(async (snap: any) => {
			// 	const { error, sessionId } = snap.data();
			// 	if (error) {
			// 		// Show an error to your customer and
			// 		// inspect your Cloud Function logs in the Firebase console.
			// 		alert(`An error occured: ${error.message}`);
			// 	}
			// 	if (sessionId) {
			// 		// We have a session, let's redirect to Checkout
			// 		// Init Stripe
			// 		const stripe = await loadStripe(testPublishedKey);
			// 		stripe?.redirectToCheckout({
			// 			sessionId: sessionId
			// 		}).then((res: any) => {
			// 			console.log(res);
			// 		});
			// 	}
			// });
		}

		get firebaseCustomer() {
			return this.$store.state.firebaseCustomer;
		}
	}
</script>


<style scoped lang="scss">
	.premium {
		border-image-slice: 1;
	}
	html:not(.dark) {
		.premium {
			border-image-source: linear-gradient(to right, #FFB88C, #E56590);
		}
	}
	html.dark {
		.premium {
			border-image-source: linear-gradient(to right, #DC92B2, #7D4CF5);
		}
	}
</style>
