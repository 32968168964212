
import {Vue, Options, Inject} from 'vue-property-decorator';
	import axios from 'axios';

	@Options({
		name: 'Login',
		components: {
		},
	})
	export default class Login extends Vue {
		@Inject() readonly store!: any;
		@Inject() readonly firebase!: any;

		backdrops: any = [];
		ready: any = false;

		async beforeMount() {
			await axios.get('https://api.themoviedb.org/3/movie/popular?api_key=121f04161acaada7f621744b0ba5cea9').then(res => {
				res.data.results.forEach((el: any, index: any) => {
					if (index > 3 ) return;
					this.backdrops.push(`https://image.tmdb.org/t/p/original${el.backdrop_path}`);
				});
			}).then(() => {
				this.ready = true;
			});
		}
	}
