
import {Options, Prop, Inject, Vue} from 'vue-property-decorator';

@Options({
	name: 'PremiumFeature',
	components: {
	},
})
export default class PremiumFeature extends Vue {
	@Inject() store;
	@Prop() visible;

	close() {
		this.$emit('close');
	}
	get icon() {
		return this.store.state.theme === 'light' ? 'coverlabs-icon-light' : 'coverlabs-icon-dark';
	}
	get logo() {
		return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
	}
}
