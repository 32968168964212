
	import { Options, mixins } from 'vue-property-decorator';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Acadia",
		components: {
			designer
		},
	})
	export default class Acadia extends mixins(Functions) {
		$refs: any;

		name = 'Acadia';
		imported: any = {};
		static = {
			fontFamily_year: 'Eighty Cologne',
			fontFamily_decades: 'Eighty Hong Kong',
			text_secondary: 'DECADES',
			text_primarySize: 253,
			text_secondarySize: 186,
			width_longBar: 248,
			width_shortBar: 154,
			width_runnerBar: 21,
			width_tag: 694,
			height_tag: 337,
			height_bar: 21,
			height_barSpace: 75,
		};
		default: any = {
			text_primary: '1990',
			colors_background: '#232930',
			colors_bars: '#FFFFFF',
			colors_tag: '#FF0018',
			colors_text_primary: '#FFFFFF',
		};

		controls: any = [
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'integer-ticker',
						options: {
							label: 'Decade',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_bars',
						comp: 'color-swatches',
						options: {
							label: 'Timeline Bars',
						}
					},
					{
						variable: 'colors_tag',
						comp: 'color-swatches',
						options: {
							label: 'Tag',
						}
					},
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Year Font',
						}
					},
				]
			},
		]

		drawer (ctx: any, variables: any, poster: any) {
			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, poster.baseHeight, variables.colors_background, null, null);
			this.draw_rectangle(ctx, 0, 0, this.static.width_runnerBar, poster.baseHeight, variables.colors_bars, null,null);

			let vertOffset = 0;
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars,null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars, null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_shortBar, this.static.height_bar, variables.colors_bars, null, this.static.height_barSpace);
			vertOffset += this.draw_rectangle(ctx, 0, vertOffset, this.static.width_longBar, this.static.height_bar, variables.colors_bars, null, this.static.height_barSpace);

			vertOffset = 968;
			this.draw_rectangle(ctx, 270, vertOffset, this.static.width_tag, this.static.height_tag, variables.colors_tag, null, null);

			ctx.fillStyle = variables.colors_tag;
			ctx.beginPath();
			ctx.moveTo(150, vertOffset + (this.static.height_tag / 2));
			ctx.lineTo(275, vertOffset + 100);
			ctx.lineTo(275, (vertOffset + this.static.height_tag) - 100);
			ctx.closePath();
			ctx.fill();

			this.write_textLine(ctx, variables.text_primary + "'s", 290, 1160, this.static.text_primarySize, this.static.fontFamily_year, variables.colors_text_primary, 'normal', 'normal', 'start', 'alphabetic');
			this.write_textLine(ctx, this.static.text_secondary, 515, 1315, this.static.text_secondarySize, this.static.fontFamily_decades, variables.colors_background, 'normal', 'normal', 'start', 'alphabetic');
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
