
import {Options, Vue} from 'vue-property-decorator';
import sidebar from './sidebar.vue';
import {
	MenuAlt2Icon,
	XIcon,
} from '@heroicons/vue/outline';

@Options({
	name: 'Navigation',
	components: {
		sidebar,
		MenuAlt2Icon,
		XIcon,
	},
})
export default class Navigation extends Vue {
	sidebarOpen = false;
}
