
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Shoshone",
		components: {
			designer
		},
	})
	export default class Shoshone extends mixins(Functions) {
		$refs: any;

		name = 'Shoshone';
		imported: any = {};
		static: any = {
			text_secondary: 'COLLECTION',
			height_highlightBar: 10,
			height_panel: 470,
			object_plexLogo: {
				url: 'https://firebasestorage.googleapis.com/v0/b/coverlabs-io.appspot.com/o/resources%2Fsystem%2Fplex.png?alt=media&token=e1eefc19-8b40-4b38-bfb2-442205ab120d',
				blob: null,
			},
		};
		default: any = {
			text_primary: 'COLLECTION NAME',
			text_fontFamily: 'Open Sans',
			text_primarySize: 90,
			text_alignment: 'center',
			colors_text_primary: '#FFFFFF',
			colors_text_secondary: '#FFFFFF',
			colors_background: '#232930',
			colors_highlightBar: '#CA7B19',
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
			}
		}

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Collection',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 50,
							max: 150,
							type: "px",
						}
					},
					{
						variable: 'text_alignment',
						comp: 'button-group',
						options: {
							mod: 'Alignment',
							label: 'Alignment',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Primary Font',
						}
					},
					{
						variable: 'colors_text_secondary',
						comp: 'color-swatches',
						options: {
							label: 'Secondary Font',
						}
					},
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_highlightBar',
						comp: 'color-swatches',
						options: {
							label: 'Highlight Bars',
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		];

		created () {
			this.loadImage();
		}

		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 750, 65, null);

			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, this.static.height_panel, variables.colors_background, null, null);
			this.draw_rectangle(ctx, 0, this.static.height_panel, poster.baseWidth, this.static.height_highlightBar, variables.colors_highlightBar, null, null);
			this.draw_rectangle(ctx, 0, 1500 - this.static.height_panel - this.static.height_highlightBar, poster.baseWidth, this.static.height_highlightBar, variables.colors_highlightBar, null, null);
			this.draw_rectangle(ctx, 0, 1500 - this.static.height_panel, poster.baseWidth, this.static.height_panel, variables.colors_background, null, null);

			const lineSizePrimarySingle = this.calc_singleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily);
			const lineSizePrimary = this.calc_multipleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily, variables.text_primary, 840, lineSizePrimarySingle);
			const yOffset = lineSizePrimary.height > lineSizePrimarySingle ? (this.static.height_panel / 2) - (lineSizePrimary.height / 5) : (this.static.height_panel / 2);

			this.write_textWrap(ctx, variables.text_primary, this.calc_alignmentOffset(variables.text_alignment), yOffset, variables.text_primarySize, variables.text_fontFamily, 'normal', variables.colors_text_primary, lineSizePrimarySingle, 'normal', variables.text_alignment, 'middle', 840, 'forward');

			if (this.static.object_plexLogo.blob) {
				ctx.drawImage(this.static.object_plexLogo.blob, 552, 1205, 426, 139);
			}
			this.write_textLine(ctx, this.static.text_secondary, 552, 1360, 118, 'Bebas Neue', variables.colors_text_secondary, 'normal', 'normal', 'start', 'top');

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		loadImage () {
			const image = new Image();
			image.crossOrigin = "anonymous";
			image.src = this.static.object_plexLogo.url;
			image.onload = () => {
				this.static.object_plexLogo.blob = image;
				this.$refs.designer.callPosterDraw();
			};
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
