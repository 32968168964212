<template>
	<tw-modal :visible="visible"
	          submitText="Okay!"
	          :showCancelButton="false"
	          @submit="close">
		<template v-slot:content>
			<div class="sm:flex sm:items-start w-full">
				<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
					<tw-icon class="text-red-600" size="lg" icon="exclamation-triangle"/>
				</div>
				<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
					<DialogTitle as="h3" class="text-lg leading-6 font-medium text-primary">Feature Disabled</DialogTitle>
					<div class="mt-2">
						<p class="text-sm text-gray-500 dark:text-gray-200 mb-2">Sorry, Premium will be added soon!</p>
					</div>
				</div>
			</div>
		</template>
	</tw-modal>
</template>

<script lang="ts">
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'PremiumFeature',
	components: {
	},
})
export default class PremiumFeature extends mixins(Services) {
	@Inject() store;
	@Inject() firebase;
	@Prop() visible;

	close() {
		this.$emit('close');
	}
	submit() {
		this.$router.push({ path: '/premium', replace: true });
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}
}
</script>
