
	import { Vue, Options } from 'vue-class-component';

	@Options({
		name: 'Library',
		components: {
		},
	})
	export default class Library extends Vue {
	}
