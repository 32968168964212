
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";
	// import colorPicker from 'vue-color-picker-wheel';

	@Options({
		name: 'ClColorSwatches',
		components: {
			// colorPicker
		},
	})
	export default class ClColorSwatches extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		activeValue: any = '';
		colorPick: any = '';
		colors = {
			red: '#FF0018',
			orange: '#FF6226',
			yellow: '#FFB63B',
			green: '#00D18B',
			purple: '#9F1AE6',
			blue: '#0096DE',
			white: '#FFFFFF',
			lightGrey: '#BDBDBD',
			middleGrey: '#757575',
			darkgrey: '#232930',
			black: '#000000',
		};

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.activeValue = this.value;
			this.colorPick = this.value;
		}
		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}
		@Watch('colorPick')
		watchColorPick() {
			this.activeValue = this.colorPick;
		}

		colorClicked (color: any) {
			this.activeValue = color;
		}
	}
