
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Rainier",
		components: {
			designer
		},
	})
	export default class Rainier extends mixins(Functions) {
		name = 'Rainier';
		imported: any = {};
		static: any = {
			text_secondarySize: 48,
			height_divider: 6,
			width_divider: 300,
			padding_horizontal: 100,
			maxWidth: 800,
		};
		default: any = {
			text_primary: 'COLLECTION NAME',
			text_secondary: 'COLLECTION',
			text_fontFamily: 'HK Grotesk',
			text_primarySize: 140,
			colors_text_primary: '#FFFFFF',
			colors_text_secondary: '#FFFFFF',
			colors_divider: '#FF0018',
			overlay_color: '#000000',
			overlay_opacity: 70,
			overlay_direction: 'down',
			extras_show_divider: true,
			extras_show_subtitle: true,
			extras_show_bold: false,
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
			}
		};

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Collection Name',
						}
					},
					{
						variable: 'text_secondary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Subtitle Text',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 58,
							max: 163,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Primary Font',
						}
					},
					{
						variable: 'colors_text_secondary',
						comp: 'color-swatches',
						options: {
							label: 'Secondary Font',
						}
					},
					{
						variable: 'colors_divider',
						comp: 'color-swatches',
						options: {
							label: 'Divider Bar',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_direction',
						comp: 'button-group',
						options: {
							mod: 'Opacity',
							label: 'Direction',
							count: 2,
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_divider',
						comp: 'toggle',
						options: {
							name: 'divider',
							label: 'Show Divider',
						}
					},
					{
						variable: 'extras_show_subtitle',
						comp: 'toggle',
						options: {
							name: 'collection',
							label: 'Show Subtitle Text',
						}
					},
					{
						variable: 'extras_show_bold',
						comp: 'toggle',
						options: {
							name: 'bolder',
							label: 'Bold Collection Name',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		];

		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 1100, 65, null);

			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

			if (variables.extras_show_divider) {
				this.draw_rectangle(ctx, this.static.padding_horizontal, 510, this.static.width_divider, this.static.height_divider, variables.colors_divider, null, null);
			}

			const lineSizePrimarySingle = this.calc_singleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily);
			this.write_textWrap(ctx, variables.text_primary, this.static.padding_horizontal - 10, 485, variables.text_primarySize, variables.text_fontFamily, variables.extras_show_bold ? 'bold' : 'normal', variables.colors_text_primary, lineSizePrimarySingle, 'normal', 'start', 'bottom', this.static.maxWidth, 'reverse');
			if (variables.extras_show_subtitle) {
				this.write_textLine(ctx, variables.text_secondary, this.static.padding_horizontal, 575, this.static.text_secondarySize, variables.text_fontFamily, variables.colors_text_secondary, 'normal', '1', 'start', 'top');
			}
			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
