<template>
	<tw-modal :visible="visible"
	          submitText="Acknowledge"
	          :showCancelButton="false"
	          @submit="close">
		<template v-slot:content>
			<div class="w-full">
				<div class="flex justify-center">

					<div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
						<tw-icon class="text-blue-600" size="lg" icon="credit-card"/>
					</div>
				</div>
				<div class="mt-3 text-center px-4 w-full">
					<div class="mt-2 mb-6 text-center">
						<p class="text-lg leading-6 font-medium text-primary text-lg mb-2">Thank you for testing Coverlabs Beta!</p>
						<p class="text-xs text-gray-500 dark:text-gray-200 mb-2">Live payments are disabled, to test Premium for free please use the below information.</p>
					</div>
					<div class="max-w-md mx-auto dark-poster-gradient rounded-lg overflow-hidden md:max-w-sm mb-6">
						<div class="md:flex">
							<div class="w-full p-4">
								<div class="flex justify-between items-center text-white">
									<span class="text-2xl font-bold opacity-50">Beta Card</span>
									<img class="h-5 overflow-visible" :src="require(`../../assets/img/${icon}.svg`)" alt="Coverlabs Logo">
								</div>
								<div class="flex justify-between items-center mt-6">
									<div class="flex flex-row"> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> </div>
									<div class="flex flex-row"> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> </div>
									<div class="flex flex-row"> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> </div>
									<div class="flex flex-row"> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> <span class="text-white text-lg mr-1 font-bold">4</span> <span class="text-white text-lg mr-1 font-bold">2</span> </div>
								</div>
								<div class="mt-6 flex justify-between text-white">
									<div class="flex flex-col"> <span class="font-bold text-gray-300 text-sm">Expires</span> <span class="font-bold">01/25</span> </div>
									<div class="flex flex-col"> <span class="font-bold text-gray-300 text-sm">CVC</span> <span class="font-bold">123</span> </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</tw-modal>
</template>

<script lang="ts">
import {Options, Prop, Inject, Vue} from 'vue-property-decorator';

@Options({
	name: 'PremiumFeature',
	components: {
	},
})
export default class PremiumFeature extends Vue {
	@Inject() store;
	@Prop() visible;

	close() {
		this.$emit('close');
	}
	get icon() {
		return this.store.state.theme === 'light' ? 'coverlabs-icon-light' : 'coverlabs-icon-dark';
	}
	get logo() {
		return this.store.state.theme === 'light' ? 'coverlabs-logo-light' : 'coverlabs-logo-dark';
	}
}
</script>
