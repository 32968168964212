<template>
	<transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-300" leave-from-class="opacity-100" leave-to-class="opacity-0">
		<div v-if="visible" class="max-w-sm w-full bg-white dark:bg-gray-700 shadow-lg rounded-lg pointer-events-auto overflow-hidden">
			<div class="p-4">
				<div class="flex items-start">
					<div class="flex-shrink-0">
						<tw-icon class="h-6 w-6" :class="style.color" :icon="style.icon" size="lg"/>
					</div>
					<div class="ml-3 w-0 flex-1 pt-0.5">
						<p class="text-sm font-medium text-primary">{{this.params.title}}</p>
						<p class="mt-1 text-sm text-gray-500 dark:text-gray-300">{{this.params.message}}</p>
					</div>
					<div class="ml-4 flex-shrink-0 flex">
						<button @click="this.reset()" class="bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none">
							<span class="sr-only">Close</span>
							<tw-icon icon="times" size="lg"/>
						</button>
					</div>
				</div>
			</div>
			<div class="relative pt-1">
				<div class="overflow-hidden h-2 text-xs flex" :class="style.bar">
					<div id="progressBar" class="w-full shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center" :class="style.progress"/>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import { Vue, Options } from 'vue-property-decorator';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import $ from 'jquery';

	@Options({
		name: 'TwNotify',
		components: {
		},
	})
	export default class TwNotify extends Vue {
		readonly eventBus: EventBus = EventBus.getInstance();

		params: any = {};
		visible = false;
		progress: any = 0;

		notifyTimer = (() => {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let self = this;
			let timer;
			function start(duration, $element, width) {
				let timeLeft = duration;
				timer = setInterval(countdown, 100);

				function countdown() {
					if(timeLeft >= 0) {
						let progressBarWidth = timeLeft * (width / duration);
						$element.animate({ width: progressBarWidth }, 100, "linear");
						--timeLeft;
					} else {
						clearInterval(timer);
						self.visible = false;
					}
				}
			}

			function stop() {
				clearInterval(timer);
				self.visible = false;
			}

			return {
				start:start,
				stop:stop
			};
		})();

		async beforeMount() {
			this.eventBus.on(EventName.ShowNotify, await this.showNotification);
		}

		async showNotification(e) {
			await this.reset();
			this.params = e;
			this.visible = true;
			setTimeout(() => {
				let el = $('#progressBar');
				this.notifyTimer.start(this.params.duration * 10, el, el.width());
			}, 100);
		}

		async reset () {
			this.notifyTimer.stop();
			this.visible = false;
			this.progress = 0;
		}

		get style () {
			if (this.params.variant === 'success') {
				return {
					icon: 'check-circle',
					color: 'text-green-400',
					bar: 'bg-green-200',
					progress: 'bg-green-500',
				};
			} else if (this.params.variant === 'danger') {
				return {
					icon: 'times-circle',
					color: 'text-red-400',
					bar: 'bg-red-200',
					progress: 'bg-red-500',
				};
			} else {
				return {
					icon: 'exclamation-circle',
					color: 'text-primary',
					bar: 'bg-blue-200',
					progress: 'bg-blue-500',
				};
			}
		}
	}
</script>
