
import {Options, Inject, mixins} from 'vue-property-decorator';
import { Services } from "@/mixins/services";
import $ from 'jquery';

@Options({
	name: 'SignIn',
	components: {
	},
})
export default class SignIn extends mixins(Services) {
	@Inject() readonly store!: any;
	@Inject() readonly firebase!: any;

	email = '';
	password = '';
	registerEmail = '';
	registerPassword = '';
	registerConfirmPassword = '';

	loading = false;
	alternativeView = 'register';

	resetVars () {
		this.email = '';
		this.password = '';
		this.registerEmail = '';
		this.registerPassword = '';
		this.registerConfirmPassword = '';
	}
	beforeMount() {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
		});
	}

	async register() {
		if (this.loading) return;

		let valid: boolean;
		let msg = '';
		if (this.registerPassword.trim().length === 0) {
			msg = 'Password required';
			valid = false;
		} else if (this.registerConfirmPassword.trim().length === 0) {
			msg = 'Confirm password required';
			valid = false;
		} else if (this.registerPassword.trim() !== this.registerConfirmPassword.trim()) {
			msg = 'Passwords must match';
			valid = false;
		} else {
			valid = true;
		}

		if (!valid) {
			this.notify('danger', 'Input Validation', msg);
		} else {
			this.loading = true;
			this.firebase.auth().createUserWithEmailAndPassword(this.registerEmail, this.registerPassword.trim()).then(async () => {
				await this.firebase.auth().currentUser.sendEmailVerification().then(() => {
					location.reload();
				}).catch(() => {
					this.loading = false;
					this.notify('danger', 'Authentication', 'Too many requests, try again later');
				});
			})
			.catch((err) => {
				this.loading = false;
				if (err.code === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else if (err.code === "auth/email-already-in-use") {
					this.notify('danger', 'Authentication', 'Email Already in Use');
				} else {
					this.notify('danger', 'Authentication', err.code);
				}
			});
		}
	}
	forgotPassword() {
		this.alternativeView = 'forgotPassword';
		this.toggle();
	}
	signIn() {
		this.firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
			location.reload();
		}).catch((err) => {
			if (err.code === "auth/invalid-email") {
				this.notify('danger', 'Authentication', 'Invalid Email');
			} else if (err.code === "auth/wrong-password" || err.code === "auth/user-not-found") {
				this.notify('danger', 'Authentication', 'Invalid Credentials');
			} else {
				this.notify('danger', 'Authentication', err.code);
			}
		});
	}
	sendPasswordResetEmail () {
		if (this.registerEmail.trim().length > 0) {
			this.firebase.auth().sendPasswordResetEmail(this.registerEmail).then(() => {
				this.notify('success', 'Authentication', 'If that account exists, an email will have been sent');
				this.goBack();
			}).catch((err) => {
				if (err.code === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else {
					this.notify('success', 'Authentication', 'If that account exists, an email will have been sent');
					this.goBack();
				}
			});
		} else {
			this.notify('danger', 'Input Validation', 'Email Address Required');
		}
	}
	goBack () {
		this.toggle();
		setTimeout(() => {
			this.alternativeView = 'register';
		}, 600);
	}

	toggle () {
		$('.loginForm').toggleClass('s--signup');
		setTimeout(() => {
			this.resetVars();
		}, 600);
	}
}
