<template>
	<div class="loginForm rounded-tl-2xl rounded-br-2xl fixed z-50 overflow-hidden bg-white shadow-lg left-1/2 top-1/2">
		<div class="form sign-in p-7 h-full">
			<div class="signInForm flex flex-col h-full">
				<p class="text-center pb-2">Welcome Back!</p>
				<div class="flex-1 pt-6">
					<tw-input-group label="Email"
					                :required="true"
					                type="email"
					                :value="email"
					                @updateValue="email = $event"/>
					<tw-input-group label="Password"
					                type="password"
					                :required="true"
					                :value="password"
					                @updateValue="password = $event"
					                @enterEvent="signIn()"/>
				</div>
				<p class="text-center cursor-pointer mb-12 text-xs" @click="forgotPassword()">Forgot password?</p>
				<tw-button class="rounded-full" text="Sign In" @click="signIn()"/>
			</div>
		</div>
		<div class="sub-form overflow-hidden absolute bg-white h-full left-0 top-0">
			<div class="img w-full flex flex-col h-full py-7 overflow-hidden z-10 absolute inset-0">
				<div class="flex-1">
					<div class="img__text m--up z-10 absolute left-0 w-full text-center text-white px-3">
						<p class="text-md md:text-lg pb-3 sm:pb-7">New here?</p>
						<p class="text-xs sm:text-sm">Sign up to view and manage all your covers in one place!</p>
					</div>
					<div class="img__text m--in z-10 absolute left-0 w-full text-center text-white px-3">
						<p class="text-md md:text-lg pb-3 sm:pb-7">One of us?</p>
						<p class="text-xs sm:text-sm">If you already have an account, just sign in. Get creating!</p>
					</div>
				</div>
				<div class="img__btn bg-transparent w-full uppercase text-white h-9 text-sm cursor-pointer relative z-10 overflow-hidden" @click="goBack()">
					<span class="m--up absolute left-0 top-0 text-center leading-9 w-full">Sign Up</span>
					<span class="m--in absolute left-0 top-0 text-center leading-9 w-full">Sign In</span>
				</div>
			</div>
			<div class="form sign-up p-7 h-full">
				<div class="flex flex-col h-full" v-show="alternativeView === 'register'">
					<p class="text-center pb-2">Create Account</p>

					<div class="flex-1 pt-6">
						<tw-input-group label="Email"
						                :required="true"
						                :value="registerEmail"
						                @updateValue="registerEmail = $event"/>
						<tw-input-group label="Password"
						                type="password"
						                :required="true"
						                :value="registerPassword"
						                @updateValue="registerPassword = $event"/>
						<tw-input-group label="Confirm Password"
						                type="password"
						                :required="true"
						                :value="registerConfirmPassword"
						                @updateValue="registerConfirmPassword = $event"
						                @enterEvent="register()"/>
					</div>
					<tw-button class="rounded-full" text="Sign Up" @click="register()" :loading="loading"/>
				</div>

				<div class="flex flex-col h-full" v-show="alternativeView === 'forgotPassword'">
					<p class="text-center pb-2">Forgot Password</p>

					<div class="flex-1 pt-6">
						<p class="center text-sm pb-3">Don't worry! Simply enter the email you used and we will send you a reset link. It's that easy.</p>
						<tw-input-group label="Email"
						                :value="registerEmail"
						                @updateValue="registerEmail = $event"
										@enterEvent="sendPasswordResetEmail()"/>
					</div>
					<tw-button class="rounded-full" text="Send Email" @click="sendPasswordResetEmail()"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {Options, Inject, mixins} from 'vue-property-decorator';
import { Services } from "@/mixins/services";
import $ from 'jquery';

@Options({
	name: 'SignIn',
	components: {
	},
})
export default class SignIn extends mixins(Services) {
	@Inject() readonly store!: any;
	@Inject() readonly firebase!: any;

	email = '';
	password = '';
	registerEmail = '';
	registerPassword = '';
	registerConfirmPassword = '';

	loading = false;
	alternativeView = 'register';

	resetVars () {
		this.email = '';
		this.password = '';
		this.registerEmail = '';
		this.registerPassword = '';
		this.registerConfirmPassword = '';
	}
	beforeMount() {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
		});
	}

	async register() {
		if (this.loading) return;

		let valid: boolean;
		let msg = '';
		if (this.registerPassword.trim().length === 0) {
			msg = 'Password required';
			valid = false;
		} else if (this.registerConfirmPassword.trim().length === 0) {
			msg = 'Confirm password required';
			valid = false;
		} else if (this.registerPassword.trim() !== this.registerConfirmPassword.trim()) {
			msg = 'Passwords must match';
			valid = false;
		} else {
			valid = true;
		}

		if (!valid) {
			this.notify('danger', 'Input Validation', msg);
		} else {
			this.loading = true;
			this.firebase.auth().createUserWithEmailAndPassword(this.registerEmail, this.registerPassword.trim()).then(async () => {
				await this.firebase.auth().currentUser.sendEmailVerification().then(() => {
					location.reload();
				}).catch(() => {
					this.loading = false;
					this.notify('danger', 'Authentication', 'Too many requests, try again later');
				});
			})
			.catch((err) => {
				this.loading = false;
				if (err.code === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else if (err.code === "auth/email-already-in-use") {
					this.notify('danger', 'Authentication', 'Email Already in Use');
				} else {
					this.notify('danger', 'Authentication', err.code);
				}
			});
		}
	}
	forgotPassword() {
		this.alternativeView = 'forgotPassword';
		this.toggle();
	}
	signIn() {
		this.firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {
			location.reload();
		}).catch((err) => {
			if (err.code === "auth/invalid-email") {
				this.notify('danger', 'Authentication', 'Invalid Email');
			} else if (err.code === "auth/wrong-password" || err.code === "auth/user-not-found") {
				this.notify('danger', 'Authentication', 'Invalid Credentials');
			} else {
				this.notify('danger', 'Authentication', err.code);
			}
		});
	}
	sendPasswordResetEmail () {
		if (this.registerEmail.trim().length > 0) {
			this.firebase.auth().sendPasswordResetEmail(this.registerEmail).then(() => {
				this.notify('success', 'Authentication', 'If that account exists, an email will have been sent');
				this.goBack();
			}).catch((err) => {
				if (err.code === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else {
					this.notify('success', 'Authentication', 'If that account exists, an email will have been sent');
					this.goBack();
				}
			});
		} else {
			this.notify('danger', 'Input Validation', 'Email Address Required');
		}
	}
	goBack () {
		this.toggle();
		setTimeout(() => {
			this.alternativeView = 'register';
		}, 600);
	}

	toggle () {
		$('.loginForm').toggleClass('s--signup');
		setTimeout(() => {
			this.resetVars();
		}, 600);
	}
}
</script>

<style scoped lang="scss">
	.form,
	.sub-form,
	.img__text,
	.img__btn span,
	.img:before {
		transition: transform .8s ease-in-out;
	}

	.loginForm.s--signup .img__text.m--in,
	.loginForm.s--signup .img__btn span.m--in {
		transform: translateY(0);
	}
	.loginForm.s--signup .img__btn span.m--up {
		transform: translateY(72px);
	}
	.img__btn span.m--in {
		transform: translateY(-72px);
	}
	.img {
		&:after,
		&:before {
			right: 0;
			bottom: 0;
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
		}
		&:after {
			background: rgba(0, 0, 0, 0.6);
		}
		&:before {
			background-size: cover;
		}
	}

	.loginForm.s--signup .sign-up {
		transform: translate3d(0, 0, 0);
	}

	@media only screen  and (min-width: 641px) {
		$formHeight: 380px;
		$formWidth: 550px;
		$activeFormWidth: 325px;
		$secondFormWidth: 225px;

		.loginForm {
			margin-left: -275px;
			margin-top: -190px;
			width: $formWidth;
			height: $formHeight;
		}
		.form {
			width: $activeFormWidth;
		}
		.sub-form {
			left: $activeFormWidth;
			width: $formWidth;
			padding-left: $secondFormWidth;
		}
		.img {
			width: $secondFormWidth;
			&:before {
				width: $formWidth;
				background-image: url("../../assets/img/loginWide.jpg");
			}
			.img__text.m--in {
				transform: translateX(-$formWidth);
			}
		}
		.sign-up {
			transform: translate3d(-$formWidth, 0, 0);
		}
		.loginForm.s--signup .img__text.m--up {
			transform: translateX($formWidth);
		}
		.loginForm.s--signup .img__text.m--in {
			transform: translateX(0);
		}
		.loginForm.s--signup .sub-form {
			transform: translate3d(-$activeFormWidth, 0, 0);
		}
		.loginForm.s--signup .sign-in,
		.loginForm.s--signup .img:before {
			transform: translate3d($activeFormWidth, 0, 0);
		}
	}
	@media only screen  and (max-width: 640px) {
		$formHeight: 530px;
		$formWidth: 320px;
		$activeFormHeight: 350px;
		$secondFormHeight: 180px;

		.loginForm {
			margin-left: -160px;
			margin-top: -265px;
			width: $formWidth;
			height: $formHeight;
		}
		.form {
			width: $formWidth;
			height: $activeFormHeight;
		}
		.sub-form {
			top: $activeFormHeight;
			width: $formWidth;
			padding-top: $secondFormHeight;
		}
		.img {
			height: $secondFormHeight;
			&:before {
				height: $formHeight;
				background-image: url("../../assets/img/loginTall.jpg");
			}
			.img__text.m--in {
				transform: translateY(-$formHeight);
			}
		}
		.sign-up {
			transform: translate3d(0, -$formHeight, 0);
		}
		.loginForm.s--signup .img__text.m--up {
			transform: translateY($formHeight);
		}
		.loginForm.s--signup .sub-form {
			transform: translate3d(0, -$activeFormHeight, 0);
		}
		.loginForm.s--signup .sign-in,
		.loginForm.s--signup .img:before {
			transform: translate3d(0, $activeFormHeight, 0);
		}
	}
</style>
