<template>
	<div class="flex flex-col h-full w-full relative">
		<canvas class="profile c" width="250" height="250"  style="display: none; width: 250px; height: 250px;" ></canvas>

		<div class="pb-4 w-full absolute z-10 bg-gradient-to-b from-gray-50 via-gray-50 dark:from-gray-950 dark:via-gray-950 to-transparent h-12">
			<span class="text-xl my-auto text-gray-500 dark:text-gray-300 pl-10 md:pl-0">Account</span>
		</div>
		<div class="flex-1 overflow-hidden">
			<div class="sm:grid sm:grid-cols-12 gap-x-5 h-full pt-10">
				<aside v-if="breakpoint !== 'xs'"
				       class="py-0 col-span-12 sm:col-span-4 lg:col-span-3 xl:col-span-2 xl:col-start-3">
					<nav class="space-y-1">
						<div v-for="item in navigation" :key="item.name"
						     :class="[item.current ? 'border-primary bg-white dark:bg-gray-900 text-brand-light-to dark:text-brand-dark-to' : 'text-gray-600 hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-50 border-transparent', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium border  cursor-pointer']"
						     @click="selectOption(item)">
							<tw-icon weight="fas" :icon="item.icon"
							         :class="[item.current ? 'text-brand-light-to dark:text-brand-dark-to group-hover:text-brand-light-via dark:group-hover:text-brand-dark-via' : 'text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-200', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']"
							         aria-hidden="true"/>
							<span class="truncate">{{ item.name }}</span>
						</div>
					</nav>
				</aside>
				<aside v-else class="pb-2">
					<tw-select-menu :value="this.navigation.find(el => el.current)"
					                :options="navigation"
					                label="Navigation"
					                variant="secondary"
					                modifier="check"
					                @updateValue="updateSelectNavigation($event)"/>
				</aside>

				<div class="pt-0 pb-6 col-span-12 sm:col-span-8 lg:col-start-4 lg:col-span-9 xl:col-start-5 xl:col-span-5 xl:col-end-11 overflow-auto">
					<div class="shadow rounded-md sm:overflow-hidden border border-primary bg-white dark:bg-gray-900">
						<div v-for="item in navigation" :key="item.name">
							<div v-if="item.current">
								<div class="py-6 px-4 sm:p-6">
									<h3 v-if="breakpoint !== 'xs'" class="text-lg leading-6 font-medium text-primary pb-4">{{item.name}}</h3>
									<div v-if="item.name === 'General'">
										<div class="grid grid-cols-2 gap-x-5 pb-2">
											<tw-input-group label="Username"
											                class="pb-2"
											                :value="firebaseCustomer.username"
															@updateValue="firebaseCustomer.username = $event"/>
										</div>
										<div class="grid grid-cols-2 gap-x-5 pb-2">
											<tw-input-group class="col-span-2 sm:col-span-1"
											                label="First Name"
											                :value="firebaseCustomer.firstName"
											                @updateValue="firebaseCustomer.firstName = $event"/>
											<tw-input-group class="col-span-2 sm:col-span-1"
											                label="Last Name"
											                :value="firebaseCustomer.lastName"
											                @updateValue="firebaseCustomer.lastName = $event"/>
										</div>
										<div class="grid grid-cols-3 sm:grid-cols-2 gap-x-5 pb-3">
											<tw-input-group class="col-span-2 sm:col-span-1"
											                label="Your Email"
											                :disabled="true"
											                :value="firebaseCustomer.email"/>
											<div class="col-span-1">
												<p class="mt-6 leading-9 h-8 text-brand-light-to dark:text-brand-dark-from text-sm cursor-pointer truncate" @click="changeEmailVisible = true">Change email</p>
											</div>
										</div>

										<div class="pb-3">
											<label class="block text-xs text-secondary">Photo</label>
											<div class="mt-1 flex items-center">
												<div class="flex flex-col overflow-hidden mr-5">
													<span class="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 border-2 border-primary">
														<img v-if="firebaseCustomerOrigin.photoUrl" :src="firebaseCustomerOrigin.photoUrl" class="rounded-full" alt="profile"/>
														<tw-icon v-else weight="fas" icon="user" class="text-white text-2xl"/>
													</span>
												</div>
												<div class="row">
													<label for="file-uploadBox" class="cursor-pointer inline-flex justify-center rounded-md border px-4 py-1 font-medium w-auto text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-gray-700 dark:text-gray-50 focus:ring-gray-300 dark:focus:ring-gray-500 bg-gray-0 dark:bg-gray-600 hover-primary border-gray-200 dark:border-gray-800 ring-offset-white dark:ring-offset-gray-900">Change</label>
													<input id="file-uploadBox" type="file" @change="updateProfileImage"/>
												</div>
											</div>
										</div>

										<div>
											<label class="block text-xs text-secondary">Theme</label>
											<div class="pt-1 d-flex flex-col">
												<div class="inline-block relative w-20 mr-3 cursor-pointer" @click="setTheme('light')">
													<div class="h-8 w-8 border-2 rounded-full bg-gray-0" :class="theme === 'light' ? 'border-brand-alternate-to' : 'border-gray-800'"/>
													<div class="h-8 w-8 border-2 rounded-full bg-gray-200 absolute right-6 top-0" :class="theme === 'light' ? 'border-brand-alternate-to' : 'border-gray-800'"/>
													<div class="h-8 w-8 border-2 rounded-full bg-brand-light-to absolute right-0 top-0" :class="theme === 'light' ? 'border-brand-alternate-to' : 'border-gray-800'"/>
												</div>
												<div class="inline-block relative w-20 cursor-pointer" @click="setTheme('dark')">
													<div class="h-8 w-8 border-2 rounded-full bg-gray-900" :class="theme === 'dark' ? 'border-brand-alternate-to' : 'border-gray-300'"/>
													<div class="h-8 w-8 border-2 rounded-full bg-gray-600 absolute right-6 top-0" :class="theme === 'dark' ? 'border-brand-alternate-to' : 'border-gray-300'"/>
													<div class="h-8 w-8 border-2 rounded-full bg-brand-dark-to absolute right-0 top-0" :class="theme === 'dark' ? 'border-brand-alternate-to' : 'border-gray-300'"/>
												</div>
											</div>
										</div>
									</div>

									<div v-if="item.name === 'Password'">
										<div>
											<div class="grid grid-cols-2 gap-x-5">
												<tw-input-group class="col-span-2 sm:col-span-1"
												                label="Current"
												                type="password"
												                :value="passwordCurrent"
												                @updateValue="passwordCurrent = $event"/>
											</div>
											<div class="grid grid-cols-2 gap-x-5 pb-1">
												<tw-input-group class="col-span-2 sm:col-span-1"
												                label="New"
												                type="password"
												                :value="passwordNew"
												                @updateValue="passwordNew = $event"/>
												<tw-input-group class="col-span-2 sm:col-span-1"
												                label="Confirm New"
												                type="password"
												                :value="passwordConfirm"
												                @updateValue="passwordConfirm = $event"/>
											</div>
										</div>
									</div>

									<div v-if="item.name === 'Plan & Billing'">
										<div class="grid grid-cols-1 gap-x-5 lg:grid-cols-2">
											<div class="col-span-1">
												<template v-if="subscription">
													<div class="flex flex-col">
														<span class="h-7 leading-7 inline-block text-center md:text-left font-bold bg-gradient-to-r from-brand-light-from to-brand-light-to dark:from-brand-dark-from dark:to-brand-dark-to text-transparent! bg-clip-text">Coverlabs Premium</span>
														<div v-if="subscription.cancelAtPeriodEnd" class="flex flex-col">
															<span class="text-xs inline-block text-primary text-center lg:text-left">Membership Canceled</span>
															<span class="text-xs inline-block text-primary text-center lg:text-left">Premium features available until: {{subscription.cancelAt}}</span>
														</div>
														<div v-else class="flex flex-col">
															<span class="text-xs text-primary text-center lg:text-left">Membership Active</span>
															<span class="text-xs text-primary text-center lg:text-left">Set to auto-renew on: {{subscription.currentPeriodEnd}}</span>
														</div>
													</div>
												</template>
												<template v-else>
													<div class="flex flex-col">
														<span class="h-7 leading-7 inline-block text-center md:text-left font-bold bg-gradient-to-r from-brand-alternate-from to-brand-alternate-to text-transparent! bg-clip-text">Coverlabs Free</span>
													</div>
												</template>
											</div>
											<div class="col-span-1 pt-2 mt-2 lg:pt-0 lg:mt-0 border-t lg:border-0 border-secondary">
												<template v-if="subscription">
													<div class="flex justify-center lg:justify-end">
														<div class="flex flex-col w-48">
															<tw-button text="Manage Subscription" font="text-gray-300 dark:text-gray-500" border="border-gray-300 dark:border-gray-500" color="" :loading="loadingStripe" @click="managePayment()"></tw-button>
															<div class="flex justify-end pt-2">
																<span class="text-xs pr-1 leading-5 text-primary">Powered By</span>
																<img class="h-5" src="./../assets/img/stripe.svg" alt="">
															</div>
														</div>
													</div>
												</template>
												<template v-else>
													<div class="flex justify-center lg:justify-end">
														<div class="flex flex-col">
															<router-link :to="{ path: '/premium' }">
																<tw-button text="Buy Premium" defaultStyle="cancel" :showImage="true"></tw-button>
															</router-link>
														</div>
													</div>
												</template>
											</div>
										</div>
										<div class="flex justify-end mt-10">
											<p class="text-sm font-medium text-red-600 cursor-pointer" @click="deactivateAccountVisible = true">Delete Account</p>
										</div>
									</div>

								</div>
								<div v-if="item.name !== 'Plan & Billing'" class="px-4 py-3 border-t border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-700 text-right sm:px-6">
									<tw-button text="Submit" :loading="savingChanges" @click="saveChanges()"/>
								</div>

							</div>
						</div>
					</div>
				</div>

				<change-email :visible="changeEmailVisible"
				              @close="changeEmailVisible = false"/>
				<deactivate-account :visible="deactivateAccountVisible"
				                    @close="deactivateAccountVisible = false"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { Options, Inject, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import changeEmail from '@/components/modals/change-email.vue';
	import deactivateAccount from '@/components/modals/deactivate-account.vue';
	import moment from 'moment';

	@Options({
		name: 'Account',
		components: {
			changeEmail,
			deactivateAccount,
		},
	})
	export default class Account extends mixins(Services) {
		@Inject() readonly firebase!: any;
		@Inject() readonly firebaseAuthentication!: any;
		@Inject() store;

		firebaseCustomer: any = null;
		imgSquareBase = 250;
		profileCanvas: any = null;

		passwordCurrent = '';
		passwordNew = '';
		passwordConfirm = '';

		selectedNavigationValue: any = null;

		savingChanges = false;
		loadingStripe = false;
		deactivateAccountVisible = false;
		changeEmailVisible = false;

		navigation = [
			{id: 0, name: 'General', icon: 'user', current: true},
			{id: 1, name: 'Password', icon: 'key', current: false},
			{id: 2, name: 'Plan & Billing', icon: 'credit-card-front', current: false},
		];

		async created() {
			this.selectedNavigationValue = this.navigation.find(el => el.current);
			this.firebaseCustomer = JSON.parse(JSON.stringify(this.store.state.firebaseCustomer));
		}
		mounted() {
			this.profileCanvas = this.$el.children[0];
			let canvas = this.profileCanvas;
			let ctx = canvas.getContext('2d');
			ctx.canvas.width = this.imgSquareBase;
			ctx.canvas.height = this.imgSquareBase;
		}
		async saveChanges() {
			let selected = this.navigation.find(el => el.current)?.name;
			if (selected === 'General') {
				await this.saveGeneral();
			} else if (selected === 'Password') {
				await this.savePassword();
			}
		}

		async verifyUniqueUsername() {
			return await this.firebase.firestore().collection("customers").where("username", "==", this.firebaseCustomer.username).get().then((snap) => {
				return snap.size === 0;
			});
		}
		async saveGeneral() {
			if (this.firebaseCustomerOrigin.username !== this.firebaseCustomer.username ||
				this.firebaseCustomerOrigin.firstName !== this.firebaseCustomer.firstName ||
				this.firebaseCustomerOrigin.lastName !== this.firebaseCustomer.lastName ||
				this.firebaseCustomerOrigin.theme !== this.theme) {

				this.savingChanges = true;
				if (this.firebaseCustomerOrigin.username !== this.firebaseCustomer.username && !this.verifyUniqueUsername) {

					this.notify('success', 'Validation', 'Username taken');
					this.savingChanges = false;
					return;
				}

				await this.firebase.functions().httpsCallable('updateCustomerName')({
					username: this.firebaseCustomer.username,
					firstName: this.firebaseCustomer.firstName,
					lastName: this.firebaseCustomer.lastName,
					theme: this.theme
				}).then(async (result: any) => {
					if('success' in result.data) {
						this.store.commit('setFirebaseCustomer', JSON.parse(JSON.stringify(this.firebaseCustomer)));
						this.notify('success', 'Account Updates', 'General information updated');
					}
				}).catch((error: any) => console.log(error));
			} else {
				this.notify('default', 'Account Updates', 'No changes to save');
			}

			this.savingChanges = false;
		}

		async savePassword() {
			this.savingChanges = true;
			if (this.validatePasswordSave()) {
				await this.firebaseAuthentication.setPassword(this.firebaseCustomer.email, this.passwordCurrent, this.passwordNew.trim()).then(async (res: any) => {
					if (res !== 'success') {
						this.notify('danger', 'Authentication', res);
					} else {
						this.passwordCurrent = '';
						this.passwordNew = '';
						this.passwordConfirm = '';
						this.notify('default', 'Account Updates', 'Password updated');
					}
				});
			}
			this.savingChanges = false;
		}

		async managePayment() {
			this.loadingStripe = true;
			const functionRef = this.firebase
				.app()
				.functions('us-central1')
				.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
			const { data } = await functionRef({ returnUrl: window.location.origin });
			window.location.assign(data.url);
		}
		updateProfileImage (e) {
			let f = e.target.files[0];
			if (f.type.match(/image\/(jpg|jpeg|png)/i)) {
				let image = new Image();
				image.onload = this.imageLoad;
				image.src = URL.createObjectURL(f);
			}
		}

		imageLoad (e) {
			let multiplier = 0;
			let imageHeight = 0;
			let imageWidth = 0;
			let xOffset = 0;
			let yOffset = 0;
			if (e.target.naturalWidth > e.target.naturalHeight) {
				multiplier = this.imgSquareBase / e.target.naturalHeight;
				imageHeight = this.imgSquareBase;
				imageWidth = multiplier * e.target.naturalWidth;
				xOffset = this.imgSquareBase - imageWidth;
			} else {
				multiplier = this.imgSquareBase / e.target.naturalWidth;
				imageWidth = this.imgSquareBase;
				imageHeight = multiplier * e.target.naturalHeight;
				yOffset = this.imgSquareBase - imageHeight;
			}
			let canvas = this.profileCanvas;
			let ctx = canvas.getContext('2d');
			ctx.drawImage(e.target, xOffset, yOffset, imageWidth, imageHeight);
			let profileUrl = canvas.toDataURL('image/jpeg', 85);
			let profileRef = this.firebase.storage().ref(`profiles/${this.firebaseCustomerOrigin.uid}.jpg`);
			profileRef.putString(profileUrl, 'data_url').then((fileSnapshot) => {
				fileSnapshot.ref.getDownloadURL().then((url) => {
					this.firebase.firestore().collection('customers/').doc(this.firebaseCustomerOrigin.uid).update({ photoUrl: url });
					this.firebaseCustomerOrigin.profileUrl = url;
				});
			});
		}

		setTheme(e) {
			if (e === 'dark') {
				document.documentElement.classList.add('dark');
			} else {
				document.documentElement.classList.remove('dark');
			}
			this.store.commit("setTheme", e);
		}

		validatePasswordSave() {
			let valid: boolean;
			let msg = '';

			if (this.passwordCurrent.trim().length > 0 || this.passwordNew.trim().length > 0 || this.passwordConfirm.trim().length > 0) {
				if (this.passwordCurrent.trim().length === 0) {
					msg = 'Current password required';
					valid = false;
				} else if (this.passwordNew.trim().length === 0) {
					msg = 'New password required';
					valid = false;
				} else if (this.passwordConfirm.trim().length === 0) {
					msg = 'Confirm new password required';
					valid = false;
				} else if (this.passwordNew.trim() !== this.passwordConfirm.trim()) {
					msg = 'New passwords must match';
					valid = false;
				} else {
					valid = true;
				}
			} else {
				msg = 'Current password required';
				valid = false;
			}

			if (!valid) {
				this.notify('danger', 'Input Validation', msg);
			}

			return valid;
		}

		updateSelectNavigation(e) {
			this.selectOption(this.navigation.find(el => el.name === e.name));
		}
		selectOption(item) {
			this.navigation.forEach(el => el.current = false);
			item.current = true;
		}

		get subscription() {
			if (this.firebaseCustomerOrigin.subscription) {
				let currentPeriodEnd: any = new Date(0);
				let cancelAt: any = new Date(0);
				const unitAmount = this.firebaseCustomerOrigin.subscription.items[0].price.unit_amount.toString();
				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				});

				currentPeriodEnd = moment(currentPeriodEnd.setUTCSeconds(this.firebaseCustomerOrigin.subscription.current_period_end._seconds)).format('MM/DD/YYYY');
				if (this.firebaseCustomerOrigin.subscription.cancel_at_period_end) {
					cancelAt = moment(cancelAt.setUTCSeconds(this.firebaseCustomerOrigin.subscription.cancel_at._seconds)).format('MM/DD/YYYY');
				}

				return {
					status: this.firebaseCustomerOrigin.subscription.status,
					name: this.firebaseCustomerOrigin.subscription.items[0].price.product.name,
					amount: formatter.format(unitAmount.substring(0, unitAmount.length - 2)),
					currentPeriodEnd: currentPeriodEnd,
					cancelAt: cancelAt,
					cancelAtPeriodEnd: this.firebaseCustomerOrigin.subscription.cancel_at_period_end,

				};
			} else {
				return null;
			}
		}
		get firebaseCustomerOrigin() {
			return this.store.state.firebaseCustomer;
		}
		get breakpoint() {
			return this.store.state.breakpoint;
		}
		get theme() {
			return this.store.state.theme;
		}
	}
</script>

<style scoped lang="scss">

input[type="file"] {
	display: none;
}
.custom-file-upload {
	//box-sizing: border-box;
	//background: transparent;
	//cursor: pointer;
	//margin: 0;
	//text-align: center;
	//font-size: 14px;
	//padding: 0;
	//line-height: 30px;
}
</style>
