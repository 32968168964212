
import { Options, Vue, Inject, Watch, Prop } from 'vue-property-decorator';

@Options({
	name: "DesignerControls",
	components: {
	},
})
export default class DesignerControls extends Vue {
	@Inject() readonly store!: any;
	@Inject() readonly firebase!: any;

	@Prop() controls: any;
	@Prop() activeControl: any;
	@Prop() isDropdown: any;

	additionalControls: any = [
		{ name: 'Presets' },
		{ name: 'Save/Download' }
	];

	updateSelectNavigation(e) {
		this.selectOption(this.controls.find(el => el.name === e.name));
	}
	selectOption(item) {
		this.$emit('setActive', item.index);
	}

	setActiveIndex(index) {
		this.$emit('setActive', index);
	}
	isActive(index) {
		return index === this.activeControl;
	}
	iconLookup(title: any) {
		if (title === 'Colors') {
			return 'swatchbook';
		} else if (title === 'Extras') {
			return 'boxes-alt';
		} else if (title === 'Image') {
			return 'image';
		} else if (title === 'Overlay') {
			return 'layer-group';
		} else if (title === 'Text') {
			return 'quote-left';
		} else if (title === 'Presets') {
			return 'filter';
		} else if (title === 'Save/Download') {
			return 'save';
		} else {
			return 'question-circle';
		}
	}

	get theme() {
		return this.store.state.theme;
	}
}
