
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";

	@Options({
		name: 'ClIntegerTicker',
		components: {
		},
	})
	export default class ClIntegerTicker extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		integers = {
			one: 1,
			two: 9,
			three: 9,
		};

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.integers.one = Number.parseInt(this.value[0]);
			this.integers.two = Number.parseInt(this.value[1]);
			this.integers.three = Number.parseInt(this.value[2]);
		}

		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}

		increase (vars, val) {
			if (val < 9) {
				this.integers[vars] = val + 1;
			} else {
				this.integers[vars] = 0;
			}
		}

		decrease (vars, val) {
			if (val > 0) {
				this.integers[vars] = val - 1;
			} else {
				this.integers[vars] = 9;
			}
		}

		get activeValue() {
			return this.integers.one.toString() + this.integers.two.toString() + this.integers.three.toString() + '0';
		}
	}
