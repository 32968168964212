<template>
	<designer ref="designer"
	          :name="name"
	          :imported="imported"
	          :static="static"
	          :default="this.default"
	          :controls="controls"
	          :drawer="drawer"
	          @imageLoaded="imageLoadedListener"/>
</template>

<script lang="ts">
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Palouse",
		components: {
			designer
		},
	})
	export default class Palouse extends mixins(Functions) {
		readonly eventBus: EventBus = EventBus.getInstance();
		$refs: any;

		name = 'Palouse';
		imported: any = {};
		static = {
			text_primarySize: 94,
			text_secondarySize: 34,
			width_ring: 22,
		};
		default = {
			text_primary: 'TITLE',
			text_secondary: 'SUBTEXT',
			text_fontFamily: 'Montserrat',
			colors_background: '#FF6226',
			colors_text: '#FFFFFF',
			extras_show_logoOnly: false,
			extras_show_gridMap: false,
			image_focus: 'primary',
			image_icon: null,
			images: {
				primary: new PosterDto(),
			},
		};

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_icon',
						comp: 'select-menu',
						options: {
							mod: 'FontAwesome',
							label: 'Provided Icons',
						}
					}
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Title',
						}
					},
					{
						variable: 'text_secondary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Sub-Title',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_text',
						comp: 'color-swatches',
						options: {
							label: 'Text',
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_logoOnly',
						comp: 'toggle',
						options: {
							name: 'divider',
							label: 'Show Logo Only',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		]


		@Watch('default.image_icon')
		@Watch('default.extras_show_logoOnly')
		watchObjectIcon() {
			if (this.default.image_icon) {
				this.eventBus.emit(EventName.ClearPoster, {type: 'primary'});
			}
		}

		created() {
			this.default.images.primary.initialY = 650;
			this.default.images.primary.initialW = 600;
			this.default.images.primary.initialH = 200;
			this.default.images.primary.maxW = 500;
			this.default.images.primary.maxH = 300;
		}

		drawer (ctx: any, variables: any, poster: any) {
			const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, poster.baseHeight, variables.colors_background, null, null);
			const luminance = this.color_hexToLuminance(variables.colors_background);
			const gradient = ctx.createRadialGradient(1500, -250, 0, 1500, -250, 2400);
			const ringRgb = luminance > 0.65 ? '0, 0, 0' : '255, 255, 255';
			let ringRgba = `rgba(${ringRgb}, .50)`;
			if (isSafari) {
				if (luminance !== 1 && luminance !== 0) {
					gradient.addColorStop(0.4, `rgba(${ringRgb}, .4)`);
					gradient.addColorStop(0.7, `rgba(${ringRgb}, 0)`);
				} else {
					gradient.addColorStop(0.4, `rgba(${ringRgb}, .2)`);
					ringRgba = `rgba(${ringRgb}, .25)`;
				}
				gradient.addColorStop(0.7, `rgba(${ringRgb}, 0)`);
			} else {
				ctx.globalCompositeOperation = 'soft-light';
				if (luminance !== 1 && luminance !== 0) {
					gradient.addColorStop(0.4, `rgba(${ringRgb}, 1)`);
					gradient.addColorStop(0.7, `rgba(${ringRgb}, 0)`);
				} else {
					ctx.globalCompositeOperation = 'source-over';
					gradient.addColorStop(0.4, `rgba(${ringRgb}, .2)`);
					ringRgba = `rgba(${ringRgb}, .25)`;
				}
				gradient.addColorStop(0.7, `rgba(${ringRgb}, 0)`);
			}
			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, poster.baseHeight, gradient, null, null);
			this.draw_circle(ctx, 500, 750, 415, 0, this.static.width_ring, ringRgba);
			this.draw_circle(ctx, 500, 750, 515, 0, this.static.width_ring, ringRgba);
			this.draw_circle(ctx, 500, 750, 615, 0, this.static.width_ring, ringRgba);
			this.draw_circle(ctx, 500, 750, 715, 0, this.static.width_ring, ringRgba);
			this.draw_circle(ctx, 500, 750, 815, 0, this.static.width_ring, ringRgba);

			ctx.globalCompositeOperation = 'source-over';

			if (poster.primaryFileSource) {
				variables.images.primary.initialY = variables.extras_show_logoOnly ? 750 : 650;
				this.draw_image(ctx, poster, 'primary', variables);
			} else if (variables.image_icon) {
				ctx.textAlign = 'center';
				ctx.fillStyle = "white";
				ctx.textBaseline = 'middle';
				ctx.font = '900 200px "Font Awesome 5 Pro"';
				this.buildFontAwesomeIcon(ctx, variables.image_icon, 500, variables.extras_show_logoOnly ? 750 : 660);
			}

			if (!poster.primaryFileSource && !variables.image_icon) {
				const offsetY = variables.extras_show_logoOnly ? 750 : 640;
				this.write_imageHelper(ctx, 500, offsetY, 30,'ripples');
			}

			if (!variables.extras_show_logoOnly) {
				this.write_textLine(ctx, variables.text_primary, poster.baseWidth / 2, 805, this.static.text_primarySize, variables.text_fontFamily, variables.colors_text, 'normal', 'normal', 'center', 'top');
				this.write_textLine(ctx, variables.text_secondary, poster.baseWidth / 2, 900, this.static.text_secondarySize, variables.text_fontFamily, variables.colors_text, 'normal', 2, 'center', 'top');
			}

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			this.default.image_icon = null;
		}

		buildFontAwesomeIcon (ctx, icon, x, y) {
			const unicodeChar = JSON.parse(`["\\u${icon}"]`)[0];
			ctx.fillText(unicodeChar, x, y);
		}
	}
</script>
