
	import {Vue, Options, Prop, Inject} from 'vue-property-decorator';

	@Options({
		name: 'TwModal',
		components: {
		},
	})
	export default class TwModal extends Vue {
		@Inject() store;
		@Prop() visible;
		@Prop({default: 'Submit'}) submitText;
		@Prop({default: null}) submitStyle;
		@Prop({default: true}) showCancelButton;

		close() {
			this.$emit('close');
		}
		submit() {
			this.$emit('submit');
		}
		get theme() {
			return this.store.state.theme;
		}
	}
