
	import { Vue,Options } from 'vue-class-component';

	@Options({
		name: 'Dashboard',
		components: {
		},
	})
	export default class Dashboard extends Vue {
		features = [
			{
				name: 'Rebuilt Designer',
				description:
					'Built from the ground up, new templates and options are built in to every option. To let you truly make your posters your own.',
				icon: 'drafting-compass',
				type: 'fas',
			},
			{
				name: 'Customizable Dashboard',
				description:
					'Still In Progress. With that said once available, you will be able to track downloads, presets, and various statistics',
				icon: 'chart-bar',
				type: 'fas',
			},
			{
				name: 'Premium (Coming Soon)',
				description:
					'We know subscriptions suck. However with a Premium model, we will be able offer saving posters and help us keep the lights on!',
				icon: 'star',
				type: 'fas',
			},
			{
				name: 'Community',
				description: 'If you have any feedback, issues, or just want to say Hi; please join us in the community discord!',
				icon: 'discord',
				type: 'fab',
			},
		]
	}
