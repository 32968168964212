
	import { Options, Provide, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import navigation from '@/components/navigation/navigation.vue';
	import designerFonts from '@/components/custom/designer.fonts.vue';
	import firebase from './firebase/config';
	import firebaseAuthentication from './firebase/authentication';
	import store from "@/store";
	import moment from 'moment';

	@Options({
		name: 'App',
		components: {
			navigation,
			designerFonts
		},
	})
	export default class App extends mixins(Services) {
		@Provide() store = store;
		@Provide() firebase = firebase;
		@Provide() firebaseAuthentication = firebaseAuthentication;

		fontsReady = false;

		async created() {
			this.setWindowWidth();
			this.setWindowHeight();
			window.addEventListener("resize", this.setWindowWidth);
			window.addEventListener("resize", this.setWindowHeight);
		}

		async mounted() {
			// TODO FIX MOMENT WARNINGS
			moment.suppressDeprecationWarnings = true;
			await this.checkInitialAuth();
		}

		async checkInitialAuth() {
			firebase.auth().onAuthStateChanged(async (user: any) => {
				if (!user || !user.emailVerified) return;

				const timeCheck = localStorage.getItem('timestamp');
				if (!timeCheck || moment(moment().format('MM/DD/YYYY')).startOf('month').isAfter(timeCheck)) {
					await firebase.functions().httpsCallable('getCustomer')({ providerData: user.providerData, displayName: user.displayName }).then(async (customerResult) => {
						if (!('error' in customerResult.data)) {
							console.log('NEW CUSTOMER DATABASE CALL');
							await firebase.functions().httpsCallable('getResources')().then(async (resourcesResult) => {
								store.commit('setFirebaseResources', resourcesResult.data);
							}).catch(error => console.log(error));

							await this.defineCustomClaimRole(customerResult.data);
							customerResult.data.trial = await this.defineTrialSettings(customerResult.data);
							store.commit('setFirebaseCustomer', customerResult.data);
						} else {
							this.notify('danger', customerResult.data.error, 'Authentication');
						}
					});
				} else {
					let _resources: any = localStorage.getItem('resources');
					let _customer: any = localStorage.getItem('customer');
					_resources = JSON.parse(_resources);
					_customer = JSON.parse(_customer);

					await this.defineCustomClaimRole(_customer);
					_customer.trial = await this.defineTrialSettings(_customer);
					store.commit('setFirebaseResources', _resources);
					store.commit('setFirebaseCustomer', _customer);
				}

				await this.setTheme();
				store.commit('setIsSignedIn', true);
			});

		}

		async defineCustomClaimRole(data: any) {
			await firebase.auth().currentUser?.getIdToken(true);
			const decodedToken = await firebase.auth().currentUser?.getIdTokenResult();
			const isPremium = decodedToken?.claims.stripeRole === 'daily';

			if (data.isPremium !== isPremium) {
				await firebase.functions().httpsCallable('updatePremiumStatus')({isPremium: isPremium});
				await this.checkInitialAuth();
			}
			this.store.commit('setIsPremium', isPremium);
		}

		async defineTrialSettings(data: any) {
			if (moment(moment().format('MM/DD/YYYY')).isAfter(data.trial.month)) {
				console.log('defineTrialSettings');
				return await firebase.functions().httpsCallable('resetMonthlyDownloadLimit')().then(async (downloadResult) => {
					return downloadResult.data;
				}).catch(error => console.log(error));
			} else {
				return data.trial;
			}
		}

		setTheme() {
			if (this.firebaseCustomer['theme'] === 'dark') {
				document.documentElement.classList.add('dark');
			}
			this.store.commit("setTheme", this.firebaseCustomer['theme']);
		}
		setWindowWidth() {
			this.store.commit("setWindowWidth", window.innerWidth);
			this.store.commit("setBreakpoint", this.breakpoint);
		}
		setWindowHeight() {
			this.store.commit("setWindowHeight", window.innerHeight);
		}
		beforeUnmount() {
			window.removeEventListener("resize", this.setWindowWidth);
			window.removeEventListener("resize", this.setWindowHeight);
		}

		get breakpoint() {
			if (this.windowWidth >= 1536) {
				return '2xl';
			} else if (this.windowWidth >= 1280) {
				return 'xl';
			} else if (this.windowWidth >= 1024) {
				return 'lg';
			} else if (this.windowWidth >= 768) {
				return 'md';
			} else if (this.windowWidth >= 640) {
				return 'sm';
			} else {
				return 'xs';
			}
		}
		get windowWidth() {
			return this.store.state.windowWidth;
		}
		get isPremium() {
			return this.store.state.isPremium;
		}
		get isSignedIn() {
			// return false;
			return this.store.state.isSignedIn;
		}
		get firebaseResources() {
			return this.store.state.firebaseResources;
		}
		get firebaseCustomer() {
			return this.store.state.firebaseCustomer;
		}
		get activeSignIn () {
			return this.$route.path.includes('sign-in');
		}
	}
