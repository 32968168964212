
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'ChangeEmail',
	components: {
	},
})
export default class ChangeEmail extends mixins(Services) {
	@Inject() store;
	@Inject() firebase;
	@Inject() firebaseAuthentication;
	@Prop() visible;

	email: any = '';
	password: any = '';

	close() {
		this.$emit('close');
		this.email = '';
		this.password = '';
	}
	async submit() {
		await this.firebaseAuthentication.changeEmail(this.firebaseCustomer.email, this.email, this.password).then(async (res: any) => {
			if (res !== 'success') {
				this.notify('danger', 'Authentication', res);
			} else {
				await this.firebase.auth().currentUser.sendEmailVerification().then();
				this.firebase.auth().signOut().then(() => {
					this.store.commit('setIsSignedIn', false);
					localStorage.removeItem('timestamp');
					localStorage.removeItem('customer');
					localStorage.removeItem('resources');
					location.reload();
				});
			}
		});
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}

}
