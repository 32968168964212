
import {Options, Inject, mixins} from 'vue-property-decorator';
import { Services } from "@/mixins/services";
import $ from 'jquery';

@Options({
	name: 'Verify',
	components: {
	},
})
export default class Verify extends mixins(Services) {
	@Inject() readonly firebase!: any;
	@Inject() readonly store!: any;
	@Inject() readonly firebaseAuthentication!: any;

	email = '';
	password = '';

	mounted() {
		setInterval(() => {
			this.firebase.auth().currentUser.reload().then(() => {
				if (this.firebase.auth().currentUser.emailVerified) {
					location.reload();
				}
			});
		}, 1000);
	}

	async changeEmail() {
		await this.firebaseAuthentication.changeEmail(this.firebaseCustomer.email, this.email, this.password).then(async (res: any) => {
			if (res !== 'success') {
				if (res === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else if (res === "auth/email-already-in-use") {
					this.notify('danger', 'Authentication', 'Email Already in Use');
				} else {
					this.notify('danger', 'Authentication', res);
				}
			} else {
				this.resendEmailVerification();
				this.toggle();
			}
		});
	}

	resendEmailVerification() {
		this.firebase.auth().currentUser.sendEmailVerification().then(() => {
			this.notify('success', 'Authentication', 'New email verification sent');
		}).catch(() => {
			this.notify('danger', 'Authentication', 'Too many requests, try again later');
		});
	}

	signOut() {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
			location.reload();
		});
	}

	toggle () {
		$('.loginForm').toggleClass('s--signup');
		setTimeout(() => {
			this.email = '';
			this.password = '';
		}, 600);
	}

	get firebaseCustomer() {
		return this.firebase.auth().currentUser;
	}
}
