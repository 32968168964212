<template>
	<div>
		<p class="block text-xs text-secondary">{{ utility.options.label }}</p>
		<div class="flex">
			<tw-input-group class="mr-2 flex-1"
			                type="number"
			                :value="x"
			                trailingIcon="X"
			                @updateValue="x = $event" />
			<tw-input-group class="ml-2 flex-1"
			                type="number"
			                :value="y"
			                trailingIcon="Y"
			                @updateValue="y = $event"/>
		</div>
	</div>

</template>

<script lang="ts">
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";
	import VueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/default.css';

	@Options({
		name: 'TwRangeSlider',
		components: {
			VueSlider
		},
	})
	export default class TwRangeSlider extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		x: any = 0;
		y: any = 0;

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.x = this.value.x;
			this.y = this.value.y;
		}

		@Watch('x')
		@Watch('y')
		watchCoordinates() {
			this.valueHandler(this.utility,{ x: this.x, y: this.y }, this.variables);
		}
	}
</script>
