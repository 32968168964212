<template>
		<TransitionRoot
			:show="ready"
			enter="transition-opacity duration-500"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="transition-opacity duration-500"
			leave-from="opacity-100"
			leave-to="opacity-0"
			class="relative h-screen w-screen">
			<div class="absolute inset-0 z-10">
				<div class="relative h-screen w-screen py-20">
					<router-view></router-view>
					<img class="h-12 absolute bottom-3 right-3" src="../assets/img/firebase-light.svg" alt="">
				</div>
			</div>

			<div class="absolute inset-0 z-0">
				<div class="slideshow">
					<div v-for="(backdrop, index) in backdrops" :key="index" class="slideshow-image" :style="`background-image: url(${backdrop})`"></div>
				</div>
			</div>
		</TransitionRoot>
</template>

<script lang="ts">
import {Vue, Options, Inject} from 'vue-property-decorator';
	import axios from 'axios';

	@Options({
		name: 'Login',
		components: {
		},
	})
	export default class Login extends Vue {
		@Inject() readonly store!: any;
		@Inject() readonly firebase!: any;

		backdrops: any = [];
		ready: any = false;

		async beforeMount() {
			await axios.get('https://api.themoviedb.org/3/movie/popular?api_key=121f04161acaada7f621744b0ba5cea9').then(res => {
				res.data.results.forEach((el: any, index: any) => {
					if (index > 3 ) return;
					this.backdrops.push(`https://image.tmdb.org/t/p/original${el.backdrop_path}`);
				});
			}).then(() => {
				this.ready = true;
			});
		}
	}
</script>

<style scoped lang="scss">
	.slideshow {
		position: relative;
		width: 100vw;
		height: 100vh;
		overflow: hidden;
	}

	.slideshow-image {
		position: absolute;
		width: 100%;
		height: 100%;
		background: no-repeat 50% 50%;
		background-size: cover;
		-webkit-animation-name: fade;
		animation-name: fade;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-duration: 60s;
		animation-duration: 60s;
		opacity: 1;
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}

	.slideshow-image:nth-child(1) {
		-webkit-animation-name: fade-1;
		animation-name: fade-1;
		z-index: 3;
	}

	.slideshow-image:nth-child(2) {
		-webkit-animation-name: fade-2;
		animation-name: fade-2;
		z-index: 2;
	}

	.slideshow-image:nth-child(3) {
		-webkit-animation-name: fade-3;
		animation-name: fade-3;
		z-index: 1;
	}

	.slideshow-image:nth-child(4) {
		-webkit-animation-name: fade-4;
		animation-name: fade-4;
		z-index: 0;
	}

	@keyframes fade-1 {
		0% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		1.5625% {
			opacity: 1;
		}
		23.4375% {
			opacity: 1;
		}
		26.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		98.4375% {
			opacity: 0;
			-webkit-transform: scale(1.21176);
			transform: scale(1.21176);
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fade-2 {
		23.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		26.5625% {
			opacity: 1;
		}
		48.4375% {
			opacity: 1;
		}
		51.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	@keyframes fade-3 {
		48.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		51.5625% {
			opacity: 1;
		}
		73.4375% {
			opacity: 1;
		}
		76.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	@keyframes fade-4 {
		73.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		76.5625% {
			opacity: 1;
		}
		98.4375% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	@-webkit-keyframes fade-1 {
		0% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		1.5625% {
			opacity: 1;
		}
		23.4375% {
			opacity: 1;
		}
		26.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		98.4375% {
			opacity: 0;
			-webkit-transform: scale(1.21176);
			transform: scale(1.21176);
		}
		100% {
			opacity: 1;
		}
	}
	@-webkit-keyframes fade-2 {
		23.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		26.5625% {
			opacity: 1;
		}
		48.4375% {
			opacity: 1;
		}
		51.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	@-webkit-keyframes fade-3 {
		48.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		51.5625% {
			opacity: 1;
		}
		73.4375% {
			opacity: 1;
		}
		76.5625% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
	@-webkit-keyframes fade-4 {
		73.4375% {
			opacity: 1;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
		76.5625% {
			opacity: 1;
		}
		98.4375% {
			opacity: 1;
		}
		100% {
			opacity: 0;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}
</style>
