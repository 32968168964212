<template>
	<div>
		<p class="block text-xs text-secondary pb-1">{{ utility.options.label }}</p>
		<div class="grid grid-cols-4 gap-x-1 h-28 w-full">
			<div class="col-span-1 flex flex-col">
				<button class="w-full background-gradient-br text-primary h-7 border border-b-0 border-primary rounded-t-md" @click="increase('one', integers.one)"><tw-icon icon="chevron-up"/></button>
				<p class="flex-1 flex flex-col justify-center text-center text-2xl border border-primary text-primary background-secondary">{{integers.one}}</p>
				<button class="w-full background-gradient-br text-primary h-7 border border-t-0 border-primary rounded-b-md" @click="decrease('one', integers.one)"><tw-icon icon="chevron-down"/></button>
			</div>
			<div class="col-span-1 flex flex-col">
				<button class="w-full background-gradient-br text-primary h-7 border border-b-0 border-primary rounded-t-md" @click="increase('two', integers.two)"><tw-icon icon="chevron-up"/></button>
				<p class="flex-1 flex flex-col justify-center text-center text-2xl border border-primary text-primary background-secondary">{{integers.two}}</p>
				<button class="w-full background-gradient-br text-primary h-7 border border-t-0 border-primary rounded-b-md" @click="decrease('two', integers.two)"><tw-icon icon="chevron-down"/></button>
			</div>
			<div class="col-span-1 flex flex-col">
				<button class="w-full background-gradient-br text-primary h-7 border border-b-0 border-primary rounded-t-md" @click="increase('three', integers.three)"><tw-icon icon="chevron-up"/></button>
				<p class="flex-1 flex flex-col justify-center text-center text-2xl border border-primary text-primary background-secondary">{{integers.three}}</p>
				<button class="w-full background-gradient-br text-primary h-7 border border-t-0 border-primary rounded-b-md" @click="decrease('three', integers.three)"><tw-icon icon="chevron-down"/></button>
			</div>
			<div class="col-span-1 flex flex-col">
				<p class="flex-1 flex my-7 flex-col justify-center text-center text-2xl border border-primary text-dimmed background-secondary rounded-md">0</p>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";

	@Options({
		name: 'ClIntegerTicker',
		components: {
		},
	})
	export default class ClIntegerTicker extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		integers = {
			one: 1,
			two: 9,
			three: 9,
		};

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.integers.one = Number.parseInt(this.value[0]);
			this.integers.two = Number.parseInt(this.value[1]);
			this.integers.three = Number.parseInt(this.value[2]);
		}

		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}

		increase (vars, val) {
			if (val < 9) {
				this.integers[vars] = val + 1;
			} else {
				this.integers[vars] = 0;
			}
		}

		decrease (vars, val) {
			if (val > 0) {
				this.integers[vars] = val - 1;
			} else {
				this.integers[vars] = 9;
			}
		}

		get activeValue() {
			return this.integers.one.toString() + this.integers.two.toString() + this.integers.three.toString() + '0';
		}
	}
</script>
