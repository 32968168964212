
	import { Vue, Options } from 'vue-property-decorator';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import $ from 'jquery';

	@Options({
		name: 'TwNotify',
		components: {
		},
	})
	export default class TwNotify extends Vue {
		readonly eventBus: EventBus = EventBus.getInstance();

		params: any = {};
		visible = false;
		progress: any = 0;

		notifyTimer = (() => {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			let self = this;
			let timer;
			function start(duration, $element, width) {
				let timeLeft = duration;
				timer = setInterval(countdown, 100);

				function countdown() {
					if(timeLeft >= 0) {
						let progressBarWidth = timeLeft * (width / duration);
						$element.animate({ width: progressBarWidth }, 100, "linear");
						--timeLeft;
					} else {
						clearInterval(timer);
						self.visible = false;
					}
				}
			}

			function stop() {
				clearInterval(timer);
				self.visible = false;
			}

			return {
				start:start,
				stop:stop
			};
		})();

		async beforeMount() {
			this.eventBus.on(EventName.ShowNotify, await this.showNotification);
		}

		async showNotification(e) {
			await this.reset();
			this.params = e;
			this.visible = true;
			setTimeout(() => {
				let el = $('#progressBar');
				this.notifyTimer.start(this.params.duration * 10, el, el.width());
			}, 100);
		}

		async reset () {
			this.notifyTimer.stop();
			this.visible = false;
			this.progress = 0;
		}

		get style () {
			if (this.params.variant === 'success') {
				return {
					icon: 'check-circle',
					color: 'text-green-400',
					bar: 'bg-green-200',
					progress: 'bg-green-500',
				};
			} else if (this.params.variant === 'danger') {
				return {
					icon: 'times-circle',
					color: 'text-red-400',
					bar: 'bg-red-200',
					progress: 'bg-red-500',
				};
			} else {
				return {
					icon: 'exclamation-circle',
					color: 'text-primary',
					bar: 'bg-blue-200',
					progress: 'bg-blue-500',
				};
			}
		}
	}
