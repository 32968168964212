<template>
	<div>
		<p class="block text-xs text-secondary">{{ utility.options.label }}</p>
		<tw-select-menu :value="activeValue"
		                :options="mod"
		                :modifier="utility.options.mod"
		                @updateValue="activeValue = $event"/>
	</div>
</template>

<script lang="ts">
	import { Options, Prop, Watch, Inject, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";

	@Options({
		name: 'ClSelectMenu',
		components: {
		},
	})
	export default class ClSelectMenu extends mixins(Services) {
		@Inject() store;

		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		activeValue: any = {};

		@Watch('value', { immediate: true })
		watchValue() {
			switch (this.utility.options.mod) {
				case 'PosterCount':
				case 'FontAwesome':
				case 'TV':
					if (this.value) {
						this.activeValue = this.mod.find(el => el.value === this.value);
					}
					break;
				case 'Network':
					if (this.value) {
						this.activeValue = this.mod.find(el => el.value.url === this.value.url);
					}
					break;
				case 'Banner':
					if (this.value) {
						this.activeValue = this.mod.find(el => el.value.urlFull === this.value.urlFull);
					}
					break;
				case 'FontFamily':
					this.activeValue = this.mod.find(el => el.name === this.value);
					break;
			}
		}

		@Watch('activeValue')
		watchActiveValue() {
			switch (this.utility.options.mod) {
				case 'PosterCount':
				case 'Banner':
				case 'FontAwesome':
				case 'Network':
				case 'TV':
					this.valueHandler(this.utility, this.activeValue.value, this.variables);
					break;
				case 'FontFamily':
					this.valueHandler(this.utility, this.activeValue.name, this.variables);
					break;
			}
		}

		get mod () {
			const arr: any = [];
			switch (this.utility.options.mod) {
				case 'FontAwesome': {
					Object.keys(this.firebaseResources.Images['font-awesome']).forEach((key: any, index) => {
						arr.push({
							id: index,
							name: key,
							value: this.firebaseResources.Images['font-awesome'][key],
						});
					});
					return arr.sort((a, b) => (a.name > b.name) ? 1 : -1);
				}
				case 'Network':
					Object.keys(this.firebaseResources.Images['networks']).forEach((key: any, index) => {
						arr.push({
							id: index,
							name: key,
							value: {
								hex: this.firebaseResources.Images['networks'][key].hex,
								url: this.firebaseResources.Images['networks'][key].url,
							}
						});
					});
					return arr.sort((a, b) => (a.name > b.name) ? 1 : -1);
				case 'Banner':
					Object.keys(this.firebaseResources.Images['banners']).forEach((key: any, index) => {
						arr.push({
							id: index,
							name: key,
							value: {
								urlFull: this.firebaseResources.Images['banners'][key]['url-full'],
								urlMin: this.firebaseResources.Images['banners'][key]['url-min'],
								hex: this.firebaseResources.Images['banners'][key].hex,
							}
						});
					});
					return arr.sort((a, b) => (a.name > b.name) ? 1 : -1);
				case 'TV':
					Object.keys(this.firebaseResources.Images['tv']).forEach((key: any, index) => {
						arr.push({
							id: index,
							name: key,
							value: this.firebaseResources.Images['tv'][key],
						});
					});
					return arr.sort((a, b) => (a.name > b.name) ? 1 : -1);
				case 'FontFamily':
					return [
						{id: 0, name: 'Bebas Neue'},
						{id: 1, name: 'HK Grotesk'},
						{id: 2, name: 'Montserrat'},
						{id: 3, name: 'Open Sans'},
						{id: 4, name: 'Poppins'},
						{id: 5, name: 'Source Sans Pro'},
					];
				case 'PosterCount':
					return [
						{id: 0, name: 'Two' ,value: 2},
						{id: 1, name: 'Three', value: 3},
						{id: 2, name: 'Four', value: 4},
						{id: 3, name: 'Five', value: 5},
					];
				default:
					return null;
			}
		}

		get logos() {
			return this.store.state.logos;
		}
		get firebaseResources() {
			return this.store.state.firebaseResources;
		}
	}
</script>
