import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const config = {
	apiKey: "AIzaSyCQLfb3_VnP8cny1qwSwE0KSPphIx3yELA",
	authDomain: "coverlabs-io.firebaseapp.com",
	databaseURL: "https://coverlabs-io.firebaseio.com",
	projectId: "coverlabs-io",
	storageBucket: "coverlabs-io.appspot.com",
	messagingSenderId: "794280031129",
	appId: "1:794280031129:web:37791b443b503fee"
};
firebase.initializeApp(config);
// firebase.functions().useFunctionsEmulator('http://localhost:5001');

export default firebase;
