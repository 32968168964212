
	import { Vue, Options, Inject } from "vue-property-decorator";
	// import { loadStripe } from "@stripe/stripe-js";
	import comingSoon from "@/components/modals/coming-soon.vue";
	import betaAlert from "@/components/modals/beta-premium.vue";

	@Options({
		name: 'Premium',
		components: {
			betaAlert,
			comingSoon
		},
	})
	export default class Premium extends Vue {
		@Inject() readonly firebase!: any;
		@Inject() readonly $store!: any;
		betaAlertVisible = false;
		showComingSoon = false;


		async goPremium() {
			this.showComingSoon = true;
			// const testPublishedKey = 'pk_test_51IWAKLKRAT62QOAPYrh91B4ILHVlkR0HdN7IWrl98MeS14UqjpeCUsVaSSDojL28lvAAEbRS9miEJFNx7ghGO23L00ikXV148B';
			// // const testPremiumPrice = 'price_1IWALiKRAT62QOAPWZXFGhGP';
			// const testDailyPrice = 'price_1IWkmFKRAT62QOAPaCurcvM5';
			//
			// const docRef = await this.firebase.firestore()
			// 	.collection('customers')
			// 	.doc(this.firebaseCustomer.uid)
			// 	.collection('checkout_sessions')
			// 	.add({
			// 		'price': testDailyPrice,
			// 		'success_url': window.location.origin,
			// 		'cancel_url': window.location.origin,
			// 	});
			//
			// // Wait for the CheckoutSession to get attached by the extension
			// docRef.onSnapshot(async (snap: any) => {
			// 	const { error, sessionId } = snap.data();
			// 	if (error) {
			// 		// Show an error to your customer and
			// 		// inspect your Cloud Function logs in the Firebase console.
			// 		alert(`An error occured: ${error.message}`);
			// 	}
			// 	if (sessionId) {
			// 		// We have a session, let's redirect to Checkout
			// 		// Init Stripe
			// 		const stripe = await loadStripe(testPublishedKey);
			// 		stripe?.redirectToCheckout({
			// 			sessionId: sessionId
			// 		}).then((res: any) => {
			// 			console.log(res);
			// 		});
			// 	}
			// });
		}

		get firebaseCustomer() {
			return this.$store.state.firebaseCustomer;
		}
	}
