<template>
	<designer ref="designer"
	          :name="name"
	          :imported="imported"
	          :static="static"
	          :default="this.default"
	          :controls="controls"
	          :drawer="drawer"
	          @imageLoaded="imageLoadedListener"/>
</template>

<script lang="ts">

	// TODO: MAYBE ADD IMAGE SCALAR CONTROL IN TEMPLATE
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Everglade",
		components: {
			designer
		},
	})
	export default class Everglade extends mixins(Functions) {
		readonly eventBus: EventBus = EventBus.getInstance();
		$refs: any;

		name = 'Everglade';
		imported: any = {};
		static: any = {
			activeLogo: null,
			usingPreferredColor: false,
		};
		default = {
			colors_border: '#20e380',
			colors_background: '#232930',
			extras_show_gridMap: false,
			image_focus: 'primary',
			image_logo: {
				hex: '#20e380',
				url: 'https://firebasestorage.googleapis.com/v0/b/coverlabs-io.appspot.com/o/resources%2Fnetworks%2FHulu.png?alt=media&token=1db56ed0-0bcb-43bb-80de-1b2eff1df2af'
			},
			images: {
				primary: new PosterDto(),
			}
		};
		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_logo',
						comp: 'select-menu',
						options: {
							mod: 'Network',
							label: 'Provided Logo',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_border',
						comp: 'color-swatches',
						options: {
							label: 'Border',
						}
					},
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		];

		@Watch('default.image_logo')
		watchObjectImgLogo() {
			this.eventBus.emit(EventName.ClearPoster, {type: 'primary'});
			this.loadImage();
		}

		@Watch('default.colors_border')
		watchColorBanner() {
			this.static.usingPreferredColor = false;
		}

		created() {
			this.default.images.primary.initialY = 750;
			this.default.images.primary.initialW = 600;
			this.default.images.primary.initialH = 400;
			this.default.images.primary.maxW = 600;
			this.default.images.primary.maxH = 400;
		}
		mounted() {
			this.loadImage();
		}
		drawer (ctx: any, variables: any, poster: any) {
			const luminance = this.color_hexToLuminance(variables.colors_background) <= 0.35 ? '#FFFFFF' : '#000000';
			const hexBackground: any = this.color_hexToRgb(variables.colors_background);
			const priBkgRgba = `rgba(${hexBackground.r}, ${hexBackground.g}, ${hexBackground.b}, 1)`;
			const secBkgRgba = `rgba(${hexBackground.r}, ${hexBackground.g}, ${hexBackground.b}, .75)`;
			const gradient = ctx.createRadialGradient(-200, 400, 0, 215, 175, 1000);
			gradient.addColorStop(0.000, secBkgRgba);
			gradient.addColorStop(1.000, priBkgRgba);

			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, poster.baseHeight, luminance, null, null);
			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, poster.baseHeight, gradient, null, null);

			const hexBorder: any = this.color_hexToRgb(variables.colors_border);
			const priBorderRgba = `rgba(${hexBorder.r}, ${hexBorder.g}, ${hexBorder.b}, 1)`;
			const secBorderRgba = `rgba(${hexBorder.r}, ${hexBorder.g}, ${hexBorder.b}, .7)`;
			const grd = ctx.createLinearGradient(0, 0, 970, 970);
			grd.addColorStop(0.0, priBorderRgba);
			grd.addColorStop(0.2, secBorderRgba);
			grd.addColorStop(0.4, priBorderRgba);
			grd.addColorStop(0.6, secBorderRgba);
			grd.addColorStop(0.8, priBorderRgba);
			grd.addColorStop(1.0, secBorderRgba);

			this.draw_rectangleCornered(ctx, 15, 15, 970, 1470, 22, grd);
			this.draw_rectangle(ctx, 38, 38, poster.baseWidth - 76, poster.baseHeight - 76, luminance, null, null);
			this.draw_rectangle(ctx, 37, 37, poster.baseWidth - 74, poster.baseHeight - 74, gradient, null, null);

			if (poster.primaryFileSource) {
				this.draw_image(ctx, poster, 'primary', variables);
			} else if (this.static.activeLogo) {
				const f = variables.image_logo.url.split('.').slice(0, -1).join('.');
				variables.name = f.substring(f.lastIndexOf('/') + 1);

				const imgRatio = this.static.activeLogo.width / this.static.activeLogo.height;
				const maxHeight = 400;
				const maxWidth = 600;
				let calcPosterWidth = maxHeight * imgRatio;
				let calcPosterHeight = maxHeight;
				if (calcPosterWidth > maxWidth) {
					calcPosterWidth = maxWidth;
					calcPosterHeight = maxWidth / imgRatio;
				}

				const xOffset = (poster.baseWidth / 2) - (calcPosterWidth / 2);
				const yOffset = (poster.baseHeight / 2) - (calcPosterHeight / 2);
				ctx.drawImage(this.static.activeLogo, xOffset, yOffset, calcPosterWidth, calcPosterHeight);
			}

			if (!poster.primaryFileSource && !this.static.activeLogo) {
				this.write_imageHelper(ctx, 1000 / 2, 1500 / 2, 30, 'networks');
			}

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		loadImage () {
			if (this.default.image_logo.url) {
				const image = new Image();
				image.crossOrigin = "anonymous";
				image.src = this.default.image_logo.url;
				this.default.colors_border = this.default.image_logo.hex;
				image.onload = () => {
					this.static.activeLogo = image;
					this.static.usingPreferredColor = true;
					this.$refs.designer.callPosterDraw();
				};
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
</script>
