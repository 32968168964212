
import {Options, Vue, Inject} from 'vue-property-decorator';

	@Options({
		components: {},
	})
	export default class DesignerFonts extends Vue {
		@Inject() store;

		generateIcon (font) {
			return JSON.parse(`["\\u${font}"]`)[0];
		}
		mounted() {
			const FontFaceObserver = require('fontfaceobserver');
			const bebasNeue = new FontFaceObserver('Bebas Neue').load();
			const eightyCologne = new FontFaceObserver('Eighty Cologne').load();
			const eightyHongKong = new FontFaceObserver('Eighty Hong Kong').load();
			const hkGrotesk = new FontFaceObserver('HK Grotesk').load();
			const montserrat = new FontFaceObserver('Montserrat').load();
			const openSans = new FontFaceObserver('Open Sans').load();
			const poppins = new FontFaceObserver('Poppins').load();
			const sourceSansPro = new FontFaceObserver('Source Sans Pro').load();

			Promise.all([bebasNeue, eightyCologne, eightyHongKong,  hkGrotesk, montserrat, openSans, poppins, sourceSansPro]).then(() => {
				this.$emit('fontsReady');
			});
		}
		get firebaseResources() {
			return this.store.state.firebaseResources;
		}
	}
