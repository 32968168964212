<template>
	<designer ref="designer"
	          :name="name"
	          :imported="imported"
	          :static="static"
	          :default="this.default"
	          :controls="controls"
	          :drawer="drawer"
	          @imageLoaded="imageLoadedListener"/>
</template>

<script lang="ts">
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { EventBus, EventName } from '@/helpers/event-bus';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Denali",
		components: {
			designer
		},
	})
	export default class Denali extends mixins(Functions) {
		readonly eventBus: EventBus = EventBus.getInstance();
		$refs: any;

		name = 'Denali';
		imported: any = {};
		static: any = {
			activeNetwork: null,
		};
		default: any = {
			text_fontFamily: 'Source Sans Pro',
			text_season: 'SEASON ONE',
			text_seasonNumber: '1',
			text_seasonSize: 40,
			text_seasonNumberSize: 55,
			text_seasonPosition: 'secondary',
			colors_season: '#FFFFFF',
			overlay_color: '#000000',
			overlay_opacity: 0,
			overlay_direction: 'up',
			extras_show_logo: true,
			extras_show_network: true,
			extras_show_season: true,
			extras_show_customMap: false,
			extras_show_gridMap: false,
			image_focus: 'primary',
			image_network: null,
			images: {
				primary: new PosterDto(),
				secondary: new PosterDto(),
				tertiary: new PosterDto(),
			}
		}

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_focus',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'Image',
							label: 'Image Focus',
							count: 3,
						}
					},
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
					{
						variable: 'image_network',
						comp: 'select-menu',
						options: {
							mod: 'TV',
							label: 'Provided Networks (Fixed size)',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_seasonPosition',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'Override',
							overrides: ['Top Right', 'Bottom Center'],
							label: 'Season Position',
							count: 2,
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_season',
						comp: 'input-group',
						visible: true,
						options: {
							mod: 'text',
							label: 'Season Text',
						}
					},
					{
						variable: 'text_seasonNumber',
						comp: 'input-group',
						options: {
							mod: 'number',
							label: 'Season Number',
							min: 1,
							max: 99,
						}
					},
					{
						variable: 'text_seasonSize',
						comp: 'slider',
						options: {
							label: 'Season Text Font Size',
							min: 20,
							max: 80,
							type: "px",
						}
					},
					{
						variable: 'text_seasonNumberSize',
						comp: 'slider',
						options: {
							label: 'Season Number Font Size',
							min: 20,
							max: 80,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_season',
						comp: 'color-swatches',
						options: {
							label: 'Season Font',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_direction',
						comp: 'button-group',
						options: {
							mod: 'Opacity',
							label: 'Direction',
							count: 2,
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_logo',
						comp: 'toggle',
						options: {
							name: 'logo',
							label: 'Show Logo Art',
						}
					},
					{
						variable: 'extras_show_network',
						comp: 'toggle',
						options: {
							name: 'network',
							label: 'Show Network',
						}
					},
					{
						variable: 'extras_show_season',
						comp: 'toggle',
						options: {
							name: 'season',
							label: 'Show Season',
						}
					},
					{
						variable: 'extras_show_customMap',
						comp: 'toggle',
						options: {
							name: 'customMap',
							label: 'Show Custom Map',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		];

		created() {
			this.default.images.secondary.initialY = 1200;
			this.default.images.secondary.initialW = 888;
			this.default.images.secondary.initialH = 176;
			this.default.images.secondary.maxW = 888;
			this.default.images.secondary.maxH = 176;
			this.default.images.tertiary.initialX = 55;
			this.default.images.tertiary.initialY = 75;
			this.default.images.tertiary.initialW = 195;
			this.default.images.tertiary.initialH = 60;
			this.default.images.tertiary.positionX = 'left';
			this.default.images.tertiary.maxW = 195;
			this.default.images.tertiary.maxH = 60;
		}

		@Watch('default.image_network', { immediate: true })
		watchImageNetwork() {
			this.eventBus.emit(EventName.ClearPoster, {type: 'tertiary'});
			this.loadImage();
		}

		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 650, 35, 'tv.1');

			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

			if (variables.extras_show_logo) {
				poster.secondaryFileSource ? this.draw_image(ctx, poster, 'secondary', variables) : this.write_imageHelper(ctx, 1000 / 2, 1226, 0, 'tv.2');
			}

			if (variables.extras_show_network) {
				if (poster.tertiaryFileSource) {
					this.draw_image(ctx, poster, 'tertiary', variables);
				} else if (this.static.activeNetwork) {
					const yOffset = variables.images.tertiary.initialY - (this.static.activeNetwork.height / 2);
					ctx.drawImage(this.static.activeNetwork, variables.images.tertiary.initialX, yOffset, this.static.activeNetwork.width, this.static.activeNetwork.height);
				}
			}

			if (variables.extras_show_network && (!poster.tertiaryFileSource && !this.static.activeNetwork)) {
				this.write_imageHelper(ctx, 55, 80, 0, 'tv.3');
			}

			if (variables.extras_show_season) {
				if (variables.text_seasonPosition === 'primary') {
					const xOffset = parseInt(variables.text_seasonNumber) > 9 ? 890 : 900;
					this.write_textLine(ctx, 'SEASON', xOffset, 80, variables.text_seasonSize, variables.text_fontFamily, variables.colors_season, 'bold', '1', 'right', 'middle');
					this.write_textLine(ctx, variables.text_seasonNumber, 1000 - 50, 80, variables.text_seasonSize, variables.text_fontFamily, variables.colors_text, 'bold', 'normal', 'right', 'middle');
				} else if (variables.text_seasonPosition === 'secondary') {
					this.write_textLine(ctx, variables.text_season, 500, 1410, variables.text_seasonNumberSize, variables.text_fontFamily, variables.colors_season, 'bold', '2', 'center', 'middle');
				}
			}

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
			if (variables.extras_show_customMap) {
				const vertX = [56, 500, 944];
				const vertY = [55, 99, 1138, 1226, 1314];
				ctx.lineWidth = 1;
				ctx.strokeStyle = '#FFFFFF';
				vertX.forEach((x) => {
					ctx.beginPath();
					ctx.moveTo(x, 0);
					ctx.lineTo(x, 1500);
					ctx.closePath();
					ctx.stroke();
				});
				vertY.forEach((y) => {
					ctx.beginPath();
					ctx.moveTo(0, y);
					ctx.lineTo(1000, y);
					ctx.closePath();
					ctx.stroke();
				});
			}
		}

		loadImage () {
			if (this.default.image_network) {
				const image = new Image();
				image.crossOrigin = "anonymous";
				image.src = this.default.image_network;
				image.onload = () => {
					this.static.activeNetwork = image;
					this.$refs.designer.callPosterDraw();
				};
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
</script>
