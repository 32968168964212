
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";

	@Options({
		name: 'ClInputGroup',
		components: {
		},
	})
	export default class ClInputGroup extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		activeValue: any = '';

		@Watch('value', { immediate: true })
		watchValue() {
			this.activeValue = this.value;
		}

		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}
	}
