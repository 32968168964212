<template>
	<div class="loginForm rounded-tl-2xl rounded-br-2xl fixed z-50 overflow-hidden bg-white shadow-lg left-1/2 top-1/2">
		<div class="form sign-in p-7 h-full">
			<div class="signInForm flex flex-col h-full">
				<p class="text-center pb-2">Pending Verification!</p>
				<div class="flex-1 pt-6">
					<p class="text-md text-center">Your signed in but unverified. Please check your email to verify your new account</p>
				</div>
				<p class="text-center cursor-pointer mb-12 text-xs" @click="signOut()">Sign Out</p>

				<tw-button class="rounded-full" text="Resend Verification Email" @click="resendEmailVerification()"/>
			</div>
		</div>
		<div class="sub-form overflow-hidden absolute bg-white h-full left-0 top-0">
			<div class="img w-full flex flex-col h-full py-7 overflow-hidden z-10 absolute inset-0">
				<div class="flex-1">
					<div class="img__text m--up z-10 absolute left-0 w-full text-center text-white px-3">
						<p class="text-md md:text-lg pb-3 sm:pb-7">Wrong Email?</p>
						<p class="text-xs sm:text-sm">It happens, head over here to update your email to a new one!</p>
					</div>
					<div class="img__text m--in z-10 absolute left-0 w-full text-center text-white px-3">
						<p class="text-md md:text-lg pb-3 sm:pb-7">Unverified?</p>
						<p class="text-xs sm:text-sm">Before you get creating, we need to make sure you are real!</p>
					</div>
				</div>
				<div class="img__btn bg-transparent w-full uppercase text-white h-9 text-sm cursor-pointer relative z-10 overflow-hidden" @click="toggle()">
					<span class="m--up absolute left-0 top-0 text-center leading-9 w-full">Change Email</span>
					<span class="m--in absolute left-0 top-0 text-center leading-9 w-full">Verify Email</span>
				</div>
			</div>
			<div class="form sign-up p-7 h-full">
				<div class="flex flex-col h-full">
					<p class="text-center pb-2">Change Email</p>

					<div class="flex-1 pt-6">
						<p class="center pb-2">Don't worry! Simply enter a new email and your current password. It's that easy.</p>
						<tw-input-group label="New Email"
						                :value="email"
						                @updateValue="email = $event"/>
						<tw-input-group label="Password"
						                type="password"
						                :value="password"
						                @updateValue="password = $event"/>
					</div>
					<tw-button class="rounded-full" text="Change Email" @click="changeEmail()"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {Options, Inject, mixins} from 'vue-property-decorator';
import { Services } from "@/mixins/services";
import $ from 'jquery';

@Options({
	name: 'Verify',
	components: {
	},
})
export default class Verify extends mixins(Services) {
	@Inject() readonly firebase!: any;
	@Inject() readonly store!: any;
	@Inject() readonly firebaseAuthentication!: any;

	email = '';
	password = '';

	mounted() {
		setInterval(() => {
			this.firebase.auth().currentUser.reload().then(() => {
				if (this.firebase.auth().currentUser.emailVerified) {
					location.reload();
				}
			});
		}, 1000);
	}

	async changeEmail() {
		await this.firebaseAuthentication.changeEmail(this.firebaseCustomer.email, this.email, this.password).then(async (res: any) => {
			if (res !== 'success') {
				if (res === "auth/invalid-email") {
					this.notify('danger', 'Authentication', 'Invalid Email');
				} else if (res === "auth/email-already-in-use") {
					this.notify('danger', 'Authentication', 'Email Already in Use');
				} else {
					this.notify('danger', 'Authentication', res);
				}
			} else {
				this.resendEmailVerification();
				this.toggle();
			}
		});
	}

	resendEmailVerification() {
		this.firebase.auth().currentUser.sendEmailVerification().then(() => {
			this.notify('success', 'Authentication', 'New email verification sent');
		}).catch(() => {
			this.notify('danger', 'Authentication', 'Too many requests, try again later');
		});
	}

	signOut() {
		this.firebase.auth().signOut().then(() => {
			this.store.commit('setIsSignedIn', false);
			localStorage.removeItem('timestamp');
			localStorage.removeItem('customer');
			localStorage.removeItem('resources');
			location.reload();
		});
	}

	toggle () {
		$('.loginForm').toggleClass('s--signup');
		setTimeout(() => {
			this.email = '';
			this.password = '';
		}, 600);
	}

	get firebaseCustomer() {
		return this.firebase.auth().currentUser;
	}
}
</script>

<style scoped lang="scss">
.form,
.sub-form,
.img__text,
.img__btn span,
.img:before {
	transition: transform .8s ease-in-out;
}

.loginForm.s--signup .img__text.m--in,
.loginForm.s--signup .img__btn span.m--in {
	transform: translateY(0);
}
.loginForm.s--signup .img__btn span.m--up {
	transform: translateY(72px);
}
.img__btn span.m--in {
	transform: translateY(-72px);
}
.img {
	&:after,
	&:before {
		right: 0;
		bottom: 0;
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
	}
	&:after {
		background: rgba(0, 0, 0, 0.6);
	}
	&:before {
		background-size: cover;
	}
}

.loginForm.s--signup .sign-up {
	transform: translate3d(0, 0, 0);
}

@media only screen  and (min-width: 641px) {
	$formHeight: 380px;
	$formWidth: 550px;
	$activeFormWidth: 325px;
	$secondFormWidth: 225px;

	.loginForm {
		margin-left: -275px;
		margin-top: -190px;
		width: $formWidth;
		height: $formHeight;
	}
	.form {
		width: $activeFormWidth;
	}
	.sub-form {
		left: $activeFormWidth;
		width: $formWidth;
		padding-left: $secondFormWidth;
	}
	.img {
		width: $secondFormWidth;
		&:before {
			width: $formWidth;
			background-image: url("../../assets/img/loginWide.jpg");
		}
		.img__text.m--in {
			transform: translateY(-$formHeight);
		}
	}
	.sign-up {
		transform: translate3d(-$formWidth, 0, 0);
	}
	.loginForm.s--signup .img__text.m--up {
		transform: translateY($formHeight);
	}
	.loginForm.s--signup .img__text.m--in {
		transform: translateX(0);
	}
	.loginForm.s--signup .sub-form {
		transform: translate3d(-$activeFormWidth, 0, 0);
	}
	.loginForm.s--signup .sign-in,
	.loginForm.s--signup .img:before {
		transform: translate3d($activeFormWidth, 0, 0);
	}
}
@media only screen  and (max-width: 640px) {
	$formHeight: 530px;
	$formWidth: 320px;
	$activeFormHeight: 350px;
	$secondFormHeight: 180px;

	.loginForm {
		margin-left: -160px;
		margin-top: -265px;
		width: $formWidth;
		height: $formHeight;
	}
	.form {
		width: $formWidth;
		height: $activeFormHeight;
	}
	.sub-form {
		top: $activeFormHeight;
		width: $formWidth;
		padding-top: $secondFormHeight;
	}
	.img {
		height: $secondFormHeight;
		&:before {
			height: $formHeight;
			background-image: url("../../assets/img/loginTall.jpg");
		}
		.img__text.m--in {
			transform: translateY(-$formHeight);
		}
	}
	.sign-up {
		transform: translate3d(0, -$formHeight, 0);
	}
	.loginForm.s--signup .img__text.m--up {
		transform: translateY($formHeight);
	}
	.loginForm.s--signup .sub-form {
		transform: translate3d(0, -$activeFormHeight, 0);
	}
	.loginForm.s--signup .sign-in,
	.loginForm.s--signup .img:before {
		transform: translate3d(0, $activeFormHeight, 0);
	}
}
</style>
