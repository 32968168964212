<template>
	<div>
		<p class="block text-xs text-secondary mb-1">{{ utility.options.label }}</p>
		<span class="flex shadow-sm w-full">
			<button v-for="(item, key, index) in mod"
			        :key="key"
			        :class="[{'-ml-px': index > Object.keys(mod).length - 1},
			                 {'rounded-l-md border-r-0': index === 0},
			                 {'rounded-r-md border-l-0': index === Object.keys(mod).length - 1},
			                 activeValue === key ? 'background-gradient-br text-primary' : 'background-secondary text-secondary ']"
			        class="flex-1 text-center items-center px-4 py-2 border border-primary background-secondary text-xs hover-primary focus:outline-none focus-ring-offset-primary ring-offset-primary"
			        @click="activeValue = key"
					v-html="item">
			</button>
		</span>
	</div>
</template>

<script lang="ts">
	import { Options, Prop, Watch, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";

	@Options({
		name: 'TwButtonGroup',
		components: {
		},
	})
	export default class TwButtonGroup extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		activeValue: any = null;

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.activeValue = this.value;
		}
		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}

		get mod() {
			switch (this.utility.options.mod) {
				case 'Image':
					if (this.utility.options.count === 2) {
						return {
							primary: 'Poster',
							secondary: 'Logo',
						};
					} else if (this.utility.options.count === 3) {
						if (this.utility.options.overrides) {
							return {
								primary: this.utility.options.overrides[0],
								secondary: this.utility.options.overrides[1],
								tertiary: this.utility.options.overrides[2],
							};
						} else {
							return {
								primary: 'Poster',
								secondary: 'Logo',
								tertiary: 'Network',
							};
						}
					}
					break;
				case 'ImageShort':
					if (this.utility.options.count === 2) {
						return {
							primary: 'Left',
							secondary: 'Right',
						};
					} else if (this.utility.options.count === 3) {
						return {
							primary: 'Left',
							secondary: 'Middle',
							tertiary: 'Right',
						};
					} else if (this.utility.options.count === 4) {
						return {
							primary: '1',
							secondary: '2',
							tertiary: '3',
							quaternary: '4',
						};
					} else {
						return {
							primary: '1',
							secondary: '2',
							tertiary: '3',
							quaternary: '4',
							quinary: '5',
						};
					}
				case 'Logo':
					return {
						plex: 'Plex',
						emby: 'Emby',
					};
				case 'Opacity':
					return {
						up: 'Up',
						down: 'Down',
					};
				case 'Alignment':
					return {
						left: '<i class="far fa-align-left"></i>',
						center: '<i class="far fa-align-center"></i>',
						right: '<i class="far fa-align-right"></i>'
					};
				case 'Override':
					if (this.utility.options.count === 2) {
						return {
							primary: this.utility.options.overrides[0],
							secondary: this.utility.options.overrides[1],
						};
					} else if (this.utility.options.count === 3) {
						return {
							primary: this.utility.options.overrides[0],
							secondary: this.utility.options.overrides[1],
							tertiary: this.utility.options.overrides[2],
						};
					}
					break;
			}
			return null;
		}
	}
</script>
