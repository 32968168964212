<template>
	<div class="w-full flex flex-col">
		<div class="flex-1 flex flex-col">
			<toggle :utility="{ options: {label: 'Save To Library?' }}"
			        :value="saveVariables.saveToLibrary"
			        :useEmit="true"
			        :disabled="!isPremium"
			        @updateValue="saveEvent($event)"/>
			<template v-if="saveVariables.saveToLibrary">
				<div class="flex relative pt-3">
					<input name="input"
					       v-on:keyup.enter="search()"
					       v-model="searchString"
					       placeholder="Search for Movie/TV Show"
					       class="w-full pl-2 h-9 leading-9 border rounded-full placeholder-gray-300 text-sm text-primary border-primary background-secondary ring-offset-primary focus-ring-offset-primary"
					       :class="activeSearch ? 'pr-14' : 'pr-10'"/>
					<tw-icon v-if="activeSearch" class="absolute right-10 h-9 text-sm text-primary cursor-pointer" weight="fas" icon="times" @click="clearSearch()"/>
					<tw-icon class="absolute right-3 h-9 text-lg background-gradient-b text-transparent! bg-clip-text cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="search" @click="search()"/>
				</div>

				<div class="flex flex-col flex-1 pt-3">
					<nav class="flex space-x-1" aria-label="Tabs">
						<div v-for="tab in tabs" :key="`tab${tab.name}`" :class="[tab.current ? 'text-primary background-secondary' : 'text-dimmed hover:text-gray-500 dark:hover:text-gray-100', 'cursor-pointer p-2 flex-1 text-center text-xs rounded-t-md']" @click="changeTab(tab)">
							{{ tab.name }}
						</div>
					</nav>
					<div class="-mt-0.5 flex flex-col flex-1 border border-gray-100 dark:border-gray-800 rounded">
						<div class="flex-1 relative">
							<div class="absolute inset-0 overflow-hidden">
								<div class="h-full w-full overflow-auto">
									<div v-for="item in tabs.find(el => el.current).results" :key="item.id" class="datasource-item h-11 cursor-pointer flex px-2 py-0.5 my-0.5 overflow-hidden border-b border-gray-100 dark:border-gray-800" @click="setData(item, tabs.find(el => el.current).name)" :class="{'activeId' : saveVariables.id === item.id}">
										<div class="item-poster flex flex-col justify-center h-10">
											<img v-if="item.poster_path" class="item-poster h-full object-contain" :src="`https://image.tmdb.org/t/p/w200/${item.poster_path}`" alt="" onerror="this.style.display='none'">
											<img v-else class="item-poster h-full object-contain" src="./../../assets/img/blank_poster.svg" alt="">
										</div>
										<div class="flex flex-col pl-1 overflow-hidden">
											<p class="text-primary text-xs m-0 whitespace-nowrap overflow-hidden overflow-ellipsis h-5 leading-5">{{ item.name ? item.name : item.title}}</p>
											<p class="text-dimmed text-xs m-0 overflow-ellipsis h-5 leading-5">({{cleanReleaseDate(item)}})</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<nav class="text-primary background-secondary px-2 flex items-center justify-between h-8 border-t border-gray-100 dark:border-gray-800">
							<div class="">
								<span v-if="activePageTotal !== null" class="font-medium text-xs">Showing {{ activePage }} of {{ activePageTotal }} Pages</span>
							</div>
							<div class="flex-1 flex justify-end">
								<tw-icon class="mr-2 p-1 text-xs cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="chevron-left" @click="paginate('back')"/>
								<tw-icon class="p-1 text-xs cursor-pointer opacity-70 hover:opacity-100" weight="fas" icon="chevron-right" @click="paginate('next')"/>
							</div>
						</nav>

					</div>
				</div>
			</template>
		</div>
		<div class="pt-3">
			<slider :useEmit="true"
					:utility="{ options: {label: 'Poster Quality', min: 0, max: 100, type: '%' }}"
			        :value="downloadVariables.quality"
					@updateValue="downloadVariables.quality = $event"/>
			<tw-button :text="btnText" defaultStyle="gradient" class="w-full ring-offset-primary focus-ring-offset-primary" @click="downloadPoster()"/>
		</div>

		<premium-feature :visible="premiumFeatureVisible"
		                 @close="premiumFeatureVisible = false"/>
	</div>
</template>
<script lang="ts">
import { Options, Inject, mixins } from 'vue-property-decorator';
import { EventBus, EventName } from '@/helpers/event-bus';
import { Services } from "@/mixins/services";
import premiumFeature from "@/components/modals/premium-feature.vue";
import toggle from '@/shared/tw-toggle.vue';
import slider from '@/shared/tw-slider.vue';
import moment from 'moment';
import axios from "axios";

@Options({
	name: 'DesignerDownload',
	components: {
		toggle,
		slider,
		premiumFeature
	},
})
export default class DesignerDownload extends mixins(Services) {
	readonly eventBus: EventBus = EventBus.getInstance();
	@Inject() store;

	searchString: any = '';
	activeSearch = false;
	premiumFeatureVisible = false;

	tabs: any = [
		{
			name: 'Collection',
			current: true,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
		{
			name: 'Movie',
			current: false,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
		{
			name: 'TV',
			current: false,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
	]

	downloadVariables: any = {
		quality: 100
	}
	saveVariables: any = {
		saveToLibrary: false,
		id: null,
		type: '',
		title: '',
	}

	saveEvent(e) {
		if (this.isPremium) {
			this.saveVariables.saveToLibrary = e;
		} else {
			this.saveVariables.saveToLibrary = false;
			this.premiumFeatureVisible = true;
		}
	}
	async downloadPoster() {
		if (this.saveVariables.saveToLibrary && this.saveVariables.id === null) {
			this.notify('danger', 'Save to Library', 'You must search for and select an item');
		} else {
			this.$emit('download', { downloadVariables: this.downloadVariables, saveVariables: this.saveVariables });
		}
	}

	async search () {
		const _searchString = this.searchString.trim().replace(/ /g,"+");
		if (_searchString.length === 0) return;

		this.tabs.forEach((el) => {
			el.page = 1;
			el.results = [];
			el.total_pages = null;
		});

		await this.getDatabaseList('initial', 'Collection');
		await this.getDatabaseList('initial', 'Movie');
		await this.getDatabaseList('initial', 'TV');
		this.activeSearch = true;
	}

	async getDatabaseList(type, format) {
		let $el = this.tabs.find(el => el.name === format);
		await axios.get(`https://api.themoviedb.org/3/search/${format.toLowerCase()}?api_key=121f04161acaada7f621744b0ba5cea9&page=${$el.page}&query=${this.searchString}`).then((res) => {
			if (type === 'initial') {
				$el.total_pages = res.data.total_pages;
				$el.total_results = res.data.total_results;
			}
			$el.results = res.data.results;
		});
	}
	setData(item, type) {
		this.saveVariables.id = item.id;
		this.saveVariables.type = type;
		this.saveVariables.title = type === 'Movie' ? item.title : item.name;
	}
	changeTab(tab) {
		this.tabs.forEach((el) => {
			el.current = false;
		});
		tab.current = true;
	}

	async paginate(direction) {
		let $el = this.tabs.find(el => el.current);
		if (direction === 'next' && $el.page < $el.total_pages) {
			$el.page++;
		} else if (direction === 'back' && $el.page > 1){
			$el.page--;
		}
		await this.getDatabaseList('paging', $el.name);
	}

	clearSearch() {
		this.activeSearch = false;
		this.searchString = '';
		this.tabs.forEach((el) => {
			el.page = 1;
			el.results = [];
			el.total_pages = null;
			el.total_results = 0;
		});
	}

	cleanReleaseDate(item) {
		return moment(item.release_date).format('MM/DD/YYYY');
	}

	get btnText() {
		return this.saveVariables.saveToLibrary ? 'Download & Save' : 'Download';
	}
	get activePage() {
		let $el = this.tabs.find(el => el.current);
		return $el.page;
	}
	get activePageTotal() {
		let $el = this.tabs.find(el => el.current);
		return $el.total_pages;
	}
	get isPremium() {
		return this.store.state.isPremium;
	}
}
</script>


<style scoped lang="scss">
	.datasource-item .item-poster {
		flex: 0 0 26px !important;
		width: 26px;
	}

	html:not(.dark) {
		.datasource-item {
			&.activeId {
				background: linear-gradient(315deg, rgba(255, 184, 140, 0.6) 0%, rgba(229, 101, 144, 0.6) 100%) !important;
			}
			&:hover {
				background: linear-gradient(315deg, rgba(255, 184, 140, 0.3) 0%, rgba(229, 101, 144, 0.3) 100%);
			}
		}
	}
	html.dark {
		.datasource-item {
			&.activeId {
				background: linear-gradient(315deg, rgba(220, 146, 178, 0.6) 0%, rgba(125, 76, 245, 0.6) 100%) !important;
			}
			&:hover {
				background: linear-gradient(315deg, rgba(220, 146, 178, 0.3) 0%, rgba(125, 76, 245, 0.3) 100%);
			}
		}
		.premium {
			border-image-source: linear-gradient(to right, #DC92B2, #7D4CF5);
		}
}
</style>
