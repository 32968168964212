<template>
	<div v-if="firebaseResources" class="font-preloader left-0 absolute">
		<p style="font-family: 'Bebas Neue', sans-serif">Bebas Neue</p>
		<p style="font-family: 'Eighty Cologne', sans-serif">Eighty Cologne</p>
		<p style="font-family: 'Eighty Hong Kong', sans-serif">Eighty Hong Kong</p>
		<p style="font-family: 'HK Grotesk', sans-serif">HK Grotesk</p>
		<p style="font-family: 'HK Grotesk', sans-serif; font-weight: bold">HK Grotesk (Bold)</p>
		<p style="font-family: 'Montserrat', sans-serif">Montserrat</p>
		<p style="font-family: 'Open Sans', sans-serif">Open Sans</p>
		<p style="font-family: 'Open Sans', sans-serif; font-weight: bold">Open Sans (Bold)</p>
		<p style="font-family: 'Poppins', sans-serif">Poppins</p>
		<p style="font-family: 'Source Sans Pro', sans-serif">Source Sans Pro</p>
	</div>
</template>

<script lang="ts">
import {Options, Vue, Inject} from 'vue-property-decorator';

	@Options({
		components: {},
	})
	export default class DesignerFonts extends Vue {
		@Inject() store;

		generateIcon (font) {
			return JSON.parse(`["\\u${font}"]`)[0];
		}
		mounted() {
			const FontFaceObserver = require('fontfaceobserver');
			const bebasNeue = new FontFaceObserver('Bebas Neue').load();
			const eightyCologne = new FontFaceObserver('Eighty Cologne').load();
			const eightyHongKong = new FontFaceObserver('Eighty Hong Kong').load();
			const hkGrotesk = new FontFaceObserver('HK Grotesk').load();
			const montserrat = new FontFaceObserver('Montserrat').load();
			const openSans = new FontFaceObserver('Open Sans').load();
			const poppins = new FontFaceObserver('Poppins').load();
			const sourceSansPro = new FontFaceObserver('Source Sans Pro').load();

			Promise.all([bebasNeue, eightyCologne, eightyHongKong,  hkGrotesk, montserrat, openSans, poppins, sourceSansPro]).then(() => {
				this.$emit('fontsReady');
			});
		}
		get firebaseResources() {
			return this.store.state.firebaseResources;
		}
	}
</script>

<style scoped lang="scss">
	.font-preloader {
		visibility: collapse;
	}
	.fa:before {
		font-weight: 900;
		content: attr(data-title) !important;
	}
</style>
