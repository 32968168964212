import { Vue } from 'vue-property-decorator';
import {EventBus, EventName} from '@/helpers/event-bus';

export class Services extends Vue {
	readonly eventBus: EventBus = EventBus.getInstance();

	notify(variant, title, message, duration = 5) {
		this.eventBus.emit(EventName.ShowNotify, {variant: variant, title: title, message: message, duration: duration});
	}
	valueHandler(utility, value, variables) {
		if (utility.variable) {
			this.eventBus.emit(EventName.UpdateVariable, {variable: utility.variable, value: value});
		} else if (utility.handler) {
			const _handler = (this as { [key: string]: any })[utility.handler] as any;
			_handler(value, variables);
		}
	}
	scaleHandler (data: any, variables: any) {
		this.eventBus.emit(EventName.UpdatePoster, {variable: 'scale', type: variables.image_focus, value: data});
	}
	offsetHandler (data: any, variables: any) {
		this.eventBus.emit(EventName.UpdatePoster, {variable: 'offset', type: variables.image_focus, value: {left: data.x, top: data.y}});
	}
}
