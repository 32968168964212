<template>
	<p id="count" class="background-gradient-r text-transparent! bg-clip-text text-5xl"></p>
</template>

<script lang="ts">
import { Vue, Options, Inject } from 'vue-property-decorator';

@Options({
	name: 'PosterCounter',
	components: {
	},
})
export default class PosterCounter extends Vue {
	@Inject() readonly store!: any;

	mounted() {
		this.easedAnimateScore();
	}
	easedAnimateScore() {
		let interval: any = 5;
		let	finalScore: any = this.firebaseResources.Summaries.total;
		let	score: any = ((finalScore - 100) > 0) ? finalScore - 100 : 0;

		setTimeout(iterate(interval), interval);

		function iterate(interval) {
			score++;
			interval = ( finalScore - score < 50 ) ? ( interval * 1.08 ) : interval;

			const myElement = document.getElementById('count')!;

			myElement.innerHTML = score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

			return function() {
				if (score !== finalScore) {
					setTimeout(iterate(interval), interval);
				}
			};
		}
	}
	get firebaseResources() {
		return this.store.state.firebaseResources;
	}
}
</script>

<style lang="scss" scoped>
span {
	letter-spacing: 1px;
	font-size: .9rem;
	line-height: 2rem;
	display: inline-block;
}
</style>
