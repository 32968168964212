<template>
	<div class="py-1">
		<label v-if="label !== ''" for="input" class="block text-xs text-secondary">{{ label }}</label>
		<div class="mt-1 flex rounded-md " :class="disabled ? 'opacity-40' : 'shadow-sm'">
			<span v-if="trailingIcon !== '' && trailingIconAlignment === 'left'" class="rounded-l-md inline-flex items-center px-2 border border-r-0 text-sm text-white border-primary background-gradient-br" @click="$emit('iconClick')">{{ trailingIcon }}</span>
			<div class="flex-1 min-w-0 block w-full relative">
				<input name="input"
				       v-model="activeValue"
				       v-on:keyup.enter="$emit('enterEvent')"
				       :placeholder="placeholder"
				       :disabled="disabled"
				       :type="type"
				       :class="[{'pr-10 border-red-300! focus:border-red-500 dark:border-red-700! dark:focus:border-red-500 ': invalid}, trailingIcon !== '' ? (trailingIconAlignment === 'right' ? 'rounded-none rounded-l-md' : 'rounded-none rounded-r-md') : 'rounded-md', backgroundColor, borderColor]"
				       class="w-full py-1.5 px-2 border placeholder-gray-300 focus-ring-offset-primary ring-offset-primary text-sm text-primary" />
				<div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<tw-icon v-if="invalid" icon="exclamation-circle" weight="fas" size="" class="text-red-500"/>
				</div>
			</div>
			<span v-if="trailingIcon !== '' && trailingIconAlignment === 'right'" class="rounded-r-md inline-flex items-center px-2 border border-l-0 text-sm text-white border-primary background-gradient-br" @click="$emit('iconClick')">{{ trailingIcon }}</span>
		</div>
		<p v-if="invalid && message !== ''" class="mt-2 text-xs text-red-500" id="email-error">{{message}}</p>
	</div>
</template>

<script lang="ts">
	import { Vue, Options, Prop, Watch } from 'vue-property-decorator';

	@Options({
		name: 'TwInputGroup',
		components: {
		},
	})
	export default class TwInputGroup extends Vue {
		@Prop({default: ''}) label;
		@Prop({default: ''}) placeholder;
		@Prop({default: ''}) value;
		@Prop({default: ''}) message;
		@Prop({default: ''}) trailingIcon;
		@Prop( {default: null}) max;
		@Prop( {default: null}) min;
		@Prop({default: 'border-primary'}) borderColor;
		@Prop({default: 'background-secondary'}) backgroundColor;
		@Prop({default: 'right'}) trailingIconAlignment;
		@Prop({default: 'text'}) type;
		@Prop({default: false}) invalid;
		@Prop({default: false}) disabled;

		activeValue: any = '';

		created() {
			this.activeValue = this.value;
		}
		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.activeValue = this.value;
		}
		@Watch('activeValue')
		async watchActiveValue() {
			if (this.type === 'number') {
				this.activeValue = parseInt(this.activeValue, 10);
				if (this.max && this.activeValue > this.max) {
					this.activeValue = this.max;
				}
				if (this.min && this.activeValue < this.min) {
					this.activeValue = this.min;
				}
			}

			this.$emit('updateValue', this.activeValue);
		}
	}
</script>
