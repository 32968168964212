
import { Options, Inject, mixins } from 'vue-property-decorator';
import { EventBus, EventName } from '@/helpers/event-bus';
import { Services } from "@/mixins/services";
import premiumFeature from "@/components/modals/premium-feature.vue";
import toggle from '@/shared/tw-toggle.vue';
import slider from '@/shared/tw-slider.vue';
import moment from 'moment';
import axios from "axios";

@Options({
	name: 'DesignerDownload',
	components: {
		toggle,
		slider,
		premiumFeature
	},
})
export default class DesignerDownload extends mixins(Services) {
	readonly eventBus: EventBus = EventBus.getInstance();
	@Inject() store;

	searchString: any = '';
	activeSearch = false;
	premiumFeatureVisible = false;

	tabs: any = [
		{
			name: 'Collection',
			current: true,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
		{
			name: 'Movie',
			current: false,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
		{
			name: 'TV',
			current: false,
			page: 1,
			results: [],
			total_pages: null,
			total_results: 0,
		},
	]

	downloadVariables: any = {
		quality: 100
	}
	saveVariables: any = {
		saveToLibrary: false,
		id: null,
		type: '',
		title: '',
	}

	saveEvent(e) {
		if (this.isPremium) {
			this.saveVariables.saveToLibrary = e;
		} else {
			this.saveVariables.saveToLibrary = false;
			this.premiumFeatureVisible = true;
		}
	}
	async downloadPoster() {
		if (this.saveVariables.saveToLibrary && this.saveVariables.id === null) {
			this.notify('danger', 'Save to Library', 'You must search for and select an item');
		} else {
			this.$emit('download', { downloadVariables: this.downloadVariables, saveVariables: this.saveVariables });
		}
	}

	async search () {
		const _searchString = this.searchString.trim().replace(/ /g,"+");
		if (_searchString.length === 0) return;

		this.tabs.forEach((el) => {
			el.page = 1;
			el.results = [];
			el.total_pages = null;
		});

		await this.getDatabaseList('initial', 'Collection');
		await this.getDatabaseList('initial', 'Movie');
		await this.getDatabaseList('initial', 'TV');
		this.activeSearch = true;
	}

	async getDatabaseList(type, format) {
		let $el = this.tabs.find(el => el.name === format);
		await axios.get(`https://api.themoviedb.org/3/search/${format.toLowerCase()}?api_key=121f04161acaada7f621744b0ba5cea9&page=${$el.page}&query=${this.searchString}`).then((res) => {
			if (type === 'initial') {
				$el.total_pages = res.data.total_pages;
				$el.total_results = res.data.total_results;
			}
			$el.results = res.data.results;
		});
	}
	setData(item, type) {
		this.saveVariables.id = item.id;
		this.saveVariables.type = type;
		this.saveVariables.title = type === 'Movie' ? item.title : item.name;
	}
	changeTab(tab) {
		this.tabs.forEach((el) => {
			el.current = false;
		});
		tab.current = true;
	}

	async paginate(direction) {
		let $el = this.tabs.find(el => el.current);
		if (direction === 'next' && $el.page < $el.total_pages) {
			$el.page++;
		} else if (direction === 'back' && $el.page > 1){
			$el.page--;
		}
		await this.getDatabaseList('paging', $el.name);
	}

	clearSearch() {
		this.activeSearch = false;
		this.searchString = '';
		this.tabs.forEach((el) => {
			el.page = 1;
			el.results = [];
			el.total_pages = null;
			el.total_results = 0;
		});
	}

	cleanReleaseDate(item) {
		return moment(item.release_date).format('MM/DD/YYYY');
	}

	get btnText() {
		return this.saveVariables.saveToLibrary ? 'Download & Save' : 'Download';
	}
	get activePage() {
		let $el = this.tabs.find(el => el.current);
		return $el.page;
	}
	get activePageTotal() {
		let $el = this.tabs.find(el => el.current);
		return $el.total_pages;
	}
	get isPremium() {
		return this.store.state.isPremium;
	}
}
