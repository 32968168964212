import {Vue, Options} from 'vue-property-decorator';
import store from "@/store";

@Options({
	components: {
	},
})
export default class Functions extends Vue {
	store = store;

	write_imageHelper (ctx: any, offsetX: any, offsetY: any, transition: any, special: any) {
		ctx.fillStyle = this.theme === 'light' ? '#232930' : '#CCCCCC';
		ctx.font = '60px Bebas Neue';
		ctx.textAlign = 'center';
		if (!special) {
			ctx.fillText('DRAG IMAGE', offsetX, (offsetY - transition));
			ctx.font = '150px Bebas Neue';
			ctx.fillText('HERE', offsetX, (offsetY + transition));
		} else {
			if (special === 'collection.1' || special === 'tv.1') {
				ctx.fillText("SELECT 'POSTER'", offsetX, (offsetY - transition));
				ctx.fillText('AND DRAG HERE', offsetX, (offsetY + transition));
			} else if (special === 'dual.1') {
				ctx.font = '55px Bebas Neue';
				ctx.fillText("SELECT 'UPPER' AND DRAG HERE", offsetX, offsetY);
			} else if (special === 'dual.2') {
				ctx.font = '55px Bebas Neue';
				ctx.fillText("SELECT 'LOWER' AND DRAG HERE", offsetX, offsetY);
			} else if (special === 'dual.3' || special === 'collection.2') {
				ctx.font = '55px Bebas Neue';
				ctx.fillText("SELECT 'LOGO' AND DRAG HERE", offsetX, offsetY);
			} else if (special === 'tv.2' || special === 'alvord.1') {
				ctx.textBaseline = 'middle';
				ctx.font = '55px Bebas Neue';
				ctx.fillText("SELECT 'LOGO' AND DRAG HERE", offsetX, offsetY);
			} else if (special === 'tv.3') {
				ctx.textBaseline = 'middle';
				ctx.font = '40px Bebas Neue';
				ctx.textAlign = 'left';
				ctx.fillText("SELECT 'NETWORK' AND DRAG HERE", offsetX, offsetY);
			} else if (special === 'ripples') {
				ctx.fillStyle = 'rgba(255, 255, 255, .6)';
				ctx.fillText('DRAG IMAGE HERE', offsetX, (offsetY - transition));
				ctx.fillText('OR SELECT ICON', offsetX, (offsetY + transition));
			} else if (special === 'networks') {
				ctx.fillStyle = 'rgba(255, 255, 255, .6)';
				ctx.fillText('DRAG IMAGE HERE', offsetX, (offsetY - transition));
				ctx.fillText('OR SELECT LOGO', offsetX, (offsetY + transition));
			}
		}
	}
	write_textLine (ctx, text, xOffset, yOffset, fontSize, fontFamily, fontColor, fontWeight, letterSpacing, textAlign, textBaseline) {
		ctx.fillStyle = fontColor;
		ctx.textAlign = textAlign;
		ctx.textBaseline = textBaseline;
		ctx.shadowBlur = 0;
		ctx.shadowBlur = 0;

		if (letterSpacing !== 'normal') {
			let newText = '';
			for (let i = 0; i < text.length; i++) {
				let char = text.charAt(i);
				for (let j = 0; j < letterSpacing; j++) {
					char += ' ';
				}
				newText += char;
			}
			text = newText.trim();
		}
		ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
		ctx.fillText(text, xOffset, yOffset);
	}
	write_textMulticolor(ctx, text1, text2, xOffset, yOffset, fontSize, fontFamily, fontColor1, fontColor2, fontWeight, textBaseline) {
		ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
		ctx.textBaseline = textBaseline;

		ctx.textAlign = 'right';
		ctx.fillStyle = fontColor1;
		ctx.fillText(text1, xOffset, yOffset);

		ctx.textAlign = 'left';
		ctx.fillStyle = fontColor2;
		ctx.fillText(text2, xOffset, yOffset);

	}
	write_textWrap (ctx, text, xOffset, yOffset, fontSize, fontFamily, fontWeight, fontColor, lineHeight, letterSpacing, textAlign, textBaseline, maxWidth, writeDirection) {
		ctx.fillStyle = fontColor;
		ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}, sans-serif`;
		ctx.textAlign = textAlign;
		ctx.textBaseline = textBaseline;
		const words = text.trim().split(' ');
		let line: any = '';
		let lineCount = 1;
		const lines: any = [];
		let y = yOffset;
		for (let n = 0; n < words.length; n++) {
			const testLine = line + ' ' + words[n];
			const testWidth = ctx.measureText(testLine.trim()).width;
			if (testWidth > maxWidth && n > 0 && lineCount <= 1) {
				lineCount += 1;
				lines.push(line);
				line = words[n];
			} else {
				line = testLine;
			}
		}
		lines.push(line);
		if (writeDirection === 'forward') {
			lines.forEach(line => {
				this.write_textLine(ctx, line.trim(), xOffset, y, fontSize, fontFamily, fontColor, fontWeight, letterSpacing, textAlign, textBaseline);
				y += lineHeight;
			});
		} else if (writeDirection === 'reverse') {
			lines.reverse().forEach(line => {
				this.write_textLine(ctx, line.trim(), xOffset, y, fontSize, fontFamily, fontColor, fontWeight, letterSpacing, textAlign, textBaseline);
				y -= lineHeight;
			});
		}
	}
	draw_Image (ctx: any, poster: any, focus: any, variables: any) {
		const imageType = variables.image_focus;

		variables.images[focus].scale = poster[`${imageType}FileDetails`].scale;
		variables.images[focus].offsetX = poster[`${imageType}FileDetails`].offset.left;
		variables.images[focus].offsetY = poster[`${imageType}FileDetails`].offset.top;

		const imgRatio = variables.images[focus].scale / 100;
		const calcWidth = poster[`${imageType}FileDetails`].width * imgRatio;
		const calcHeight = poster[`${imageType}FileDetails`].height * imgRatio;

		ctx.drawImage(poster[`${imageType}FileSource`], variables.images[focus].offsetX, variables.images[focus].offsetY, calcWidth, calcHeight);
	}
	draw_rectangle (ctx, offsetX, offsetY, width, height, color, shadow, returnVal) {
		if (shadow) {
			ctx.shadowBlur = shadow;
		}
		ctx.fillStyle = color;
		ctx.fillRect(offsetX, offsetY, width, height);
		return returnVal;
	}
	draw_rectangleCornered (ctx, startX, startY, rectWidth, rectHeight, cornerRadius, color) {
		const calcWidth = rectWidth + startX;
		const calcHeight = rectHeight + startY;
		ctx.fillStyle = color;
		ctx.beginPath();
		ctx.moveTo(startX + cornerRadius, startY);
		ctx.lineTo(calcWidth - cornerRadius, startY);
		ctx.arcTo(calcWidth, startY, calcWidth, startY + cornerRadius, cornerRadius);
		ctx.lineTo(calcWidth, startY + cornerRadius);
		ctx.lineTo(calcWidth, calcHeight - cornerRadius);
		ctx.arcTo(calcWidth, calcHeight, calcWidth - cornerRadius, calcHeight, cornerRadius);
		ctx.lineTo(calcWidth - cornerRadius, calcHeight);
		ctx.lineTo(startX + cornerRadius, calcHeight);
		ctx.arcTo(startX, calcHeight, startX, calcHeight - cornerRadius, cornerRadius);
		ctx.lineTo(startX, calcHeight - cornerRadius);
		ctx.lineTo(startX, startY + cornerRadius);
		ctx.arcTo(startX, startY, startX + cornerRadius, startY, cornerRadius);
		ctx.closePath();
		ctx.fill();
	}
	draw_rectangleGradient (ctx, horizontalOffset, verticalOffset, poster, color, opacity, direction) {
		const grd = ctx.createLinearGradient(0, 0, 0, poster.baseHeight);

		if (direction) {
			const gradientStart: any = this.color_hexToRgba(color, opacity);
			const gradientEnd: any = this.color_hexToRgba(color, 0);
			const graStrRgba = `rgba(${gradientStart.r}, ${gradientStart.g}, ${gradientStart.b}, ${gradientStart.a})`;
			const graEndRgba = `rgba(${gradientEnd.r}, ${gradientEnd.g}, ${gradientEnd.b}, ${gradientEnd.a})`;
			if (direction === 'up') {
				grd.addColorStop(0, graEndRgba);
				grd.addColorStop(0.8, graStrRgba);
			} else {
				grd.addColorStop(0.2, graStrRgba);
				grd.addColorStop(1, graEndRgba);
			}
		} else {
			const gradientStart: any = this.color_hexToRgba(color, opacity);
			const gradientEnd: any = this.color_hexToRgba(color, opacity);
			const graStrRgba = `rgba(${gradientStart.r}, ${gradientStart.g}, ${gradientStart.b}, ${gradientStart.a})`;
			const graEndRgba = `rgba(${gradientEnd.r}, ${gradientEnd.g}, ${gradientEnd.b}, ${gradientEnd.a})`;
			grd.addColorStop(0, graStrRgba);
			grd.addColorStop(1, graEndRgba);
		}
		ctx.fillStyle = grd;
		ctx.fillRect(horizontalOffset, verticalOffset, poster.baseWidth, poster.baseHeight);
	}
	draw_circle (ctx, x, y, r, startAngle, width, color) {
		ctx.beginPath();
		ctx.arc(x, y, r, startAngle, 2 * Math.PI, false);
		ctx.closePath();
		ctx.lineWidth = width;
		ctx.strokeStyle = color;
		ctx.stroke();
	}
	draw_image (ctx, poster, focus, variables) {
		variables.images[focus].scale = poster[`${focus}FileDetails`].scale;
		variables.images[focus].offsetX = poster[`${focus}FileDetails`].offset.left;
		variables.images[focus].offsetY = poster[`${focus}FileDetails`].offset.top;

		const imgRatio = variables.images[focus].scale / 100;
		const calcWidth = poster[`${focus}FileDetails`].width * imgRatio;
		const calcHeight = poster[`${focus}FileDetails`].height * imgRatio;
		ctx.drawImage(poster[`${focus}FileSource`], variables.images[focus].offsetX, variables.images[focus].offsetY, calcWidth, calcHeight);
	}

	draw_imageBlurredBackground (ctx, poster, focus, blur) {
		ctx.filter = `blur(${blur}px)`;
		const imgRatio = 300 / 100;
		const calcWidth = poster[`${focus}FileDetails`].width * imgRatio;
		const calcHeight = poster[`${focus}FileDetails`].height * imgRatio;
		ctx.drawImage(poster[`${focus}FileSource`], -poster[`${focus}FileDetails`].width, -poster[`${focus}FileDetails`].height, calcWidth, calcHeight);
		ctx.filter = 'blur(0px)';
	}
	draw_imageWithBounds (ctx, poster, focus, variables, shadow) {
		if (shadow) {
			ctx.shadowBlur = shadow;
		}
		variables.images[focus].scale = poster[`${focus}FileDetails`].scale;
		variables.images[focus].offsetX = poster[`${focus}FileDetails`].offset.left;
		variables.images[focus].offsetY = poster[`${focus}FileDetails`].offset.top;

		const imgRatio = variables.images[focus].scale / 100;
		const calcWidth = poster[`${focus}FileSource`].naturalWidth / imgRatio;
		const calcHeight = poster[`${focus}FileSource`].naturalHeight / imgRatio;

		const ratioW = calcWidth / variables.images[focus].boundW;
		const ratioH = calcHeight / variables.images[focus].boundH;

		let clipW = 0;
		let clipH = 0;
		if (calcWidth > calcHeight) {
			clipW = variables.images[focus].boundW * ratioH;
			clipH = calcHeight;
		} else {
			clipW = calcWidth;
			clipH = variables.images[focus].boundH * ratioW;
		}

		ctx.drawImage(poster[`${focus}FileSource`], variables.images[focus].offsetX * -1, variables.images[focus].offsetY * -1, clipW, clipH, variables.images[focus].boundX, variables.images[focus].boundY, variables.images[focus].boundW, variables.images[focus].boundH);
		ctx.shadowBlur = 0;
	}
	draw_gridMap (ctx) {
		const vertX = [100, 200, 300, 400, 500, 600, 700, 800, 900];
		const vertY = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400];
		ctx.lineWidth = 1;
		ctx.strokeStyle = '#FFFFFF';
		vertX.forEach((x) => {
			ctx.beginPath();
			ctx.moveTo(x, 0);
			ctx.lineTo(x, 1500);
			ctx.closePath();
			ctx.stroke();
		});
		vertY.forEach((y) => {
			ctx.beginPath();
			ctx.moveTo(0, y);
			ctx.lineTo(1000, y);
			ctx.closePath();
			ctx.stroke();
		});
	}
	color_hexToRgb (hex) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
	color_hexToRgba (hex, opacity) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
			a: opacity / 100,
		} : null;
	}
	color_hexToLuminance (hex) {
		const rgb: any = this.color_hexToRgb(hex);
		return 0.2126 * this.calc_luminanceFix(rgb.r) + 0.7152 * this.calc_luminanceFix(rgb.g) + 0.0722 * this.calc_luminanceFix(rgb.b);
	}
	color_rgbToHsl (r, g, b) {
		r /= 255;
		g /= 255;
		b /= 255;

		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		const l = (max + min) / 2;
		let h;
		let s;

		if (max === min) {
			h = s = 0; // achromatic
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

			switch (max) {
				case r: h = (g - b) / d + (g < b ? 6 : 0); break;
				case g: h = (b - r) / d + 2; break;
				case b: h = (r - g) / d + 4; break;
			}

			h /= 6;
		}

		return {
			h: h,
			s: s,
			l: l
		};
	}
	calc_luminanceFix (color) {
		const c = color / 255;
		return c <= 0.03928 ? c / 12.92 : Math.pow(((c + 0.055) / 1.055), 2.4);
	}
	calc_singleLineHeight (ctx, fontSize, fontFamily) {
		ctx.textAlign = 'left';
		ctx.textBaseline = 'middle';
		ctx.font = `${fontSize}px ${fontFamily}`;
		const el = document.createElement('span');
		this.$el.children[0].append(el);
		el.innerText = 'M';
		el.style.font = `${fontSize}px ${fontFamily}`;
		el.className = 'heightCalc';
		const h = el.getBoundingClientRect().height;
		el.remove();
		return h;
	}
	calc_alignmentOffset (alignment) {
		if (alignment === 'left') {
			return 80;
		} else if (alignment === 'center') {
			return 500;
		} else if (alignment === 'right') {
			return 920;
		}
	}
	calc_multipleLineHeight (ctx, fontSize, fontFamily, fontText, maxWidth, lineHeight) {
		ctx.textAlign = 'left';
		ctx.textBaseline = 'middle';
		ctx.font = `${fontSize}px ${fontFamily}`;
		const el = document.createElement('span');
		this.$el.children[0].append(el);
		el.innerText = fontText;
		el.style.font = `${fontSize}px ${fontFamily}`;
		el.style.display = 'inline-block';
		el.style.maxWidth = `${maxWidth}px`;
		el.className = 'heightCalc';
		const w = el.getBoundingClientRect().width;
		if (w >= maxWidth) {
			el.className = 'heightCalc wrap';
		}
		let h = el.getBoundingClientRect().height;
		el.remove();
		if (lineHeight && h > lineHeight) {
			h = lineHeight * 2;
		}
		return {
			height: h,
			width: w,
		};
	}

	get theme() {
		return this.store.state.theme;
	}
}
