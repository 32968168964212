<template>
	<div class="h-screen w-screen bg-gray-950 overflow-hidden">
		<designer-fonts @fontsReady="fontsReady = true;"/>
		<TransitionRoot
			:show="activeSignIn"
			enter="transition-opacity duration-75"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="transition-opacity duration-150"
			leave-from="opacity-100"
			leave-to="opacity-0">
			<router-view v-if="activeSignIn"/>
		</TransitionRoot>

		<TransitionRoot
			:show="(!isSignedIn || !fontsReady) && !activeSignIn"
			enter="transition-opacity duration-75"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="transition-opacity duration-150"
			leave-from="opacity-100"
			leave-to="opacity-0"
			class="h-full w-full">
			<div v-if="(!isSignedIn || !fontsReady) && !activeSignIn" class="h-full flex justify-center relative">
				<div class="cut absolute">
					<div class="inner-upper absolute">
						<div class="overlay absolute">
							<svg class="absolute" viewBox="0 0 4 4">
								<path d="M3.12,3.12l-3.12,0l0,-3.12c0,0.867 0.699,1.571 1.56,1.571c0.861,0 1.56,-0.704 1.56,-1.571l0,3.12Z"/>
							</svg>
						</div>
					</div>
					<div class="inner-lower absolute">
						<div class="overlay absolute">
							<div class="cut absolute"></div>
						</div>
					</div>
				</div>
				<svg class="infinite" viewBox="-2000 -1000 4000 2000">
					<path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
					<use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use>
				</svg>
			</div>
		</TransitionRoot>
		<TransitionRoot
			:show="isSignedIn && fontsReady && !activeSignIn"
			enter="transition-opacity duration-75"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="transition-opacity duration-150"
			leave-from="opacity-100"
			leave-to="opacity-0">
			<navigation v-if="isSignedIn && fontsReady && !activeSignIn">
				<template v-slot:content>
					<router-view/>
				</template>
			</navigation>
		</TransitionRoot>

		<!-- Global notification live region, render this permanently at the end of the document -->
		<div aria-live="assertive" class="fixed inset-0 flex items-end justify-center p-4 pointer-events-none sm:justify-end z-50">
			<tw-notify/>
		</div>
	</div>
</template>

<script lang="ts">
	import { Options, Provide, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import navigation from '@/components/navigation/navigation.vue';
	import designerFonts from '@/components/custom/designer.fonts.vue';
	import firebase from './firebase/config';
	import firebaseAuthentication from './firebase/authentication';
	import store from "@/store";
	import moment from 'moment';

	@Options({
		name: 'App',
		components: {
			navigation,
			designerFonts
		},
	})
	export default class App extends mixins(Services) {
		@Provide() store = store;
		@Provide() firebase = firebase;
		@Provide() firebaseAuthentication = firebaseAuthentication;

		fontsReady = false;

		async created() {
			this.setWindowWidth();
			this.setWindowHeight();
			window.addEventListener("resize", this.setWindowWidth);
			window.addEventListener("resize", this.setWindowHeight);
		}

		async mounted() {
			// TODO FIX MOMENT WARNINGS
			moment.suppressDeprecationWarnings = true;
			await this.checkInitialAuth();
		}

		async checkInitialAuth() {
			firebase.auth().onAuthStateChanged(async (user: any) => {
				if (!user || !user.emailVerified) return;

				const timeCheck = localStorage.getItem('timestamp');
				if (!timeCheck || moment(moment().format('MM/DD/YYYY')).startOf('month').isAfter(timeCheck)) {
					await firebase.functions().httpsCallable('getCustomer')({ providerData: user.providerData, displayName: user.displayName }).then(async (customerResult) => {
						if (!('error' in customerResult.data)) {
							console.log('NEW CUSTOMER DATABASE CALL');
							await firebase.functions().httpsCallable('getResources')().then(async (resourcesResult) => {
								store.commit('setFirebaseResources', resourcesResult.data);
							}).catch(error => console.log(error));

							await this.defineCustomClaimRole(customerResult.data);
							customerResult.data.trial = await this.defineTrialSettings(customerResult.data);
							store.commit('setFirebaseCustomer', customerResult.data);
						} else {
							this.notify('danger', customerResult.data.error, 'Authentication');
						}
					});
				} else {
					let _resources: any = localStorage.getItem('resources');
					let _customer: any = localStorage.getItem('customer');
					_resources = JSON.parse(_resources);
					_customer = JSON.parse(_customer);

					await this.defineCustomClaimRole(_customer);
					_customer.trial = await this.defineTrialSettings(_customer);
					store.commit('setFirebaseResources', _resources);
					store.commit('setFirebaseCustomer', _customer);
				}

				await this.setTheme();
				store.commit('setIsSignedIn', true);
			});

		}

		async defineCustomClaimRole(data: any) {
			await firebase.auth().currentUser?.getIdToken(true);
			const decodedToken = await firebase.auth().currentUser?.getIdTokenResult();
			const isPremium = decodedToken?.claims.stripeRole === 'daily';

			if (data.isPremium !== isPremium) {
				await firebase.functions().httpsCallable('updatePremiumStatus')({isPremium: isPremium});
				await this.checkInitialAuth();
			}
			this.store.commit('setIsPremium', isPremium);
		}

		async defineTrialSettings(data: any) {
			if (moment(moment().format('MM/DD/YYYY')).isAfter(data.trial.month)) {
				console.log('defineTrialSettings');
				return await firebase.functions().httpsCallable('resetMonthlyDownloadLimit')().then(async (downloadResult) => {
					return downloadResult.data;
				}).catch(error => console.log(error));
			} else {
				return data.trial;
			}
		}

		setTheme() {
			if (this.firebaseCustomer['theme'] === 'dark') {
				document.documentElement.classList.add('dark');
			}
			this.store.commit("setTheme", this.firebaseCustomer['theme']);
		}
		setWindowWidth() {
			this.store.commit("setWindowWidth", window.innerWidth);
			this.store.commit("setBreakpoint", this.breakpoint);
		}
		setWindowHeight() {
			this.store.commit("setWindowHeight", window.innerHeight);
		}
		beforeUnmount() {
			window.removeEventListener("resize", this.setWindowWidth);
			window.removeEventListener("resize", this.setWindowHeight);
		}

		get breakpoint() {
			if (this.windowWidth >= 1536) {
				return '2xl';
			} else if (this.windowWidth >= 1280) {
				return 'xl';
			} else if (this.windowWidth >= 1024) {
				return 'lg';
			} else if (this.windowWidth >= 768) {
				return 'md';
			} else if (this.windowWidth >= 640) {
				return 'sm';
			} else {
				return 'xs';
			}
		}
		get windowWidth() {
			return this.store.state.windowWidth;
		}
		get isPremium() {
			return this.store.state.isPremium;
		}
		get isSignedIn() {
			// return false;
			return this.store.state.isSignedIn;
		}
		get firebaseResources() {
			return this.store.state.firebaseResources;
		}
		get firebaseCustomer() {
			return this.store.state.firebaseCustomer;
		}
		get activeSignIn () {
			return this.$route.path.includes('sign-in');
		}
	}
</script>

<style lang="scss" scoped>
	.cut {
		top: 50%;
		.inner-upper {
			$offset: -8px;
			width: 13px;
			height: 13px;
			top: $offset;
			left: $offset;
			.overlay {
				$offset: -8px;
				transform: rotate(-45deg);
				width: 13px;
				height: 13px;
				top: $offset;
				left: $offset;
				svg {
					width: 17px;
					fill: #0e131a;
				}
			}
		}
		.inner-lower {
			$offset: -6px;
			width: 13px;
			height: 13px;
			top: $offset;
			left: $offset;
			.overlay {
				width: 13px;
				height: 13px;
				top:  8px;
				left:  8px;
				transform: rotate(-45deg);
				.cut {
					background: #0e131a;
					width: 13px;
					height: 2px;
					margin-top: -6px;
				}
			}
		}
	}
	svg.infinite {
		max-width: 200px;
		background: transparent;
		fill: none;
		stroke: #232c3b;
		stroke-linecap: round;
		stroke-width: 8%
	}

	use {
		stroke: #7D4CF5;
		animation: a 2s linear infinite
	}

	@keyframes a { to { stroke-dashoffset: 0 } }
</style>
