
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Apostle",
		components: {
			designer
		},
	})
	export default class Apostle extends mixins(Functions) {
		$refs: any;

		name = 'Apostle';
		imported: any = {};
		static = {};
		default = {
			text_primary: 'FEATURED MOVIES',
			text_fontFamily: 'Open Sans',
			text_primarySize: 150,
			text_alignment: 'left',
			colors_text_primary: '#FFFFFF',
			colors_base: '#0096DE',
			overlay_color: '#000000',
			overlay_opacity: 25,
			overlay_direction: 'down',
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
			}
		};
		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Collection',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 50,
							max: 200,
							type: "px",
						}
					},
					{
						variable: 'text_alignment',
						comp: 'button-group',
						options: {
							mod: 'Alignment',
							label: 'Alignment',
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Primary Font',
						}
					},
					{
						variable: 'colors_base',
						comp: 'color-swatches',
						options: {
							label: 'Base Background',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_direction',
						comp: 'button-group',
						options: {
							mod: 'Opacity',
							label: 'Direction',
							count: 2,
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		];

		drawer (ctx: any, variables: any, poster: any) {
			if (poster.primaryFileSource) {
				this.draw_image(ctx, poster, 'primary', variables);
			} else {
				this.write_imageHelper(ctx, 500, 450, 65, null);
			}

			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, variables.overlay_direction);

			ctx.beginPath();
			ctx.moveTo(0, 1024);
			ctx.lineTo(1000, 600);
			ctx.lineTo(1000, 1500);
			ctx.lineTo(0, 1500);
			ctx.lineTo(0, 1024);
			ctx.closePath();
			ctx.fillStyle = variables.colors_base;
			ctx.fill();

			const lineSizePrimarySingle = this.calc_singleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily);
			this.write_textWrap(ctx, variables.text_primary, this.calc_alignmentOffset(variables.text_alignment), 1380, variables.text_primarySize, variables.text_fontFamily, 'normal', variables.colors_text_primary, lineSizePrimarySingle, 'normal', variables.text_alignment, 'middle', 800, 'reverse');

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
