<template>
	<div v-if="!isDropdown" class="w-48">
		<div v-for="control in controls"
		     :key="'split-control' + control.index"
		     :class="[{'bg-gray-50 dark:bg-gray-950 border-gray-200 dark:border-gray-800 rounded-l-md active': isActive(control.index)}]"
		     class="w-full z-10 flex justify-between relative px-3 cursor-pointer h-10 border border-r-0 border-transparent option-selector relative overflow-hidden"
		     @click="setActiveIndex(control.index)">
			<div class="absolute inset-0" v-if="isActive(control.index)" :class="theme === 'light' ? 'light-partial-gradient' : 'dark-partial-gradient'"/>
			<div class="flex">
				<tw-icon weight="fas"
				         :icon="iconLookup(control.name)"
				         class="icon pr-4 text-gray-300 dark:text-gray-500"
				         :class="[{'background-gradient-b text-transparent! bg-clip-text': isActive(control.index)}]"></tw-icon>
				<p class="leading-9 text-sm text-gray-300 dark:text-gray-500" :class="{'text-gray-900! dark:text-gray-50!': isActive(control.index)}">{{control.name}}</p>
			</div>
			<tw-icon v-if="!isActive(control.index)" class="text-gray-300 dark:text-gray-500" weight="fas" icon="chevron-right"/>
		</div>
	</div>
	<tw-select-menu v-else
	                :options="controls"
	                label="Navigation"
	                modifier="check"
	                @updateValue="updateSelectNavigation($event)"/>
</template>

<script lang="ts">
import { Options, Vue, Inject, Watch, Prop } from 'vue-property-decorator';

@Options({
	name: "DesignerControls",
	components: {
	},
})
export default class DesignerControls extends Vue {
	@Inject() readonly store!: any;
	@Inject() readonly firebase!: any;

	@Prop() controls: any;
	@Prop() activeControl: any;
	@Prop() isDropdown: any;

	additionalControls: any = [
		{ name: 'Presets' },
		{ name: 'Save/Download' }
	];

	updateSelectNavigation(e) {
		this.selectOption(this.controls.find(el => el.name === e.name));
	}
	selectOption(item) {
		this.$emit('setActive', item.index);
	}

	setActiveIndex(index) {
		this.$emit('setActive', index);
	}
	isActive(index) {
		return index === this.activeControl;
	}
	iconLookup(title: any) {
		if (title === 'Colors') {
			return 'swatchbook';
		} else if (title === 'Extras') {
			return 'boxes-alt';
		} else if (title === 'Image') {
			return 'image';
		} else if (title === 'Overlay') {
			return 'layer-group';
		} else if (title === 'Text') {
			return 'quote-left';
		} else if (title === 'Presets') {
			return 'filter';
		} else if (title === 'Save/Download') {
			return 'save';
		} else {
			return 'question-circle';
		}
	}

	get theme() {
		return this.store.state.theme;
	}
}
</script>


<style scoped lang="scss">
</style>
