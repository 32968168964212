
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Mohegan",
		components: {
			designer
		},
	})
	export default class Mohegan extends mixins(Functions) {
		$refs: any;

		name = 'Mohegan';
		imported: any = {};
		static: any = {
			text_secondarySize: 58,
			height_topBar: 205,
			height_bottomBar: 535,
			padding_right: 30,
			maxWidth: 940,
		};
		default: any = {
			text_primary: 'COLLECTION NAME',
			text_secondary: 'COLLECTION',
			text_fontFamily: 'Bebas Neue',
			text_primarySize: 100,
			colors_background: '#232930',
			colors_highlightBar: '#757575',
			colors_text_primary: '#FF0018',
			colors_text_secondary: '#FFFFFF',
			extras_height_highlightBar: 30,
			extras_show_the: true,
			extras_show_collection: true,
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
			}
		}

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_primary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Collection Name',
						}
					},
					{
						variable: 'text_secondary',
						comp: 'input-group',
						options: {
							mod: 'text',
							label: 'Secondary Text',
						}
					},
					{
						variable: 'text_fontFamily',
						comp: 'select-menu',
						options: {
							mod: 'FontFamily',
							label: 'Font Family',
						}
					},
					{
						variable: 'text_primarySize',
						comp: 'slider',
						options: {
							label: 'Collection Font Size',
							min: 58,
							max: 163,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_highlightBar',
						comp: 'color-swatches',
						options: {
							label: 'Highlight Bars',
						}
					},
					{
						variable: 'colors_text_primary',
						comp: 'color-swatches',
						options: {
							label: 'Collection Font',
						}
					},
					{
						variable: 'colors_text_secondary',
						comp: 'color-swatches',
						options: {
							label: 'Secondary Font',
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_height_highlightBar',
						comp: 'slider',
						options: {
							label: 'Highlight Bar Height',
							min: 10,
							max: 50,
							type: "px",
						}
					},
					{
						variable: 'extras_show_the',
						comp: 'toggle',
						options: {
							name: 'the',
							label: 'Show "THE" Text',
						}
					},
					{
						variable: 'extras_show_collection',
						comp: 'toggle',
						options: {
							name: 'collection',
							label: 'Show "COLLECTION" Text',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		];
		drawer (ctx: any, variables: any, poster: any) {
			poster.primaryFileSource ? this.draw_image(ctx, poster, 'primary', variables) : this.write_imageHelper(ctx, 1000 / 2, 585, 65, null);

			this.draw_rectangle(ctx, 0, 0, poster.baseWidth, this.static.height_topBar, variables.colors_background, null, null);
			this.draw_rectangle(ctx, 0, 203, poster.baseWidth, variables.extras_height_highlightBar, variables.colors_highlightBar, null, null);
			this.draw_rectangle(ctx, 0, 967 - variables.extras_height_highlightBar, poster.baseWidth, variables.extras_height_highlightBar, variables.colors_highlightBar, null, null);
			this.draw_rectangle(ctx, 0, 967, poster.baseWidth, this.static.height_bottomBar, variables.colors_background, null, null);

			const lineSizePrimarySingle = this.calc_singleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily);
			const lineSizePrimary = this.calc_multipleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily, variables.text_primary, (this.static.maxWidth - this.static.padding_right), lineSizePrimarySingle);
			const lineSizeSecondarySingle = this.calc_singleLineHeight(ctx, variables.text_primarySize, variables.text_fontFamily);
			const lineSizeSecondary = this.calc_multipleLineHeight(ctx, this.static.text_secondarySize, variables.text_fontFamily, 'THE', this.static.maxWidth, lineSizeSecondarySingle);

			const topLineOffset = (267 - (lineSizePrimary.height / 3) - lineSizeSecondary.height) + 967;
			let bottomLineOffset = (267 + (lineSizePrimary.height / 3) + lineSizeSecondary.height) + 967;

			let primaryTextOffset = 1234;
			if (lineSizePrimary.height > lineSizePrimarySingle) {
				primaryTextOffset -= (lineSizePrimary.height / 5);
				bottomLineOffset += 20;
			}

			if (variables.extras_show_the) {
				this.write_textLine(ctx, 'THE', (poster.baseWidth - this.static.padding_right), topLineOffset, this.static.text_secondarySize, variables.text_fontFamily, variables.colors_text_secondary, 'normal', 'normal', 'right', 'middle');
			}
			if (variables.extras_show_collection) {
				this.write_textLine(ctx, variables.text_secondary, (poster.baseWidth - this.static.padding_right), bottomLineOffset, this.static.text_secondarySize, variables.text_fontFamily, variables.colors_text_secondary, 'normal', 'normal', 'right', 'middle');
			}
			this.write_textWrap(ctx, variables.text_primary, (poster.baseWidth - this.static.padding_right), primaryTextOffset, variables.text_primarySize, variables.text_fontFamily, 'normal', variables.colors_text_primary, lineSizePrimarySingle, 'normal', 'right', 'middle', this.static.maxWidth, 'forward');

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
