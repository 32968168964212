<template>
	<div class="flex flex-col h-full w-full">
<!--		<div class="pb-4 w-full absolute z-10 bg-gradient-to-b from-gray-50 via-gray-50 dark:from-gray-950 dark:via-gray-950 to-transparent h-12">-->
<!--			<span class="text-xl my-auto text-gray-500 dark:text-gray-300 pl-10 md:pl-0">Dashboard</span>-->
<!--		</div>-->

		<div class="flex-1 pt-6">
			<div class="py-12">
				<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div class="lg:text-center">
						<h2 class="text-base text-brand-light-to dark:text-brand-dark-to font-semibold tracking-wide uppercase">Welcome</h2>
						<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-primary sm:text-4xl">A better way to create posters</p>
						<p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Choose from multiple different templates that fit your style, with thousands of different combinations to make something truly unique.</p>
					</div>

					<div class="mt-10">
						<dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
							<div v-for="feature in features" :key="feature.name" class="relative">
								<dt>
									<div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-brand-light-to dark:bg-brand-dark-to text-primary">
										<tw-icon :weight="feature.type"
										         :icon="feature.icon"
										         class="text-white"></tw-icon>
									</div>
									<p class="ml-16 text-lg leading-6 font-medium text-primary">{{ feature.name }}</p>
								</dt>
								<dd class="mt-2 ml-16 text-base text-gray-500">
									{{ feature.description }}
								</dd>
							</div>
						</dl>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import { Vue,Options } from 'vue-class-component';

	@Options({
		name: 'Dashboard',
		components: {
		},
	})
	export default class Dashboard extends Vue {
		features = [
			{
				name: 'Rebuilt Designer',
				description:
					'Built from the ground up, new templates and options are built in to every option. To let you truly make your posters your own.',
				icon: 'drafting-compass',
				type: 'fas',
			},
			{
				name: 'Customizable Dashboard',
				description:
					'Still In Progress. With that said once available, you will be able to track downloads, presets, and various statistics',
				icon: 'chart-bar',
				type: 'fas',
			},
			{
				name: 'Premium (Coming Soon)',
				description:
					'We know subscriptions suck. However with a Premium model, we will be able offer saving posters and help us keep the lights on!',
				icon: 'star',
				type: 'fas',
			},
			{
				name: 'Community',
				description: 'If you have any feedback, issues, or just want to say Hi; please join us in the community discord!',
				icon: 'discord',
				type: 'fab',
			},
		]
	}
</script>
