<template>
	<div class="flex flex-col h-full w-full">
		<div class="pb-4 w-full absolute z-10 bg-gradient-to-b from-gray-50 via-gray-50 dark:from-gray-950 dark:via-gray-950 to-transparent h-12">
			<span class="text-xl my-auto text-gray-500 dark:text-gray-300 pl-10 md:pl-0">Library</span>
		</div>
		<div class="flex-1">

		</div>
	</div>
</template>

<script lang="ts">
	import { Vue, Options } from 'vue-class-component';

	@Options({
		name: 'Library',
		components: {
		},
	})
	export default class Library extends Vue {
	}
</script>
