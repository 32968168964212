
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'DeactivateAccount',
	components: {
	},
})
export default class DeactivateAccount extends mixins(Services) {
	@Inject() store;
	@Inject() firebaseAuthentication;
	@Prop() visible;

	password: any = '';

	close() {
		this.$emit('close');
		this.password = '';
	}
	async submit() {
		const uid: any = JSON.parse(JSON.stringify(this.firebaseCustomer.uid));
		await this.firebaseAuthentication.deleteCustomer(uid, this.firebaseCustomer.email, this.password).then(async (res: any) => {
			if (res !== '') {
				this.notify('danger', 'Authentication', res);
			} else {
				localStorage.removeItem('timestamp');
				localStorage.removeItem('customer');
				localStorage.removeItem('resources');
				location.reload();
			}
		});
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}

}
