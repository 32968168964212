export class PosterDto {
	scale = 100;
	offsetX = 0;
	offsetY = 0;
	initialX: any = null;
	initialY: any = null;
	initialW = 1000;
	initialH = 1500;
	maxW: any = null;
	maxH: any = null;
	positionX: any = null;
	positionY: any = null;
	boundX: any = null;
	boundY: any = null;
	boundW: any = null;
	boundH: any = null;
	blockMove: any = false;
}

export class PosterDetailDto {
	mode = '';
	name = '';
	width = 1000;
	height = 0;
	scale = 100;
	offset = {
		top: 0,
		left: 0,
	};
	blockMove: any = false;
}
