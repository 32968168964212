<template>
	<div>
		<p class="block text-xs text-secondary mb-1">{{ utility.options.label }}</p>
		<div class="flex justify-center">
			<div class="w-full">
				<div class="grid grid-cols-6 gap-y-1">
					<div v-for="(color, index) in colors"
					     :key="index"
					     :style="{ background: color }"
					     :class="activeValue === color ? 'border-gray-200 dark:border-gray-400' : 'border-primary'"
					     class="flex justify-center col-span-1 color-container rounded-xl border-2 w-9 h-9 cursor-pointer"
					     @click="colorClicked(color)">
						<tw-icon v-if="activeValue === color" :class="activeValue === '#FFFFFF' ? 'text-gray-950' : 'text-gray-50'" weight="fas" size="sm" icon="check"/>
					</div>
					<label class="relative cursor-pointer w-9 h-9 flex justify-center text-primary rounded-xl border border-2 border-primary background-gradient-br">
						<input class="cursor-pointer absolute inset-0 w-8 h-8 opacity-0" type="color" v-model="colorPick">
						<tw-icon class="cursor-pointer" weight="fas" size="sm" icon="eye-dropper"/>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Options, Prop, Watch, mixins} from 'vue-property-decorator';
	import {Services} from "@/mixins/services";
	// import colorPicker from 'vue-color-picker-wheel';

	@Options({
		name: 'ClColorSwatches',
		components: {
			// colorPicker
		},
	})
	export default class ClColorSwatches extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;

		activeValue: any = '';
		colorPick: any = '';
		colors = {
			red: '#FF0018',
			orange: '#FF6226',
			yellow: '#FFB63B',
			green: '#00D18B',
			purple: '#9F1AE6',
			blue: '#0096DE',
			white: '#FFFFFF',
			lightGrey: '#BDBDBD',
			middleGrey: '#757575',
			darkgrey: '#232930',
			black: '#000000',
		};

		@Watch('value', {immediate: true, deep: true})
		watchValue() {
			this.activeValue = this.value;
			this.colorPick = this.value;
		}
		@Watch('activeValue')
		watchActiveValue() {
			this.valueHandler(this.utility, this.activeValue, this.variables);
		}
		@Watch('colorPick')
		watchColorPick() {
			this.activeValue = this.colorPick;
		}

		colorClicked (color: any) {
			this.activeValue = color;
		}
	}
</script>
