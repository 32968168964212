
	import { Options, Watch, mixins } from 'vue-property-decorator';
	import { PosterDto } from '@/helpers/dtos.poster';
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';
	import {EventName} from "@/helpers/event-bus";

	@Options({
		name: "Monument",
		components: {
			designer
		},
	})
	export default class Monument extends mixins(Functions) {
		name = 'Monument';
		imported: any = {};
		static: any = {
			text_fontFamily: 'Open Sans',
		};
		default: any = {
			image_count: 3,
			image_shadowBlur: 8,
			image_shadowOffset: 0,
			colors_shadow: '#000000',
			overlay_color: '#000000',
			overlay_opacity: 10,
			overlay_blur: 30,
			extras_show_gridMap: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
				secondary: new PosterDto(),
				tertiary: new PosterDto(),
				quaternary: new PosterDto(),
				quinary: new PosterDto(),
			}
		}

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_count',
						comp: 'select-menu',
						options: {
							mod: 'PosterCount',
							label: 'Number of Posters',
						}
					},
					{
						variable: 'image_focus',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'ImageShort',
							label: 'Image Focus',
							count: 2,
						}
					},
					{
						variable: 'image_shadowBlur',
						comp: 'slider',
						options: {
							label: 'Shadow Blur',
							min: 0,
							max: 50,
							type: "px",
						}
					},
					{
						variable: 'image_shadowOffset',
						comp: 'slider',
						options: {
							label: 'Shadow Offset',
							min: 0,
							max: 50,
							type: "px",
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_shadow',
						comp: 'color-swatches',
						options: {
							label: 'Poster Shadow',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_blur',
						comp: 'slider',
						options: {
							label: 'Image Blur',
							min: 0,
							max: 50,
							type: "px",
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			}
		];

		@Watch('default.image_count', { immediate: true })
		watchImageNetwork() {
			this.controls.find(el => el.name === 'Image').utilities.find(el => el.variable === 'image_focus').options.count = this.default.image_count;
		}

		created() {
			this.default.images.primary.blockMove = true;
			this.default.images.secondary.blockMove = true;
			this.default.images.tertiary.blockMove = true;
			this.default.images.quaternary.blockMove = true;
			this.default.images.quinary.blockMove = true;
		}

		drawer (ctx: any, variables: any, poster: any) {
			let rgba: any = this.color_hexToRgba(variables.colors_shadow, 80);
			ctx.shadowColor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;

			if (variables.image_count === 2) {
				this.drawTwoElements(ctx, variables, poster);
			} else if (variables.image_count === 3) {
				this.drawThreeElements(ctx, variables, poster);
			} else if (variables.image_count === 4) {
				this.drawFourElements(ctx, variables, poster);
			} else if (variables.image_count === 5) {
				this.drawFiveElements(ctx, variables, poster);
			}
			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		drawTwoElements(ctx: any, variables: any, poster: any) {
			this.setBounds('primary', 80, 225, 700, 1050);
			this.setBounds('secondary', 675, 375, 500, 750);

			poster.primaryFileSource ? this.draw_imageBlurredBackground(ctx, poster, 'primary', variables.overlay_blur) : null;
			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, null);

			this.setShadowOffset(true, ctx, variables);
			if (poster.secondaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'secondary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.secondary.boundX, this.default.images.secondary.boundY, this.default.images.secondary.boundW, this.default.images.secondary.boundH, '#555e6e', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, 'Right', 890, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.primaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'primary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.primary.boundX, this.default.images.primary.boundY, this.default.images.primary.boundW, this.default.images.primary.boundH, '#6e7582', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, 'Left', 430, 750, 80, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}
		}

		drawThreeElements(ctx: any, variables: any, poster: any) {
			this.setBounds('primary', -175, 375, 500, 750);
			this.setBounds('secondary', 150, 225, 700, 1050);
			this.setBounds('tertiary', 675, 375, 500, 750);

			poster.secondaryFileSource ? this.draw_imageBlurredBackground(ctx, poster, 'secondary', variables.overlay_blur) : null;
			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, null);

			this.setShadowOffset(true, ctx, variables);
			if (poster.primaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'primary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.primary.boundX, this.default.images.primary.boundY, this.default.images.primary.boundW, this.default.images.primary.boundH, '#6e7582', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, 'Left', 75, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.tertiaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'tertiary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.tertiary.boundX, this.default.images.tertiary.boundY, this.default.images.tertiary.boundW, this.default.images.tertiary.boundH, '#3e4859', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, 'Right', 925, 750, 48,  this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.secondaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'secondary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.secondary.boundX, this.default.images.secondary.boundY, this.default.images.secondary.boundW, this.default.images.secondary.boundH, '#555e6e', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, 'Middle', 500, 750, 80,  this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}
		}

		drawFourElements(ctx: any, variables: any, poster: any) {
			this.setBounds('primary', -200, 375, 500, 750);
			this.setBounds('secondary', 110, 225, 700, 1050);
			this.setBounds('tertiary', 430, 375, 500, 750);
			this.setBounds('quaternary', 810, 512, 310, 465);

			poster.secondaryFileSource ? this.draw_imageBlurredBackground(ctx, poster, 'secondary', variables.overlay_blur) : null;
			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, null);

			this.setShadowOffset(true, ctx, variables);
			if (poster.primaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'primary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.primary.boundX, this.default.images.primary.boundY, this.default.images.primary.boundW, this.default.images.primary.boundH, '#6e7582', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '1', 55, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.quaternaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'quaternary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.quaternary.boundX, this.default.images.quaternary.boundY, this.default.images.quaternary.boundW, this.default.images.quaternary.boundH, '#283242', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '4', 965, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.tertiaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'tertiary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.tertiary.boundX, this.default.images.tertiary.boundY, this.default.images.tertiary.boundW, this.default.images.tertiary.boundH, '#3e4859', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '3', 860, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.secondaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'secondary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.secondary.boundX, this.default.images.secondary.boundY, this.default.images.secondary.boundW, this.default.images.secondary.boundH, '#555e6e', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '2', 460, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}
		}

		drawFiveElements(ctx: any, variables: any, poster: any) {
			this.setBounds('primary', -125, 512, 310, 465);
			this.setBounds('secondary', 60, 375, 500, 750);
			this.setBounds('tertiary', 150, 225, 700, 1050);
			this.setBounds('quaternary', 440, 375, 500, 750);
			this.setBounds('quinary', 815, 512, 310, 465);

			poster.tertiaryFileSource ? this.draw_imageBlurredBackground(ctx, poster, 'tertiary', variables.overlay_blur) : null;
			this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, null);

			this.setShadowOffset(true, ctx, variables);
			if (poster.primaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'primary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.primary.boundX, this.default.images.primary.boundY, this.default.images.primary.boundW, this.default.images.primary.boundH, '#8f959f', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '1', 30, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.secondaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'secondary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.secondary.boundX, this.default.images.secondary.boundY, this.default.images.secondary.boundW, this.default.images.secondary.boundH, '#6e7582', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '2', 110, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.quinaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'quinary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.quinary.boundX, this.default.images.quinary.boundY, this.default.images.quinary.boundW, this.default.images.quinary.boundH, '#283242', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '5', 970, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.quaternaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'quaternary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.quaternary.boundX, this.default.images.quaternary.boundY, this.default.images.quaternary.boundW, this.default.images.quaternary.boundH, '#3e4859', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '4', 890, 750, 48, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}

			this.setShadowOffset(true, ctx, variables);
			if (poster.tertiaryFileSource) {
				this.draw_imageWithBounds(ctx, poster, 'tertiary', variables, variables.image_shadowBlur);
			} else {
				this.draw_rectangle(ctx, this.default.images.tertiary.boundX, this.default.images.tertiary.boundY, this.default.images.tertiary.boundW, this.default.images.tertiary.boundH, '#555e6e', variables.image_shadowBlur, null);
				this.setShadowOffset(false, ctx, variables);
				this.write_textLine(ctx, '3', 500, 750, 80, this.static.text_fontFamily, '#FFFFFF', 'normal', null, 'center', 'middle');
			}
		}

		setShadowOffset(active, ctx, variables) {
			ctx.shadowOffsetX = active ? variables.image_shadowOffset : 0;
			ctx.shadowOffsetY = active ? variables.image_shadowOffset : 0;
		}

		setBounds(image, x, y, w, h) {
			this.default.images[image].boundX = x;
			this.default.images[image].boundY = y;
			this.default.images[image].boundW = w;
			this.default.images[image].boundH = h;
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
