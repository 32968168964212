
	import { Vue, Options } from 'vue-class-component';
	import counter from "@/components/custom/poster.counter.vue";
	import axios from "axios";
	import moment from "moment";

	@Options({
		name: 'Browse',
		components: {
			counter
		},
	})
	export default class Browse extends Vue {
		searchString: any = 'avenger';
		activeSearch = false;

		totalCount = 1000;

		tabs: any = [
			{
				name: 'Collection',
				current: true,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
			{
				name: 'Movie',
				current: false,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
			{
				name: 'TV',
				current: false,
				page: 1,
				results: [],
				total_pages: null,
				total_results: 0,
			},
		]

		async search () {
			const _searchString = this.searchString.trim().replace(/ /g,"+");
			if (_searchString.length === 0) return;

			this.tabs.forEach((el) => {
				el.page = 1;
				el.results = [];
				el.total_pages = null;
			});

			await this.getDatabaseList('initial', 'Collection');
			await this.getDatabaseList('initial', 'Movie');
			await this.getDatabaseList('initial', 'TV');
			this.activeSearch = true;
		}

		async getDatabaseList(type, format) {
			let $el = this.tabs.find(el => el.name === format);
			await axios.get(`https://api.themoviedb.org/3/search/${format.toLowerCase()}?api_key=121f04161acaada7f621744b0ba5cea9&page=${$el.page}&query=${this.searchString}`).then((res) => {
				if (type === 'initial') {
					$el.total_pages = res.data.total_pages;
					$el.total_results = res.data.total_results;
				}
				$el.results = res.data.results;
			});
		}

		changeTab(tab) {
			this.tabs.forEach((el) => {
				el.current = false;
			});
			tab.current = true;
		}

		cleanReleaseDate(item) {
			return moment(item.release_date).format('MM/DD/YYYY');
		}

		setData(item) {
			console.log(item);
		}
		clearSearch() {
			this.activeSearch = false;
			this.searchString = '';
			this.tabs.forEach((el) => {
				el.page = 1;
				el.results = [];
				el.total_pages = null;
				el.total_results =0;
			});
		}

		async paginate(direction) {
			let $el = this.tabs.find(el => el.current);
			if (direction === 'next' && $el.page < $el.total_pages) {
				$el.page++;
			} else if (direction === 'back' && $el.page > 1){
				$el.page--;
			}
			await this.getDatabaseList('paging', $el.name);
		}

		get activePage() {
			let $el = this.tabs.find(el => el.current);
			return $el.page;
		}
		get activePageTotal() {
			let $el = this.tabs.find(el => el.current);
			return $el.total_pages;
		}
	}
