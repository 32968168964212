<template>
	<i class="flex flex-col justify-center leading-none" :class="`text-${size} ${weight} fa-${icon}`"></i>
</template>

<script lang="ts">
	import { Vue, Options, Prop } from 'vue-property-decorator';

	@Options({
		name: 'TwIcon',
		components: {
		},
	})
	export default class TwIcon extends Vue {
		@Prop() icon;
		@Prop({ default: 'far' }) weight;
		@Prop({ default: 'base' }) size;
	}
</script>
