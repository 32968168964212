<template>
	<div class="h-full relative overflow-auto bg-gray-50 dark:bg-gray-950 border border border-gray-200 dark:border-gray-800 rounded"
	     :class="windowWidth >= 1023 ? '-ml-px w-72 p-4' : 'w-full p-4 flex-1 mt-2 h-full overflow-auto'">
		<div v-for="(control, index) in controls" :key="index" :class="{'h-full' : isActive(control.index)}">
			<div v-if="isActive(control.index)" class="w-full h-full">
				<div v-for="(utility, key) in control.utilities" :key="key" class="pb-2">
					<component :is="utility.comp"
					           :value="utility.variable ? variables[utility.variable] : values[utility.value]"
					           :utility="utility"
					           :variables="variables"
					           :isLast="index === control.utilities.length - 1"/>
				</div>
				<div v-if="activeControl === controls.find(el => el.name === 'Presets').index" class="w-full h-full">
					<designer-presets/>
				</div>
				<div v-if="activeControl === controls.find(el => el.name === 'Save/Download').index" class="w-full h-full">
					<designer-download class="h-full" @download="callDownload($event)"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Options, Vue, Inject, Prop } from 'vue-property-decorator';
import designerPresets from '@/components/custom/designer.presets.vue';
import designerDownload from '@/components/custom/designer.download.vue';
import toggle from '@/shared/tw-toggle.vue';
import slider from '@/shared/tw-slider.vue';
import buttonGroup from '@/shared/tw-button-group.vue';
import offsets from '@/shared/cl-offsets.vue';
import selectMenu from '@/shared/cl-select-menu.vue';
import inputGroup from '@/shared/cl-input-group.vue';
import colorSwatches from '@/shared/cl-color-swatches.vue';
import integerTicker from '@/shared/cl-integer-ticker.vue';

import { EventName, EventBus } from "@/helpers/event-bus";

@Options({
	name: "DesignerUtilities",
	components: {
		designerPresets,
		designerDownload,
		toggle,
		slider,
		buttonGroup,
		offsets,
		selectMenu,
		inputGroup,
		colorSwatches,
		integerTicker,
	},
})
export default class DesignerUtilities extends Vue {
	@Inject() readonly store!: any;

	@Prop() controls: any;
	@Prop() activeControl: any;
	@Prop() variables: any;

	readonly eventBus: EventBus = EventBus.getInstance();

	isActive(index) {
		return index === this.activeControl;
	}

	callDownload (e) {
		console.log('callDownload');
		const originalGridState = this.variables.extras_show_gridMap;
		const originalCustomState = this.variables.show_customMap;
		this.eventBus.emit(EventName.UpdateVariable, {variable: 'extras_show_gridMap', value: false});
		this.eventBus.emit(EventName.UpdateVariable, {variable: 'show_customMap', value: false});
		this.eventBus.emit(EventName.DownloadPoster, e);
		this.eventBus.emit(EventName.UpdateVariable, {variable: 'extras_show_gridMap', value: originalGridState});
		this.eventBus.emit(EventName.UpdateVariable, {variable: 'show_customMap', value: originalCustomState});
	}

	get values () {
		return {
			offsets: this.offsets,
			scale: this.scale,
		};
	}
	get offsets() {
		return {
			x: this.variables.images[this.variables.image_focus].offsetX,
			y: this.variables.images[this.variables.image_focus].offsetY,
		};
	}
	get scale () {
		return this.variables.images[this.variables.image_focus].scale;
	}
	get windowWidth() {
		return this.store.state.windowWidth;
	}
}
</script>


<style scoped lang="scss">
</style>
