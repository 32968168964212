
	import {Vue, Options, Prop, Inject} from 'vue-property-decorator';

	@Options({
		name: 'TwButton',
		components: {
		},
	})
	export default class TwButton extends Vue {
		@Inject() store;

		@Prop() text;
		@Prop({default: 'text-white'}) font;
		@Prop({default: 'focus:ring-brand-light-to dark:focus:ring-brand-dark-to'}) focus;
		@Prop({default: 'bg-brand-light-to hover:bg-brand-light-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via'}) color;
		@Prop({default: 'border-transparent'}) border;
		@Prop({default: 'shadow-sm'}) shadow;
		@Prop({default: 'ring-offset-white dark:ring-offset-gray-900'}) offsetColor;
		@Prop({default: 'text-brand-light-from dark:text-brand-dark-from'}) loader;

		@Prop({default: null}) defaultStyle;
		@Prop({default: false}) showImage
		@Prop({default: false}) loading

		get icon() {
			return this.store.state.theme === 'light' ? 'coverlabs-icon-light' : 'coverlabs-icon-dark';
		}

		get colorStyles () {
			if (this.defaultStyle === 'danger') {
				return {
					font: this.font,
					focus: 'focus:ring-red-500',
					color: 'bg-red-600 hover:bg-red-700',
					border: this.border,
					loader: this.loader,
				};
			} else if (this.defaultStyle === 'cancel') {
				return {
					font: 'text-gray-700 dark:text-gray-50',
					focus: 'focus:ring-gray-300 dark:focus:ring-gray-500',
					color: 'bg-gray-0 dark:bg-gray-600 hover-primary',
					border: 'border-gray-200 dark:border-gray-800',
					offsetColor: this.offsetColor,
					// offsetRing: 'ring-offset-gray-100 dark:ring-offset-gray-700'
				};
			} else if (this.defaultStyle === 'alternate') {
				return {
					font: this.font,
					focus: 'focus:ring-brand-alternate-to',
					color: 'background-gradient-alt-r',
					border: this.border,
					offsetColor: this.offsetColor,
					loader: this.loader,
				};
			} else if (this.defaultStyle === 'gradient') {
				return {
					font: this.font,
					focus: '',
					color: 'background-gradient-r',
					border: 'border-gray-200 dark:border-gray-800',
					offsetColor: this.offsetColor,
					loader: this.loader,
				};
			} else {
				return {
					font: this.font,
					focus: this.focus,
					color: this.color,
					border: this.border,
					offsetColor: this.offsetColor,
					loader: this.loader,
				};
			}
		}
	}
