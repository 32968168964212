
	import { Options, mixins } from 'vue-property-decorator';
	import { PosterDto } from "@/helpers/dtos.poster";
	import Functions from '@/mixins/designer.functions';
	import designer from '@/components/custom/designer.foundation.vue';

	@Options({
		name: "Alvord",
		components: {
			designer
		},
	})
	export default class Alvord extends mixins(Functions) {
		$refs: any;

		name = 'Alvord';
		imported: any = {};
		static = {
			text_fontFamily: 'Source Sans Pro',
			shadow_blur: 6 ,
			shadow_color: 'rgba(0, 0, 0, .8)'
		};
		default = {
			text_seasonNumber: 1,
			overlay_color: '#000000',
			overlay_opacity: 0,
			overlay_blur: 0,
			colors_background: '#0D5770',
			colors_borderOuter: '#FFFFFF',
			colors_borderInner: '#FBEC24',
			colors_letter: '#FFFFFF',
			colors_season: '#FBEC24',
			extras_show_gridMap: false,
			extras_show_season: true,
			extras_show_complete: false,
			image_focus: 'primary',
			images: {
				primary: new PosterDto(),
				secondary: new PosterDto(),
				tertiary: new PosterDto(),
			}
		}

		controls: any = [
			{
				name: "Image",
				utilities: [
					{
						variable: 'image_focus',
						handler: 'imageToggle',
						comp: 'button-group',
						options: {
							mod: 'Image',
							overrides: ['Poster', 'Logo', 'Texture'],
							label: 'Image Focus',
							count: 3,
						}
					},
					{
						value: 'scale',
						handler: 'scaleHandler',
						comp: 'slider',
						options: {
							label: 'Adjust Size',
							min: 5,
							max: 200,
							type: "%",
						}
					},
					{
						value: 'offsets',
						handler: 'offsetHandler',
						comp: 'offsets',
						options: {
							label: 'Offsets',
						}
					},
				]
			},
			{
				name: "Text",
				utilities: [
					{
						variable: 'text_seasonNumber',
						comp: 'input-group',
						options: {
							mod: 'number',
							label: 'Season Number',
							min: 1,
							max: 99,
						}
					},
				]
			},
			{
				name: "Colors",
				utilities: [
					{
						variable: 'colors_background',
						comp: 'color-swatches',
						options: {
							label: 'Background',
						}
					},
					{
						variable: 'colors_borderOuter',
						comp: 'color-swatches',
						options: {
							label: 'Border Outer',
						}
					},
					{
						variable: 'colors_borderInner',
						comp: 'color-swatches',
						options: {
							label: 'Border Inner',
						}
					},
					{
						variable: 'colors_letter',
						comp: 'color-swatches',
						options: {
							label: 'Letter',
						}
					},
					{
						variable: 'colors_season',
						comp: 'color-swatches',
						options: {
							label: 'Season',
						}
					},
				]
			},
			{
				name: "Overlay",
				utilities: [
					{
						variable: 'overlay_color',
						comp: 'color-swatches',
						options: {
							label: 'Color',
						}
					},
					{
						variable: 'overlay_opacity',
						comp: 'slider',
						options: {
							label: 'Opacity',
							min: 0,
							max: 100,
							type: "%",
						}
					},
					{
						variable: 'overlay_blur',
						comp: 'slider',
						options: {
							label: 'Image Blur',
							min: 0,
							max: 50,
							type: "px",
						}
					},
				]
			},
			{
				name: "Extras",
				utilities: [
					{
						variable: 'extras_show_season',
						comp: 'toggle',
						options: {
							label: 'Show Season',
						}
					},
					{
						variable: 'extras_show_complete',
						comp: 'toggle',
						options: {
							label: 'Show Complete Season',
						}
					},
					{
						variable: 'extras_show_gridMap',
						comp: 'toggle',
						options: {
							label: 'Show Grid Map',
						}
					},
				]
			},
		]

		created() {
			this.default.images.primary.boundX = 30;
			this.default.images.primary.boundY = 30;
			this.default.images.primary.boundW = 940;
			this.default.images.primary.boundH = 1215;
			this.default.images.secondary.initialY = 1370;
			this.default.images.secondary.initialW = 400;
			this.default.images.secondary.initialH = 300;
			this.default.images.secondary.maxW = 500;
			this.default.images.secondary.maxH = 300;
		}
		drawer (ctx: any, variables: any, poster: any) {
			if (poster.tertiaryFileSource) {
				this.draw_imageBlurredBackground(ctx, poster, 'tertiary', variables.overlay_blur);
				this.draw_rectangleGradient(ctx, 0, 0, poster, variables.overlay_color, variables.overlay_opacity, null);

			} else {
				this.draw_rectangle (ctx, 0, 0, 1000, 1500, variables.colors_background, null, null);
			}

			poster.primaryFileSource ? this.draw_imageWithBounds(ctx, poster, 'primary', variables, null) : this.write_imageHelper(ctx, 1000 / 2, 600, 65, null);

			ctx.save();
			ctx.lineWidth = 26;
			ctx.shadowBlur = this.static.shadow_blur;
			ctx.shadowColor = this.static.shadow_color;
			ctx.strokeStyle = variables.colors_borderOuter;
			ctx.strokeRect(39, 39, 922, 1194);
			ctx.restore();

			ctx.lineWidth = 18;
			ctx.strokeStyle = variables.colors_borderInner;
			ctx.strokeRect(39, 39, 922, 1194);
			ctx.restore();

			poster.secondaryFileSource ? this.draw_image(ctx, poster, 'secondary', variables) : this.write_imageHelper(ctx, 1000 / 2, 1370, 0, 'alvord.1');

			if (variables.extras_show_season) {
				ctx.beginPath();
				ctx.lineWidth = 8;
				ctx.arc(855, 1160, 135, 0, 2 * Math.PI);
				ctx.fillStyle = variables.colors_background;
				ctx.fill();
				ctx.stroke();
				ctx.restore();

				let offsetX = 855;
				if (variables.extras_show_complete) {
					this.write_textMulticolor(ctx, 'C', 'S',  offsetX, 1175, 160, this.static.text_fontFamily, variables.colors_letter, variables.colors_season, 'normal', 'middle');
				} else {
					if (variables.text_seasonNumber > 9) {
						offsetX = 820;
					}

					this.write_textMulticolor(ctx, 'S', variables.text_seasonNumber,  offsetX, 1175,160, this.static.text_fontFamily, variables.colors_letter, variables.colors_season, 'normal', 'middle');
				}
			}

			if (variables.extras_show_gridMap) {
				this.draw_gridMap(ctx);
			}
		}

		imageLoadedListener () {
			//	RUN ANY RESETS HERE AFTER IMAGE LOADED
		}
	}
