<template>
	<div>
		<p class="block text-xs text-secondary">{{ utility.options.label }}</p>
		<div class="flex">
			<div class="tw-range-slider flex-1 py-3 pr-4">
				<vue-slider v-model="activeValue"
				            tooltip="none"
				            :height="10"
				            :min="utility.options.min"
				            :max="utility.options.max"
				            @change="activeValue = $event"/>
			</div>
			<tw-input-group class="w-24"
			                type="number"
			                :value="activeValue"
			                :trailingIcon="utility.options.type"
			                @updateValue="activeValue = $event"/>
		</div>
	</div>

</template>

<script lang="ts">
	import { Options, Prop, Watch, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import VueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/default.css';

	@Options({
		name: 'TwRangeSlider',
		components: {
			VueSlider
		},
	})
	export default class TwRangeSlider extends mixins(Services) {
		@Prop() value: any;
		@Prop() utility: any;
		@Prop() variables: any;
		@Prop() isLast: any;
		@Prop({default: false}) useEmit: any;

		activeValue: any = 0;

		@Watch('value', { immediate: true })
		watchValue() {
			this.activeValue = this.value;
		}

		@Watch('activeValue')
		watchActiveValue() {
			if (this.activeValue > this.utility.options.max) {
				this.activeValue = this.utility.options.max;
			} else if (this.activeValue < this.utility.options.min) {
				this.activeValue = this.utility.options.min;
			}
			if (this.useEmit) {
				this.$emit('updateValue', this.activeValue);
			} else {
				this.valueHandler(this.utility, this.activeValue, this.variables);
			}
		}
	}
</script>
<style scoped lang="scss">
	html:not(.dark) {
		.tw-range-slider ::v-deep{
			.vue-slider-rail {
				background-color: #f0f1f3 !important;
			}
			.vue-slider-process {
				background-image: linear-gradient(to right, #FFB88C, #E56590);
			}
		}
	}
	html.dark {
		.tw-range-slider ::v-deep{
			.vue-slider-rail {
				background-color: #283242 !important;
			}
			.vue-slider-process {
				background-image: linear-gradient(to right, #DC92B2, #7D4CF5);
			}
		}
	}
</style>
