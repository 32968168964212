
	import { Options, Inject, mixins } from 'vue-property-decorator';
	import { Services } from "@/mixins/services";
	import changeEmail from '@/components/modals/change-email.vue';
	import deactivateAccount from '@/components/modals/deactivate-account.vue';
	import moment from 'moment';

	@Options({
		name: 'Account',
		components: {
			changeEmail,
			deactivateAccount,
		},
	})
	export default class Account extends mixins(Services) {
		@Inject() readonly firebase!: any;
		@Inject() readonly firebaseAuthentication!: any;
		@Inject() store;

		firebaseCustomer: any = null;
		imgSquareBase = 250;
		profileCanvas: any = null;

		passwordCurrent = '';
		passwordNew = '';
		passwordConfirm = '';

		selectedNavigationValue: any = null;

		savingChanges = false;
		loadingStripe = false;
		deactivateAccountVisible = false;
		changeEmailVisible = false;

		navigation = [
			{id: 0, name: 'General', icon: 'user', current: true},
			{id: 1, name: 'Password', icon: 'key', current: false},
			{id: 2, name: 'Plan & Billing', icon: 'credit-card-front', current: false},
		];

		async created() {
			this.selectedNavigationValue = this.navigation.find(el => el.current);
			this.firebaseCustomer = JSON.parse(JSON.stringify(this.store.state.firebaseCustomer));
		}
		mounted() {
			this.profileCanvas = this.$el.children[0];
			let canvas = this.profileCanvas;
			let ctx = canvas.getContext('2d');
			ctx.canvas.width = this.imgSquareBase;
			ctx.canvas.height = this.imgSquareBase;
		}
		async saveChanges() {
			let selected = this.navigation.find(el => el.current)?.name;
			if (selected === 'General') {
				await this.saveGeneral();
			} else if (selected === 'Password') {
				await this.savePassword();
			}
		}

		async verifyUniqueUsername() {
			return await this.firebase.firestore().collection("customers").where("username", "==", this.firebaseCustomer.username).get().then((snap) => {
				return snap.size === 0;
			});
		}
		async saveGeneral() {
			if (this.firebaseCustomerOrigin.username !== this.firebaseCustomer.username ||
				this.firebaseCustomerOrigin.firstName !== this.firebaseCustomer.firstName ||
				this.firebaseCustomerOrigin.lastName !== this.firebaseCustomer.lastName ||
				this.firebaseCustomerOrigin.theme !== this.theme) {

				this.savingChanges = true;
				if (this.firebaseCustomerOrigin.username !== this.firebaseCustomer.username && !this.verifyUniqueUsername) {

					this.notify('success', 'Validation', 'Username taken');
					this.savingChanges = false;
					return;
				}

				await this.firebase.functions().httpsCallable('updateCustomerName')({
					username: this.firebaseCustomer.username,
					firstName: this.firebaseCustomer.firstName,
					lastName: this.firebaseCustomer.lastName,
					theme: this.theme
				}).then(async (result: any) => {
					if('success' in result.data) {
						this.store.commit('setFirebaseCustomer', JSON.parse(JSON.stringify(this.firebaseCustomer)));
						this.notify('success', 'Account Updates', 'General information updated');
					}
				}).catch((error: any) => console.log(error));
			} else {
				this.notify('default', 'Account Updates', 'No changes to save');
			}

			this.savingChanges = false;
		}

		async savePassword() {
			this.savingChanges = true;
			if (this.validatePasswordSave()) {
				await this.firebaseAuthentication.setPassword(this.firebaseCustomer.email, this.passwordCurrent, this.passwordNew.trim()).then(async (res: any) => {
					if (res !== 'success') {
						this.notify('danger', 'Authentication', res);
					} else {
						this.passwordCurrent = '';
						this.passwordNew = '';
						this.passwordConfirm = '';
						this.notify('default', 'Account Updates', 'Password updated');
					}
				});
			}
			this.savingChanges = false;
		}

		async managePayment() {
			this.loadingStripe = true;
			const functionRef = this.firebase
				.app()
				.functions('us-central1')
				.httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
			const { data } = await functionRef({ returnUrl: window.location.origin });
			window.location.assign(data.url);
		}
		updateProfileImage (e) {
			let f = e.target.files[0];
			if (f.type.match(/image\/(jpg|jpeg|png)/i)) {
				let image = new Image();
				image.onload = this.imageLoad;
				image.src = URL.createObjectURL(f);
			}
		}

		imageLoad (e) {
			let multiplier = 0;
			let imageHeight = 0;
			let imageWidth = 0;
			let xOffset = 0;
			let yOffset = 0;
			if (e.target.naturalWidth > e.target.naturalHeight) {
				multiplier = this.imgSquareBase / e.target.naturalHeight;
				imageHeight = this.imgSquareBase;
				imageWidth = multiplier * e.target.naturalWidth;
				xOffset = this.imgSquareBase - imageWidth;
			} else {
				multiplier = this.imgSquareBase / e.target.naturalWidth;
				imageWidth = this.imgSquareBase;
				imageHeight = multiplier * e.target.naturalHeight;
				yOffset = this.imgSquareBase - imageHeight;
			}
			let canvas = this.profileCanvas;
			let ctx = canvas.getContext('2d');
			ctx.drawImage(e.target, xOffset, yOffset, imageWidth, imageHeight);
			let profileUrl = canvas.toDataURL('image/jpeg', 85);
			let profileRef = this.firebase.storage().ref(`profiles/${this.firebaseCustomerOrigin.uid}.jpg`);
			profileRef.putString(profileUrl, 'data_url').then((fileSnapshot) => {
				fileSnapshot.ref.getDownloadURL().then((url) => {
					this.firebase.firestore().collection('customers/').doc(this.firebaseCustomerOrigin.uid).update({ photoUrl: url });
					this.firebaseCustomerOrigin.profileUrl = url;
				});
			});
		}

		setTheme(e) {
			if (e === 'dark') {
				document.documentElement.classList.add('dark');
			} else {
				document.documentElement.classList.remove('dark');
			}
			this.store.commit("setTheme", e);
		}

		validatePasswordSave() {
			let valid: boolean;
			let msg = '';

			if (this.passwordCurrent.trim().length > 0 || this.passwordNew.trim().length > 0 || this.passwordConfirm.trim().length > 0) {
				if (this.passwordCurrent.trim().length === 0) {
					msg = 'Current password required';
					valid = false;
				} else if (this.passwordNew.trim().length === 0) {
					msg = 'New password required';
					valid = false;
				} else if (this.passwordConfirm.trim().length === 0) {
					msg = 'Confirm new password required';
					valid = false;
				} else if (this.passwordNew.trim() !== this.passwordConfirm.trim()) {
					msg = 'New passwords must match';
					valid = false;
				} else {
					valid = true;
				}
			} else {
				msg = 'Current password required';
				valid = false;
			}

			if (!valid) {
				this.notify('danger', 'Input Validation', msg);
			}

			return valid;
		}

		updateSelectNavigation(e) {
			this.selectOption(this.navigation.find(el => el.name === e.name));
		}
		selectOption(item) {
			this.navigation.forEach(el => el.current = false);
			item.current = true;
		}

		get subscription() {
			if (this.firebaseCustomerOrigin.subscription) {
				let currentPeriodEnd: any = new Date(0);
				let cancelAt: any = new Date(0);
				const unitAmount = this.firebaseCustomerOrigin.subscription.items[0].price.unit_amount.toString();
				const formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				});

				currentPeriodEnd = moment(currentPeriodEnd.setUTCSeconds(this.firebaseCustomerOrigin.subscription.current_period_end._seconds)).format('MM/DD/YYYY');
				if (this.firebaseCustomerOrigin.subscription.cancel_at_period_end) {
					cancelAt = moment(cancelAt.setUTCSeconds(this.firebaseCustomerOrigin.subscription.cancel_at._seconds)).format('MM/DD/YYYY');
				}

				return {
					status: this.firebaseCustomerOrigin.subscription.status,
					name: this.firebaseCustomerOrigin.subscription.items[0].price.product.name,
					amount: formatter.format(unitAmount.substring(0, unitAmount.length - 2)),
					currentPeriodEnd: currentPeriodEnd,
					cancelAt: cancelAt,
					cancelAtPeriodEnd: this.firebaseCustomerOrigin.subscription.cancel_at_period_end,

				};
			} else {
				return null;
			}
		}
		get firebaseCustomerOrigin() {
			return this.store.state.firebaseCustomer;
		}
		get breakpoint() {
			return this.store.state.breakpoint;
		}
		get theme() {
			return this.store.state.theme;
		}
	}
