<template>
	<button :class="[color, font, focus, border, offsetColor, shadow]"
	        class="cursor-pointer inline-flex justify-center rounded-md border px-4 py-1 font-medium w-auto text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 relative mx-3">
		<span>Join the Discord</span>
		<tw-icon weight="fab"
		         icon="discord"
		         class="p-1 pl-3"></tw-icon>
	</button>
</template>

<script lang="ts">
import {Vue, Options, Prop, Inject} from 'vue-property-decorator';

@Options({
	name: 'TwButton',
	components: {
	},
})
export default class TwButton extends Vue {
	@Inject() store;
	@Prop({default: 'text-white'}) font;
	@Prop({default: 'focus:ring-brand-light-to dark:focus:ring-brand-dark-to'}) focus;
	@Prop({default: 'bg-brand-light-to hover:bg-brand-light-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via'}) color;
	@Prop({default: 'border-transparent'}) border;
	@Prop({default: 'shadow-sm'}) shadow;
	@Prop({default: 'ring-offset-white dark:ring-offset-gray-900'}) offsetColor;
	@Prop({default: 'text-brand-light-from dark:text-brand-dark-from'}) loader;
}
</script>
