
import {Vue, Options, Prop, Inject} from 'vue-property-decorator';

@Options({
	name: 'TwButton',
	components: {
	},
})
export default class TwButton extends Vue {
	@Inject() store;
	@Prop({default: 'text-white'}) font;
	@Prop({default: 'focus:ring-brand-light-to dark:focus:ring-brand-dark-to'}) focus;
	@Prop({default: 'bg-brand-light-to hover:bg-brand-light-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via dark:bg-brand-dark-to dark:hover:bg-brand-dark-via'}) color;
	@Prop({default: 'border-transparent'}) border;
	@Prop({default: 'shadow-sm'}) shadow;
	@Prop({default: 'ring-offset-white dark:ring-offset-gray-900'}) offsetColor;
	@Prop({default: 'text-brand-light-from dark:text-brand-dark-from'}) loader;
}
