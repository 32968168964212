<template>
	<tw-modal :visible="visible"
	          submitText="Deactivate"
	          submitStyle="danger"
	          @close="close"
	          @submit="submit">
		<template v-slot:content>
			<div class="sm:flex sm:items-start">
				<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
					<tw-icon class="text-red-600" size="lg" icon="exclamation-triangle"/>
				</div>
				<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
					<DialogTitle as="h3" class="text-lg leading-6 font-medium text-primary">Deactivate account</DialogTitle>
					<div class="mt-2">
						<p class="text-sm text-gray-500 dark:text-gray-200 mb-2">Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.</p>
						<tw-input-group label="Confirm Password"
						                :required="true"
						                type="password"
						                border-color="border-gray-300 dark:border-gray-500"
						                background-color="bg-white dark:bg-gray-600"
						                :value="password"
						                @updateValue="password = $event"
										@enterEvent="submit()"/>
					</div>
				</div>
			</div>
		</template>
	</tw-modal>
</template>

<script lang="ts">
import {Options, Prop, Inject, mixins} from 'vue-property-decorator';
import {Services} from "@/mixins/services";

@Options({
	name: 'DeactivateAccount',
	components: {
	},
})
export default class DeactivateAccount extends mixins(Services) {
	@Inject() store;
	@Inject() firebaseAuthentication;
	@Prop() visible;

	password: any = '';

	close() {
		this.$emit('close');
		this.password = '';
	}
	async submit() {
		const uid: any = JSON.parse(JSON.stringify(this.firebaseCustomer.uid));
		await this.firebaseAuthentication.deleteCustomer(uid, this.firebaseCustomer.email, this.password).then(async (res: any) => {
			if (res !== '') {
				this.notify('danger', 'Authentication', res);
			} else {
				localStorage.removeItem('timestamp');
				localStorage.removeItem('customer');
				localStorage.removeItem('resources');
				location.reload();
			}
		});
	}

	get firebaseCustomer() {
		return this.store.state.firebaseCustomer;
	}

}
</script>
