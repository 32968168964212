import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';

import Account from '@/views/account.vue';
import Browse from '@/views/browse.vue';
import Dashboard from '@/views/dashboard.vue';
import Design from '@/views/design.vue';
import Library from '@/views/library.vue';
import Premium from '@/views/premium.vue';
import Login from '@/views/login.vue';
import SignIn from '@/components/sign-in/signin.vue';
import Verify from '@/components/sign-in/verify.vue';

import Acadia from '@/components/templates/acadia.vue';
import Alvord from '@/components/templates/alvord.vue';
import Apostle from '@/components/templates/apostle.vue';
import Bisti from '@/components/templates/bisti.vue';
import Denali from '@/components/templates/denali.vue';
import Everglade from '@/components/templates/everglade.vue';
import Kilauea from '@/components/templates/kilauea.vue';
import Mohegan from '@/components/templates/mohegan.vue';
import Monument from '@/components/templates/monument.vue';
import Niagara from '@/components/templates/niagara.vue';
import Palouse from '@/components/templates/palouse.vue';
import Pomona from '@/components/templates/pomona.vue';
import Rainier from '@/components/templates/rainier.vue';
import Redwood from '@/components/templates/redwood.vue';
import Shoshone from '@/components/templates/shoshone.vue';
import Yosemite from '@/components/templates/yosemite.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			auth: true,
		}
	},
	{
		path: '/account-settings',
		name: 'Account',
		component: Account,
		meta: {
			auth: true
		}
	},
	{
		path: '/browse',
		name: 'Browse',
		component: Browse,
		meta: {
			auth: true,
			premium: true,
		}
	},
	{
		path: '/design',
		name: 'Design',
		component: Design,
		meta: {
			auth: true
		},
		children: [
			{
				path: 'acadia',
				name: 'acadia',
				component: Acadia,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'alvord',
				name: 'alvord',
				component: Alvord,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'apostle',
				name: 'apostle',
				component: Apostle,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'bisti',
				name: 'bisti',
				component: Bisti,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'denali',
				name: 'denali',
				component: Denali,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'everglade',
				name: 'everglade',
				component: Everglade,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'kilauea',
				name: 'kilauea',
				component: Kilauea,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'mohegan',
				name: 'mohegan',
				component: Mohegan,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'monument',
				name: 'monument',
				component: Monument,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'niagara',
				name: 'niagara',
				component: Niagara,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'palouse',
				name: 'palouse',
				component: Palouse,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'pomona',
				name: 'pomona',
				component: Pomona,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'rainier',
				name: 'rainier',
				component: Rainier,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'redwood',
				name: 'redwood',
				component: Redwood,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'shoshone',
				name: 'shoshone',
				component: Shoshone,
				props: (route) => ({query: route.query.q})
			},
			{
				path: 'yosemite',
				name: 'yosemite',
				component: Yosemite,
				props: (route) => ({query: route.query.q})
			},
		]
	},
	{
		path: '/library',
		name: 'Library',
		component: Library,
		meta: {
			auth: true,
			premium: true,
		}
	},
	{
		path: '/premium',
		name: 'Premium',
		component: Premium,
		meta: {
			auth: true,
		}
	},
	{
		path: '/sign-in',
		component: Login,
		meta: {
			guest: true
		},
		children: [
			{
				path: '',
				name: 'sign-in',
				component: SignIn,
				meta: {
					guest: true
				},
			},
			{
				path: 'verify',
				name: 'verify',
				component: Verify,
				meta: {
					guest: true
				},
			},
		]
	},
	{
		path: "/:catchAll(.*)",
		component: Dashboard,
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});


router.beforeEach((to, from, next) => {
	firebase.auth().onAuthStateChanged(async user => {
		await firebase.auth().currentUser?.getIdToken(true);
		const decodedToken = await firebase.auth().currentUser?.getIdTokenResult();
		const isPremium = decodedToken?.claims.stripeRole === 'daily';

		if (user && user.emailVerified) {
			if (to.matched.some(record => record.meta.premium)) {
				if (isPremium) {
					next();
				} else {
					next({
						path: "/design",
					});
				}
			} else if ((to.path === '/premium' && isPremium) || to.path === '/sign-in/verify' || to.path === '/sign-in') {
				next({
					path: "/",
				});
			} else {
				next();
			}
		} else if (to.fullPath !==  "/sign-in/verify" && user && !user.emailVerified) {
			next({
				path: "/sign-in/verify",
			});
		} else if (to.fullPath !== "/sign-in" && !user) {
			next({
				path: "/sign-in",
			});
		} else {
			next();
		}
	});
});

export default router;
