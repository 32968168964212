
	import { Vue, Options, Prop, Watch } from 'vue-property-decorator';

	@Options({
		name: 'TwSelectMenu',
		components: {
		},
	})
	export default class TwSelectMenu extends Vue {
		@Prop({default: ''}) label;
		@Prop({default: null}) value;
		@Prop({default: []}) options;
		@Prop({default: 'primary'}) variant;
		@Prop({default: null}) modifier;

		selected: any = null;

		@Watch('value', {immediate: true})
		watchValue() {
			this.selected = this.value !== null ? this.value : this.options[0];
		}

		@Watch('selected')
		watchActiveValue() {
			this.$emit('updateValue', this.selected);
		}

		get style () {
			if (this.variant === 'secondary') {
				return {
					selectMenu: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'bg-white dark:bg-gray-900',
						focus: 'focus-ring-offset-primary',
					},
					options: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'bg-white dark:bg-gray-900',
						activeRow: 'text-white bg-brand-light-to dark:bg-brand-dark-to',
						activeText: 'text-brand-light-to dark:text-brand-dark-to',
						focus: 'focus-ring-offset-primary',
						offset: 'ring-offset-primary'
					}
				};
			} else {
				return {
					selectMenu: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'background-secondary',
						focus: 'focus-ring-offset-primary',
					},
					options: {
						text: 'text-primary',
						border: 'border-primary',
						background: 'background-secondary',
						activeRow: 'text-white bg-brand-light-to dark:bg-brand-dark-to',
						activeText: 'text-brand-light-to dark:text-brand-dark-to',
						focus: 'focus-ring-offset-primary',
						offset: 'ring-offset-primary'
					}
				};
			}
		}


		generateIcon (font) {
			return JSON.parse(`["\\u${font}"]`)[0];
		}
	}
